import React from 'react'
import { Tooltip, ListItem, ListItemButton, ListItemIcon, ListItemText, Grid, List } from '@mui/material'
import { MenuEntry } from 'layout/models/menuLayout'
import '../styles/menuLayout.less'
import { Link } from 'react-router-dom'
import { CustomNavLink } from 'utils/components/navigation'
import { useUsers } from 'contexts/UsersContext'

export const ListMenuItem: React.FC<{
    title?: string
    url: string
    tooltip?: string
    icon?: React.ReactNode
    children?: React.ReactNode
}> = ({ title, icon, url, tooltip, children }) => {
    return (
        <Tooltip title={tooltip || ''} placement="right">
            <ListItem disablePadding className="layout-menu-item">
                <ListItemButton
                    dense
                    disableRipple
                    component={CustomNavLink}
                    to={url}
                    target={url.startsWith('http') ? '_blank' : '_self'}
                    className="button"
                    activeClassName={(isActive) => (url && isActive ? 'active' : '')}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        if (!url) event.preventDefault()
                        if (url.startsWith('http')) {
                            event.preventDefault()
                            window.open(url, '_blank')
                        }
                    }}
                >
                    {icon && <ListItemIcon className="icon">{icon}</ListItemIcon>}
                    {title && <ListItemText primary={title} className="text" />}
                    {children}
                </ListItemButton>
            </ListItem>
        </Tooltip>
    )
}

const MenuSection: React.FC<{
    items: MenuEntry[]
}> = ({ items }) => (
    <>
        {items.map((item) => (
            <div key={item.url || item.title || item.tooltip}>
                {item?.component ? (
                    item.component
                ) : (
                    <ListMenuItem url={item.url || ''} icon={item.icon} tooltip={item.tooltip} title={item.title} />
                )}
            </div>
        ))}
    </>
)

const MenuLayout: React.FC<{
    menuTop: MenuEntry[]
    menuBottom: MenuEntry[]
    mainLink: string
    children?: React.ReactNode
}> = ({ menuTop, menuBottom, mainLink, children }) => {
    const { hasAccess } = useUsers()

    return (
        <Grid className="layout-menu" container>
            <div className="layout-menu-sections">
                <List dense>
                    <Link className="logo-wrapper" to={hasAccess('project:read') ? mainLink : ''}>
                        <div className="logo" />
                    </Link>

                    <MenuSection items={menuTop} />
                </List>

                <List dense>
                    <MenuSection items={menuBottom} />
                </List>
            </div>
            <Grid item xs sm md lg>
                {children}
            </Grid>
        </Grid>
    )
}

export default MenuLayout
