import icoGraphQL from 'static/images/ico_graphql.svg'
import icoOpenAPI2 from 'static/images/ico_openapi_2.svg'
import icoOpenAPI3 from 'static/images/ico_openapi_3.svg'

export function getSchemaImage(type: string, version: string): string | null {
    if (type === 'graphql') {
        return icoGraphQL
    }
    if (type === 'openapi') {
        if (version[0] === '2') {
            return icoOpenAPI2
        }
        if (version[0] === '3') {
            return icoOpenAPI3
        }
    }
    return null
}
