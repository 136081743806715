/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useMemo } from 'react'

import { apiClient, UserProfile, SignupRequest } from 'api'
import { useAuth } from 'contexts/AuthContext'
import { ApiError, CancelablePromise } from 'api/client'
import { UseQueryResult, useQuery } from 'react-query'

type UsersContextData = {
    user: UserProfile | undefined
    userObject: UseQueryResult<UserProfile, ApiError>
    signUp: (data: SignupRequest) => CancelablePromise<{
        message: string
    }> | void
    isGitHubUser: boolean
    hasAccess: (priviledge: UserProfile['access'][0]) => boolean
}

const UsersContext = React.createContext<UsersContextData>({} as UsersContextData)

export const UsersContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const auth = useAuth()
    const user = useQuery<UserProfile, ApiError>(['userProfile', auth], async () => apiClient.users.usersMe(), {
        enabled: auth.isAuthenticated,
    })

    const isGitHubUser = useMemo(
        () => user?.data?.source === 'github_signup' || user?.data?.source === 'github_app',
        [user.data],
    )

    const signUp = (data: SignupRequest) => {
        const urlSearchParams = new URLSearchParams(window.location.search)
        const query = Object.fromEntries(urlSearchParams.entries())
        return apiClient.users.usersSignup({ requestBody: data, query })
    }

    const hasAccess = (priviledge: UserProfile['access'][0]) => !!user?.data?.access.includes(priviledge)

    return (
        <UsersContext.Provider
            value={{
                // State
                user: user.data,
                userObject: user,

                // Functions
                signUp,
                isGitHubUser,
                hasAccess,
            }}
        >
            {children}
        </UsersContext.Provider>
    )
}

export const useUsers = () => useContext(UsersContext)
