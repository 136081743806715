import React from 'react'

import { Chip, Typography } from '@mui/material'
import { MissingHeadersError } from '../../../../../api'

function MissingHeaders({ context }: { context: MissingHeadersError }) {
    return (
        <Typography style={{ marginBottom: 0 }}>
            Missing headers:{' '}
            {context.missing_headers.map((header: string) => (
                <Chip size="small" label={header} />
            ))}
        </Typography>
    )
}

export default MissingHeaders
