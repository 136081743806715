import { useParams } from 'react-router-dom'
import CenteredPageLayout from 'components/CenteredPageLayout'
import RunDetail from '../Runs/RunDetail'
import { ApiPageHeader } from './ApiPageHeader'
import React, { useContext } from 'react'
import { Error } from 'utils/components/errors'
import { ProjectContext } from 'features/contexts/projects'
import { URLS } from 'urls'

export const ApiLastRun: React.FC = () => {
    const project = useContext(ProjectContext)
    const { id } = useParams<{ id: string }>()

    if (project?.error)
        return (
            <Error
                message={String(project?.error.body.detail || project.error)}
                ctaText="Back to projects"
                ctaLink={URLS.projects.index.route}
            />
        )

    return (
        <CenteredPageLayout>
            <ApiPageHeader id={id!} page="lastRun" />

            <RunDetail id="latest" apiId={id!} />
        </CenteredPageLayout>
    )
}
