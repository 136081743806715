import ReactGA from 'react-ga4'

export const trackCreateProjectEvent = (action: string, label?: string) => {
    ReactGA.event({
        category: 'create_project',
        action,
        label,
    })
}

export const trackCreateProjectEnvironmentsEvent = (action: string, extra?: object) => {
    ReactGA.event({
        category: 'project_environments',
        action,
        ...extra,
    })
}
