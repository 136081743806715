import { Alert, Card, IconButton, Tab, Tabs, Tooltip } from '@mui/material'

import { useQuery, useQueryClient } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { URLS } from 'urls'
import React, { useState, useEffect, useCallback, useContext } from 'react'
import { StatusTag } from '../Runs/StatusTag'
import SchemaIcon from './SchemaIcon'
import { apiClient, ApiError } from '../../../api'
import { isUsableLocation } from '../../../utils/urls'
import { TitleBlock } from 'layout/components/titles'
import { ProjectEnvironment } from '../../../api/client'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { ProjectContext } from 'features/contexts/projects'
import { useRunDetail } from '../../../queries/useRunDetail'
import { useUsers } from 'contexts/UsersContext'
import { hasIndividualAccess } from 'utils/access'

type ApiPage = 'lastRun' | 'runs' | 'issues'

export const ApiPageHeader: React.FC<{ page: ApiPage | null; id: string }> = ({ page, id }) => {
    const { hasAccess } = useUsers()
    const project = useContext(ProjectContext)
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const runRes = useRunDetail(id, 'latest')
    const lastRun = runRes.data

    const scopes = project?.data?.scopes || []

    const handleChange = useCallback(
        (e: React.SyntheticEvent, newValue: ApiPage) => {
            switch (newValue) {
                case 'lastRun':
                    navigate(URLS.projects.lastRun.buildPath({ id }))
                    break
                case 'runs':
                    navigate(URLS.projects.runs.buildPath({ id }))
                    break
                case 'issues':
                    navigate(URLS.projects.issues.buildPath({ id }))
                    break
            }
        },
        [id, navigate],
    )

    const projectEnvironmentsQuery = useQuery<ProjectEnvironment[], ApiError>(
        ['projectEnvironmentsList', id],
        async () => apiClient.apis.apisEnvironmentsList({ apiId: id }),
    )
    const environments = projectEnvironmentsQuery.data

    return (
        <>
            {project?.error ? (
                <Alert severity="error">{String(project.error.body.detail || project.error)}</Alert>
            ) : null}
            <TitleBlock
                avatar={
                    <SchemaIcon
                        spec_type={project?.data?.specification.type}
                        spec_version={project?.data?.specification.version}
                    />
                }
                title={
                    <>
                        {project?.data?.name} <div>{lastRun ? <StatusTag status={lastRun?.status} /> : null}</div>
                    </>
                }
                subtitle={
                    project?.data &&
                    (isUsableLocation(project.data.source.location) ? (
                        <a href={project.data.source.location} rel="noopener noreferrer" target="_blank">
                            {project.data.source.location}
                        </a>
                    ) : (
                        project.data.source.location
                    ))
                }
                extra={
                    <div className="content-cols">
                        {hasIndividualAccess(scopes, 'project:write') && hasAccess('project:admin') && (
                            <Tooltip title="Settings">
                                <IconButton component={Link} to={URLS.projects.detail.buildPath({ id })}>
                                    <SettingsOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                }
            />

            <Card>
                <Tabs value={page || false} onChange={handleChange} aria-label="basic tabs example">
                    {hasIndividualAccess(scopes, 'project:read') && hasAccess('project:read') && (
                        <Tab label="Last run" value="lastRun" />
                    )}
                    {hasIndividualAccess(scopes, 'project:read') && hasAccess('project:read') && (
                        <Tab label="Runs history" value="runs" />
                    )}
                    {hasIndividualAccess(scopes, 'project:read') && hasAccess('project:read') && (
                        <Tab label="Issues" value="issues" />
                    )}
                </Tabs>
            </Card>
        </>
    )
}
