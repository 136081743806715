/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessToken } from '../models/AccessToken'
import type { APIDetails } from '../models/APIDetails'
import type { APIIssues } from '../models/APIIssues'
import type { APIList } from '../models/APIList'
import type { NewAPIRequest } from '../models/NewAPIRequest'
import type { Organization } from '../models/Organization'
import type { Project } from '../models/Project'
import type { ProjectEnvironment } from '../models/ProjectEnvironment'
import type { UpdateAPIRequest } from '../models/UpdateAPIRequest'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class ApisService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get a list of APIs belonging to the currently authenticated user.
     * @returns APIList A list of APIs.
     * @throws ApiError
     */
    public apisList(): CancelablePromise<APIList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/apis/',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
    /**
     * Create a new API.
     * @returns any A new API created.
     * @throws ApiError
     */
    public apisCreate({ requestBody }: { requestBody: NewAPIRequest }): CancelablePromise<{
        project: Project
        organization: Organization | null
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/apis/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
                409: `Generic error response.`,
            },
        })
    }
    /**
     * API details
     * @returns APIDetails API details response
     * @throws ApiError
     */
    public apisDetails({
        apiId,
    }: {
        /**
         * Unique API ID.
         */
        apiId: string
    }): CancelablePromise<APIDetails> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/apis/{api_id}/',
            path: {
                api_id: apiId,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * Update an API
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public apisUpdate({
        apiId,
        requestBody,
    }: {
        /**
         * Unique API ID.
         */
        apiId: string
        requestBody: UpdateAPIRequest
    }): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/apis/{api_id}/',
            path: {
                api_id: apiId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
                409: `Generic error response.`,
            },
        })
    }
    /**
     * Delete an API.
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public apisDelete({
        apiId,
    }: {
        /**
         * Unique API ID.
         */
        apiId: string
    }): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/apis/{api_id}/',
            path: {
                api_id: apiId,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * Project environments
     * @returns ProjectEnvironment Configured environments for a project.
     * @throws ApiError
     */
    public apisEnvironmentsList({
        apiId,
    }: {
        /**
         * Project ID.
         */
        apiId: string
    }): CancelablePromise<Array<ProjectEnvironment>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/apis/{api_id}/environments/',
            path: {
                api_id: apiId,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * Update project environments
     * @returns ProjectEnvironment Configured environments for a project.
     * @throws ApiError
     */
    public apisEnvironmentsUpdate({
        apiId,
        requestBody,
    }: {
        /**
         * Project ID.
         */
        apiId: string
        requestBody: Array<ProjectEnvironment>
    }): CancelablePromise<Array<ProjectEnvironment>> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/apis/{api_id}/environments/',
            path: {
                api_id: apiId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * Project token info.
     * @returns any Token info for a project.
     * @throws ApiError
     */
    public projectsTokensDetails({
        apiId,
    }: {
        /**
         * Project ID.
         */
        apiId: string
    }): CancelablePromise<{
        token: AccessToken | null
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/apis/{api_id}/token/',
            path: {
                api_id: apiId,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * API issues
     * @returns APIIssues A list of issues found during API testing.
     * @throws ApiError
     */
    public apisIssues({
        apiId,
    }: {
        /**
         * Unique API ID.
         */
        apiId: string
    }): CancelablePromise<APIIssues> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/apis/{api_id}/issues/',
            path: {
                api_id: apiId,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * Test an API.
     * @returns any A successful API testing response
     * @throws ApiError
     */
    public apisTest({
        apiId,
    }: {
        /**
         * Unique API ID.
         */
        apiId: string
    }): CancelablePromise<{
        message: string
        run_id: string
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/apis/{api_id}/test/',
            path: {
                api_id: apiId,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
}
