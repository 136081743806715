import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CircularProgress, LinearProgress } from '@mui/material'

import '../styles/loaders.less'

export const LoadingStatus: React.FC<{
    title: string
}> = ({ title }) => {
    return (
        <div className="loader">
            <CircularProgress />
            <h4>{title}</h4>
        </div>
    )
}

export const ProgressBar: React.FC = () => {
    const [progress, setProgress] = useState(0)
    const progressRef = useRef(0)
    const stepRef = useRef(0.5)

    const counter = useCallback(() => {
        progressRef.current += stepRef.current
        const prog = Math.min(Math.round((Math.atan(progressRef.current) / (Math.PI / 2)) * 100 * 1000) / 1000, 99.99)

        if (prog >= 70) {
            stepRef.current = 0.1
        }

        setProgress(prog)
    }, [])

    useEffect(() => {
        const intervalId = setInterval(counter, 100)
        return () => {
            clearInterval(intervalId)
            setProgress(0)
            progressRef.current = 0
            stepRef.current = 0
        }
    }, [counter])

    return <LinearProgress className="progressbar" variant="determinate" value={progress} />
}
