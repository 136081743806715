import { Alert, Box, Step, StepLabel, Typography } from '@mui/material'
import Stepper from '@mui/material/Stepper'
import { Helmet } from 'react-helmet'
import LooksOneIcon from '@mui/icons-material/LooksOne'
import LooksTwoIcon from '@mui/icons-material/LooksTwo'
import FavoriteIcon from '@mui/icons-material/Favorite'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Code, CodeAlign } from '../APIs/APIsList/Code'
import { URLS } from '../../../urls'
import CenteredPageLayout from '../../../components/CenteredPageLayout'
import ExternalLinkIcon from '../../../components/ExternalLinkIcon'
import { apiClient } from '../../../api'
import { humanizeSize } from '../../../utils/size'
import {
    GithubPrivateRepositoriesFeature,
    GithubPublicRepositoriesFeature,
    Product,
    ReportSizeFeature,
} from '../../../api/client'
import { APP_NAME } from 'config'
import '../../../static/styles/components/guide.less'

const ALREADY_AVAILABLE_WORKFLOW_URL =
    'https://github.com/schemathesis/schemathesis/blob/master/.github/workflows/example-no-build.yml'
const REQUIRES_STARTING_WORKFLOW_URL =
    'https://github.com/schemathesis/schemathesis/blob/master/.github/workflows/example-build.yml'
const DISCUSSION_URL = 'https://github.com/schemathesis/schemathesis/discussions/1567'
const GITHUB_META_API = 'https://api.github.com/meta'
const PETSTORE_API_URL = 'https://petstore3.swagger.io/'
const ALREADY_AVAILABLE_CODE = `jobs:
api-tests:
  runs-on: ubuntu-20.04
  steps:
    # Runs Schemathesis tests
    - uses: schemathesis/action@v1
      with:
        # Your API schema location
        schema: 'http://localhost:5000/api/openapi.json'
        # Set your token from secrets
        token: \${{ secrets.SCHEMATHESIS_TOKEN }}`
const REQUIRES_STARTING_CODE = `jobs:
api-tests:
  runs-on: ubuntu-20.04
  # Your app could be written in any language
  # This is a sample Python app
  steps:
    # Gets a copy of the source code in your repository before running API tests
    - uses: actions/checkout@v3.0.0

    - uses: actions/setup-python@v4
      with:
        python-version: '3.10'

    # Installs project's dependencies
    - run: pip install -r requirements.txt

    # Start the API in the background
    - run: python main.py &

    # Runs Schemathesis tests
    - uses: schemathesis/action@v1
      with:
        # Your API schema location
        schema: 'http://localhost:5000/api/openapi.json'
        # Set your token from secrets
        token: \${{ secrets.SCHEMATHESIS_TOKEN }}`

const getPlanDescriptions = (products: Product[]) => {
    const planDescriptions = []
    for (const product of products) {
        const publicRepoFeature = product.features.find((feature) => feature.type === 'github_public_repositories') as
            | GithubPublicRepositoriesFeature
            | undefined
        const privateRepoFeature = product.features.find(
            (feature) => feature.type === 'github_private_repositories'
        ) as GithubPrivateRepositoriesFeature | undefined
        const reportSizeFeature = product.features.find((feature) => feature.type === 'report_size') as
            | ReportSizeFeature
            | undefined
        let includesAnything = false

        let planDescription = `The <strong>${product.name}</strong> plan includes `

        if (publicRepoFeature && privateRepoFeature) {
            planDescription += `reporting in both public and private repositories`
            includesAnything = true
        } else if (publicRepoFeature) {
            planDescription += `reporting in public repositories`
            includesAnything = true
        } else if (privateRepoFeature) {
            planDescription += `reporting in private repositories`
            includesAnything = true
        }

        if (reportSizeFeature) {
            const [amount, unit] = humanizeSize(reportSizeFeature.amount)
            planDescription += includesAnything ? ', and ' : ''
            planDescription += `a report size limit of up to <strong>${amount} ${unit}</strong>.`
            includesAnything = true
        }

        if (includesAnything) {
            planDescriptions.push(planDescription)
        }
    }
    return planDescriptions
}

const GitHubAppInstalled: React.FC = () => {
    const [token, setToken] = useState<string>('****')
    const [isTokenGenerated, setIsTokenGenerated] = useState<boolean>(false)
    const envVarCode = `SCHEMATHESIS_TOKEN=${token}`
    const [selectedSetup, setSelectedSetup] = useState<string>(ALREADY_AVAILABLE_CODE)

    const [planInfo, setPlanInfo] = useState<string[]>([])

    useEffect(() => {
        apiClient.billing.billingProductsList().then(({ products }) => {
            const planDescriptions = getPlanDescriptions(products)
            setPlanInfo(planDescriptions)
        })
    }, [])

    return (
        <CenteredPageLayout>
            <Helmet>
                <title>GitHub App | {APP_NAME}</title>
            </Helmet>
            <div className="content-plain">
                <h1>Let's get your APIs tested!</h1>
                <DescriptionParagraph>
                    Once you installed our{' '}
                    <a href="https://github.com/apps/schemathesis" target="_blank" rel="noopener noreferrer">
                        {' '}
                        GitHub app <ExternalLinkIcon />
                    </a>{' '}
                    , Schemathesis.io needs to receive test report from your GitHub Actions workflow to get started.
                </DescriptionParagraph>
                <div
                    style={{
                        marginTop: '30px',
                        marginBottom: '10px',
                        padding: '0px 110px',
                    }}
                >
                    <SetupProgress />
                </div>
                <div className="step">
                    <LooksOneIcon />
                    <h3>Set access token</h3>
                    <p>
                        To identify your report upload within Schemathesis.io, you'll need to set the token below and
                        set it in your GitHub Actions environment variables.
                    </p>
                    <Code
                        code={envVarCode}
                        codeAlign={CodeAlign.CENTER}
                        regenerateCode={() => {
                            // Generate token before copying to the clipboard
                            return apiClient.users.usersTokensCreate().then(({ token }) => {
                                setIsTokenGenerated(true)
                                setToken(token)
                                return `SCHEMATHESIS_TOKEN=${token}`
                            })
                        }}
                    />
                    {isTokenGenerated && (
                        <Alert severity="success" variant="outlined">
                            The token is copied to your clipboard.
                        </Alert>
                    )}
                </div>
                <hr />
                <div className="step">
                    <LooksTwoIcon />
                    <h3>Run API tests</h3>
                    <p>
                        Schemathesis works over HTTP(s) and expects that your application is reachable from the CI
                        environment. <br />
                        You can test your API in a pull request or run tests against a publicly resolvable API.
                    </p>
                    {selectedSetup === ALREADY_AVAILABLE_CODE && (
                        <Alert severity="info" variant="outlined" sx={{ my: 2 }}>
                            See{' '}
                            <a href={ALREADY_AVAILABLE_WORKFLOW_URL} target="_blank" rel="noopener noreferrer">
                                a fully working example <ExternalLinkIcon />
                            </a>{' '}
                            to learn more, or click <a onClick={() => setSelectedSetup(REQUIRES_STARTING_CODE)}>here</a>{' '}
                            if you need to start your API server first.
                        </Alert>
                    )}
                    {selectedSetup === REQUIRES_STARTING_CODE && (
                        <Alert severity="info" variant="outlined" sx={{ my: 2 }}>
                            See{' '}
                            <a href={REQUIRES_STARTING_WORKFLOW_URL} target="_blank" rel="noopener noreferrer">
                                a fully working example <ExternalLinkIcon />
                            </a>{' '}
                            to learn more, or click <a onClick={() => setSelectedSetup(ALREADY_AVAILABLE_CODE)}>here</a>{' '}
                            for simpler setup.
                        </Alert>
                    )}
                    <Code code={selectedSetup} highlight="yaml" />
                    <DescriptionParagraph>
                        All test requests will come from GitHub Actions' IP addresses. You can get them from GitHub's
                        <a href={GITHUB_META_API} target="_blank" rel="noopener noreferrer">
                            {' '}
                            Meta <ExternalLinkIcon />
                        </a>{' '}
                        API endpoint.
                    </DescriptionParagraph>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            padding: '0px 100px 0px 100px',
                            justifyContent: 'center',
                            mt: 2,
                        }}
                        align="center"
                        paragraph
                    >
                        <FavoriteIcon sx={{ color: 'red' }} />
                        Please, let us know what you think about this integration in{' '}
                        <a href={DISCUSSION_URL} target="_blank" rel="noopener noreferrer">
                            this discussion
                        </a>{' '}
                        directly on GitHub!
                        <FavoriteIcon sx={{ color: 'red' }} />
                    </Typography>
                </div>
                <hr />
                <Typography variant="h5" align="center" sx={{ m: 2 }}>
                    🎉 Check out your report! 🎉
                </Typography>
                <DescriptionParagraph>
                    Once the test report is processed you'll see a comment in your Pull Request with a link where you
                    can view your reports on Schemathesis.io.
                </DescriptionParagraph>
                <div className="center-block">
                    <img
                        src="/images/schemathesis-github-report.png"
                        alt="Schemathesis Report"
                        width="75%"
                        loading="lazy"
                        style={{ borderRadius: '4px' }}
                    />
                </div>
                <hr />
                <Typography variant="h5" align="center" sx={{ m: 2 }}>
                    Pricing
                </Typography>
                <DescriptionParagraph>
                    {planInfo.map((desc, index) => (
                        <p key={index} dangerouslySetInnerHTML={{ __html: desc }}></p>
                    ))}
                    For more detailed information about our offerings, please visit our{' '}
                    <Link to={URLS.settings.billing.details.route} target="_blank" rel="noopener">
                        Billing Page
                    </Link>
                    .
                </DescriptionParagraph>
                <DescriptionParagraph>
                    For reference, a test report for a 19-endpoints{' '}
                    <a href={PETSTORE_API_URL} target="_blank" rel="noopener noreferrer">
                        Petstore API
                    </a>{' '}
                    <ExternalLinkIcon />
                    takes <strong>~241KB</strong>.
                </DescriptionParagraph>
                <hr />
                <Typography variant="h5" align="center" sx={{ m: 2 }}>
                    What is next?
                </Typography>
                <DescriptionParagraph>
                    After you finish setup and run your first API tests, you can expect to see your reports in the{' '}
                    <Link to={URLS.projects.index.route} target="_blank" rel="noopener">
                        Dashboard
                    </Link>
                    .
                </DescriptionParagraph>
                <Alert severity="info" variant="outlined" sx={{ mt: 2 }}>
                    You can always get back to this page by clicking on the{' '}
                    <HelpOutlineIcon style={{ height: '20px', width: '20px' }} /> icon in the top menu and choosing the
                    "GitHub Actions" menu entry.
                </Alert>
            </div>
        </CenteredPageLayout>
    )
}

const DescriptionParagraph: React.FC<{children?: React.ReactNode;}> = ({ children }) => {
    return (
        <Typography
            sx={{
                fontSize: '15px',
                padding: '0px 110px 0px 110px',
                justifyContent: 'center',
            }}
            align="center"
            paragraph
        >
            {children}
        </Typography>
    )
}

const SetupProgress: React.FC = () => {
    const steps = ['Set access token', 'Run API tests', 'Receive report']
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper nonLinear alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={label} index={index} active>
                        <StepLabel color="inherit">{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )
}

export default GitHubAppInstalled
