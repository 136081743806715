import React, { useState } from 'react'
import moment from 'moment'
import { Card, Typography, List, CircularProgress, LinearProgress, Tooltip, Alert, Input } from '@mui/material'
import { Link } from 'react-router-dom'
import ReactEcharts from 'echarts-for-react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

import styled from '@emotion/styled'
import { URLS } from 'urls'

import { StatusTagIcon } from './StatusTag'
import '../../../features/styles/tests.less'
import { RunGroup, RunIssue } from 'api/client'
import { useRunDetail } from 'queries/useRunDetail'
import '../../../utils/styles/uploaders.less'
import '../../../static/styles/components/status.less'
import { Code } from '../APIs/APIsList/Code'
import { GhPrLink } from 'features/components/tests'

const EmptyState = styled.div`
    display: flex;
    width: 100%;
    padding: 40px;
    align-items: center;
    justify-content: center;
`

type ErrorTypes = RunGroup['status'] | RunIssue['category']

const colors: { [key in ErrorTypes]: string } = {
    succeeded: '#0097a0',
    errored: '#C70039',
    failed: '#FF5733',
    in_progress: '#FFC300', //
    skipped: '#708284',
    deselected: '#708284', //

    critical: '#900C3F',
    high: '#C70039',
    medium: '#FF5733',
    low: '#FFC300',
}

const chartDefaults = {
    tooltip: {
        trigger: 'item',
    },
    legend: {
        orient: 'vertical',
        left: 15,
        top: 15,
    },
}

const pieDefaults = {
    center: ['65%', '50%'],
    type: 'pie',
    radius: '60%',
}

const failuresFilter = (g: RunGroup, filter: string) => {
    const filterLow = filter.toLocaleLowerCase()
    return (
        !filter ||
        g.name.toLocaleLowerCase().includes(filterLow) ||
        (g.status === 'failed' &&
            g.issues.filter(
                (i) =>
                    i.description.toLocaleLowerCase().includes(filterLow) ||
                    i.category.toLocaleLowerCase().includes(filterLow),
            ).length)
    )
}

const RunDetail: React.FC<{ id: string; apiId: string }> = ({ id, apiId }) => {
    const runRes = useRunDetail(apiId, id)
    const [filter, setFilter] = useState('')

    const activeRunData = runRes.data

    if (id === 'latest' && !activeRunData && !runRes.isLoading && !runRes.error) {
        return (
            <Card className="card">
                <Alert severity="info" variant="outlined">
                    You haven't run any tests on this API yet
                </Alert>
            </Card>
        )
    }

    if (!activeRunData) {
        return runRes.error ? <Alert severity="error">{String(runRes.error)}</Alert> : <CircularProgress />
    }

    const chartOverview = {
        ...chartDefaults,
        series: [
            {
                ...pieDefaults,
                data: Object.entries(activeRunData.stats.endpoints).map((i) => {
                    return {
                        value: i[1],
                        name: `${i[0]}: ${i[1]}`,
                        itemStyle: { color: colors[i[0] as ErrorTypes] },
                    }
                }),
            },
        ],
    }

    const chartErrors = {
        ...chartDefaults,
        series: [
            {
                ...pieDefaults,
                data: Object.entries(activeRunData.stats.issues).map((i) => {
                    return {
                        value: i[1],
                        name: `${i[0]}: ${i[1]}`,
                        itemStyle: { color: colors[i[0] as ErrorTypes] },
                    }
                }),
            },
        ],
    }

    return (
        <div className="content-cards">
            <Card className="card">
                <div className="content-cols content-sides">
                    <h1 className="h1 compact">
                        <StatusTagIcon
                            className="bigger"
                            status={activeRunData.status}
                            hasErrors={!!activeRunData.stats.issues}
                        />
                        {activeRunData.progress.current}/{activeRunData.progress.total} tested #
                        {activeRunData.sequential_id}
                    </h1>

                    <div className="link-light">
                        {activeRunData.pull_request && <GhPrLink pr={activeRunData.pull_request} />}
                    </div>

                    <Typography variant="body2" component="div" color="text.secondary" className="content-rows-s">
                        <Tooltip
                            placement="left"
                            title={`Started at: ${
                                activeRunData.started_at
                                    ? moment(activeRunData.started_at * 1000).format('LLL')
                                    : '--:--'
                            }`}
                        >
                            <div className="text-fragments">
                                <CalendarTodayIcon fontSize="small" />{' '}
                                {activeRunData.started_at ? moment(activeRunData.started_at * 1000).fromNow() : '--:--'}
                            </div>
                        </Tooltip>
                        <Tooltip placement="left" title="Duration">
                            <div className="text-fragments">
                                <AccessTimeIcon fontSize="small" />{' '}
                                {activeRunData.duration
                                    ? moment.utc(activeRunData.duration * 1000).format('mm:ss')
                                    : '--:--'}
                            </div>
                        </Tooltip>
                    </Typography>
                </div>
            </Card>

            {activeRunData.status === 'in_progress' && (
                <div className="progressbar-wrapper">
                    <LinearProgress
                        variant="determinate"
                        value={(activeRunData.progress.current / activeRunData.progress.total) * 100}
                        className="bar"
                    />
                    <div className="value">
                        {activeRunData.progress.current} / {activeRunData.progress.total}
                    </div>
                </div>
            )}

            {activeRunData.status === 'scheduled' ? (
                <EmptyState>Waiting for tests to start …</EmptyState>
            ) : activeRunData.status === 'errored' || activeRunData.status === 'internal_error' ? null : ( // Content is already in the error message
                <>
                    {activeRunData.status !== 'succeeded' && (
                        <Card className="tests-charts">
                            <div className="chart">
                                <h3 className="h3">
                                    Overview{' '}
                                    <span className="small">{activeRunData.groups?.length || 0} endpoints</span>
                                </h3>
                                <ReactEcharts option={chartOverview} style={{ height: 250 }} />
                            </div>

                            <div className="chart">
                                <h3 className="h3">
                                    Failures <span className="small">{activeRunData.stats.issuesCount} total</span>
                                </h3>
                                <ReactEcharts option={chartErrors} style={{ height: 250 }} />
                            </div>
                        </Card>
                    )}

                    <div className="content-sides">
                        <Typography variant="h5">
                            <b>Failures</b>
                        </Typography>

                        <Input
                            type="search"
                            placeholder="Filter failures"
                            onChange={(event) => setFilter(event.target.value)}
                        />
                    </div>

                    <Card sx={{ mb: 2 }}>
                        {activeRunData?.groups?.length ? (
                            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                {activeRunData.groups
                                    .filter((g) => failuresFilter(g, filter))
                                    .map((failure, i) => (
                                        <Issue key={`${failure.name}_${i}`} data={failure} />
                                    ))}
                            </List>
                        ) : (
                            <EmptyState>
                                <Typography
                                    variant="body2"
                                    component="div"
                                    color="text.secondary"
                                    className="content-cols-s"
                                >
                                    {activeRunData.isDeselected ? (
                                        <>
                                            <StatusTagIcon status="deselected" />{' '}
                                            <b>No tests. All API operations were deselected.</b>
                                        </>
                                    ) : (
                                        <>
                                            {activeRunData.status === 'succeeded' && (
                                                <StatusTagIcon status="succeeded" />
                                            )}
                                            <b>
                                                No failures {activeRunData.status === 'in_progress' ? 'yet' : 'found'}
                                            </b>
                                        </>
                                    )}
                                </Typography>
                            </EmptyState>
                        )}
                    </Card>
                </>
            )}
        </div>
    )
}

const Issue: React.FC<{ data: RunGroup }> = ({ data }) => {
    return (
        <div className="list-item">
            <Tooltip placement="left" title={`Status: ${data.status}`}>
                <span>
                    <StatusTagIcon status={data.status} />
                </span>
            </Tooltip>
            <div className={`text ${data.status === 'errored' && data.exception && !data.message ? 'scroll' : ''}`}>
                <div>{data.name}</div>

                {data.status === 'errored' && (
                    <>
                        {data.title && (
                            <div>
                                <strong>{data.title}</strong>
                            </div>
                        )}
                        {data.message && <div className="ternary">{data.message}</div>}

                        <div className="space-y-xs">
                            {data.extras.map((e, i) => (
                                <div key={`${e}_${i}`} className="ternary li">
                                    {e}
                                </div>
                            ))}
                        </div>

                        {data.suggestion && <div className="ternary">{data.suggestion}</div>}

                        {data.exception && !data.message && (
                            <Code code={data.exception} highlight="plaintext" expandable={true} />
                        )}
                    </>
                )}

                {data.status === 'failed' && (
                    <>
                        {data.issues.map((i) => (
                            <Link className="subitem" key={i.id} to={URLS.issues.detail.buildPath({ id: i.id })}>
                                <Tooltip placement="left" title={`Severity: ${i.severity}`}>
                                    <span>
                                        <StatusTagIcon status={i.severity} />
                                    </span>
                                </Tooltip>
                                <div className="w50">
                                    <div className="text">{i.category}</div>
                                    <div className="secondary sections content-cols-s">
                                        {i.is_new && (
                                            <div className={`tag-status colors yellow`}>
                                                <div>New</div>
                                            </div>
                                        )}
                                        <div>
                                            #{i.short_id} | {i.description}
                                        </div>
                                    </div>
                                </div>

                                <div className="w50 valign secondary">
                                    <CalendarTodayIcon fontSize="small" />{' '}
                                    <Tooltip
                                        placement="right"
                                        title={`First seen: ${moment(i.first_seen * 1000).format('LLL')}`}
                                    >
                                        <span>{moment(i.first_seen * 1000).fromNow()}</span>
                                    </Tooltip>
                                </div>
                                <div className="valign">
                                    <ChevronRightIcon />
                                </div>
                            </Link>
                        ))}
                    </>
                )}
            </div>
        </div>
    )
}

export default RunDetail
