import { useAuth } from 'contexts/AuthContext'

import InternalLayout from './InternalLayout'
import PublicLayout from './PublicLayout'

const AppLayouts: React.FC = () => {
    const auth = useAuth()
    if (auth.isAuthenticated) {
        return <InternalLayout />
    }
    return <PublicLayout />
}

export default AppLayouts
