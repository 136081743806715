import { useUsers } from 'contexts/UsersContext'
import { Organization } from '../api'

const ORGANIZATION_SETTINGS_ACCESS_ROLES = ['owner', 'admin']

export function hasAdminAccess(role: string) {
    return ORGANIZATION_SETTINGS_ACCESS_ROLES.includes(role)
}

export function hasIndividualAccess(roles: string[], role: string) {
    return roles.includes(role)
}

export function buildPotentialOwners({
    organizations,
}: {
    organizations?: Organization[]
}): { value: string | undefined }[] {
    const { hasAccess } = useUsers()
    const potentialOwners = []
    potentialOwners.push({ value: '' })
    potentialOwners.push(
        ...(organizations || [])
            .filter((org) => hasAdminAccess(org.role) && hasAccess('organization:admin'))
            .map(({ slug }) => {
                return { value: slug }
            }),
    )
    return potentialOwners
}
