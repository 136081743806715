/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Failure } from '../models/Failure'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class IssuesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * The latest failure on particular issue.
     * @returns Failure Failure data
     * @throws ApiError
     */
    public issuesFailuresLatest({
        issueId,
    }: {
        /**
         * Issue ID.
         */
        issueId: number
    }): CancelablePromise<Failure> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/issues/{issue_id}/failures/latest/',
            path: {
                issue_id: issueId,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
}
