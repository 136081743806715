import React, { useState } from 'react'

import { Link } from 'react-router-dom'

import { URLS } from 'urls'

import { Alert, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import LoadingButton from '@mui/lab/LoadingButton'
import { useMutation } from 'react-query'
import UnauthorizedPageLayout from '../../../components/UnauthorizedPageLayout'
import { apiClient, ApiError, PasswordRecoveryRequest } from '../../../api'
import Footer from '../../AppLayouts/PublicLayout/Footer'
import { useAuth } from '../../../contexts/AuthContext'

function ForgotPassword() {
    const { passwordRecoveryError, setPasswordRecoveryError } = useAuth()
    const [successMessage, setSuccessMessage] = useState<string | undefined>()

    const {
        control,
        handleSubmit,
        formState: { errors, dirtyFields },
    } = useForm({
        defaultValues: {
            email: '',
        },
    })
    const mutation = useMutation(
        async (requestBody: PasswordRecoveryRequest) => apiClient.users.usersPasswordsRecovery({ requestBody }),
        {
            onMutate: () => {
                setPasswordRecoveryError(undefined)
            },
            onSuccess: ({ message }) => {
                setSuccessMessage(message)
            },
            onError: (error: ApiError) => {
                if (error.body) {
                    setPasswordRecoveryError(error.body.detail)
                } else {
                    setPasswordRecoveryError('Could not connect to the server, please try again in a few seconds.')
                }
            },
        }
    )
    const onSubmit = (data: PasswordRecoveryRequest) => mutation.mutate(data)

    return (
        <UnauthorizedPageLayout
            title="Reset your password"
            content={
                <>
                    {passwordRecoveryError ? (
                        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                            {passwordRecoveryError}
                        </Alert>
                    ) : null}
                    {mutation.isSuccess && <Alert severity="success">{successMessage}</Alert>}
                    {!mutation.isSuccess && (
                        <form onSubmit={handleSubmit(onSubmit)} className="content-rows" autoComplete="off">
                            <Alert severity="info">
                                Enter your account's email and we'll send you a password reset link.
                            </Alert>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Please input email',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        type="email"
                                        label="Enter your email address"
                                        variant="standard"
                                        {...field}
                                        error={!!errors.email}
                                        helperText={errors?.email?.message}
                                    />
                                )}
                            />

                            <LoadingButton
                                loadingPosition="start"
                                loading={mutation.isLoading}
                                type="submit"
                                variant="contained"
                                sx={{ mt: 2 }}
                                disabled={!('email' in dirtyFields)}
                            >
                                Send password reset email
                            </LoadingButton>
                        </form>
                    )}
                </>
            }
            footer={
                <Footer>
                    <Link to={URLS.auth.signIn.route}>Back to sign in</Link>
                </Footer>
            }
        />
    )
}

export default ForgotPassword
