import { Link } from 'react-router-dom'
import { URLS } from 'urls'
import { Button } from '@mui/material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'

import '../../../../static/styles/components/section-empty.less'

export const NoApiIntro: React.FC = () => {
    return (
        <div className="section-empty">
            <h1>No projects yet!</h1>
            <p>You need at least one project to start testing</p>
            <div className="actions">
                <Link key="create" to={URLS.projects.create.route}>
                    <Button variant="contained" startIcon={<AddOutlinedIcon />}>
                        Create Project
                    </Button>
                </Link>
            </div>
        </div>
    )
}
