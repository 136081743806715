import React, { useState } from 'react'

import { Alert, Box, Divider, TextField, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from 'contexts/AuthContext'
import { URLS } from 'urls'

import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import LoadingButton from '@mui/lab/LoadingButton'
import UnauthorizedPageLayout from '../../../components/UnauthorizedPageLayout'
import Footer from '../../AppLayouts/PublicLayout/Footer'
import GitHubButton from './GitHubButton'
import { apiClient, ApiError, BackUrl, OrganizationInvitation, TokenRequest } from '../../../api'
import InvitationHeader, { extractInviteFromParameters } from './InvitationHeader'
import useQueryParams from '../../../hooks/useQueryParams'

function SignIn({
    gitHubAuthError,
    setGitHubAuthError,
}: {
    gitHubAuthError: string | undefined
    setGitHubAuthError: (value: string | undefined) => void
}) {
    const auth = useAuth()
    const navigate = useNavigate()
    const [signInError, setSignInError] = useState<string | undefined>(undefined)

    const {
        control,
        handleSubmit,
        formState: { errors, dirtyFields },
    } = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
    })

    const query = useQueryParams()

    const { inviteMatch, organization, invitationId } = extractInviteFromParameters(query.next)

    const { data: invitation, error } = useQuery<OrganizationInvitation, ApiError>({
        queryKey: ['organizationInviteData', organization],
        queryFn: async () => apiClient.invitations.invitationsDetails({ organization, invitationId }),
        enabled: inviteMatch !== false,
        retry: false,
    })

    const signInMutation = useMutation(async (formData: TokenRequest) => auth.signIn({ formData }), {
        onSuccess: () => {
            auth.setIsAuthenticated(true)
            setSignInError(undefined)
            if (query.next) {
                navigate(query.next)
            } else {
                navigate(URLS.projects.index.route)
            }
        },
        onError: (error: ApiError) => {
            if (error.body) {
                setSignInError(error.body.detail || 'Unable to sign up with provided credentials')
            } else {
                setSignInError('Could not connect to the server, please try again in a few seconds.')
            }
        },
    })
    const onSubmit = (formData: TokenRequest) => signInMutation.mutate(formData)

    const [gitHubSignUpLoading, setGitHubSignUpLoading] = useState<boolean>(false)
    const signInGitHub = (back_to: BackUrl) => {
        apiClient.auth
            .authSocialGithubAuthorize({
                requestBody: { back_to, next: query.next },
                query,
            })
            .then(({ authorize_url }) => {
                window.location.replace(authorize_url)
            })
            .catch((error: ApiError) => {
                setGitHubSignUpLoading(false)
                if (error.body) {
                    setSignInError(error.body.detail)
                } else {
                    setSignInError('Could not connect to the server, please try again in a few seconds.')
                }
            })
    }

    return (
        <UnauthorizedPageLayout
            title="Sign In"
            header={<InvitationHeader error={error} invitation={invitation} />}
            content={
                <>
                    {gitHubAuthError ? (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {gitHubAuthError}
                        </Alert>
                    ) : null}
                    <Box mt={2} mb={2}>
                        <GitHubButton
                            onClick={() => {
                                setGitHubAuthError(undefined)
                                setGitHubSignUpLoading(true)
                                signInGitHub('sign_in')
                            }}
                            loading={gitHubSignUpLoading}
                            text="Sign in with GitHub"
                        />
                    </Box>
                    <Divider sx={{ mt: 2 }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>OR</Typography>
                    </Divider>
                    {signInError ? (
                        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                            {signInError}
                        </Alert>
                    ) : null}
                    <form onSubmit={handleSubmit(onSubmit)} className="content-rows" autoComplete="off">
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Please input email',
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    type="email"
                                    label="Email"
                                    variant="standard"
                                    {...field}
                                    error={!!errors.email}
                                    helperText={errors?.email?.message}
                                />
                            )}
                        />

                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    type="password"
                                    label="Password"
                                    variant="standard"
                                    {...field}
                                    error={!!errors.password}
                                    helperText={errors?.password?.message}
                                />
                            )}
                        />
                        <Typography sx={{ textAlign: 'right', fontSize: 12 }}>
                            <Link to={URLS.auth.resetPassword.route}>Forgot your password?</Link>
                        </Typography>
                        <LoadingButton
                            loadingPosition="start"
                            loading={signInMutation.isLoading}
                            type="submit"
                            variant="contained"
                            disabled={!('email' in dirtyFields && 'password' in dirtyFields)}
                        >
                            Sign In
                        </LoadingButton>
                    </form>
                </>
            }
            footer={
                <Footer>
                    New to Schemathesis.io?{' '}
                    <Link to={URLS.auth.signUp.buildPath({ queryParams: query })}>Create an account</Link>
                </Footer>
            }
        />
    )
}

export default SignIn
