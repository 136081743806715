import React from 'react'
import { Avatar } from '@mui/material'
import '../styles/notificationsSnippet.less'
import { AccountCircle } from '@mui/icons-material'

export const UserAvatar: React.FC<{
    alt?: string
    avatar_url?: string | null
    size?: 'medium' | 'big'
    color?: 'default' | 'gray'
}> = ({ alt, avatar_url, size = 'medium', color = 'default' }) => {
    const dim = size === 'big' ? 40 : 30

    return avatar_url ? (
        <Avatar
            alt={alt || 'User avatar'}
            src={avatar_url}
            sx={{ width: dim, height: dim }}
            style={{
                border: '1px solid #938ea7',
            }}
        />
    ) : (
        <AccountCircle className={color === 'gray' ? 'text-gray-700' : ''} sx={{ width: dim, height: dim }} />
    )
}
