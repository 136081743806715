import React, { useCallback, useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams, useNavigate } from 'react-router-dom'
import { URLS } from 'urls'
import { useSnackbar } from 'notistack'
import { Alert, Card, CircularProgress } from '@mui/material'
import { useMutation } from 'react-query'
import LoadingButton from '@mui/lab/LoadingButton'
import CenteredPageLayout from 'components/CenteredPageLayout'
import { CreateApiError } from '../CreateAPI/CreateApiError'
import APIForm from '../CreateAPI/APIForm'
import { apiClient, ApiError, APIErrorResponse, UpdateAPIRequest } from '../../../../api'
import { APP_NAME } from 'config'
import Dialog from 'components/Dialog'
import { ProjectContext } from 'features/contexts/projects'

const APIWidgetDetail: React.FC = () => {
    const apiDetail = useContext(ProjectContext)
    const { id } = useParams<{ id: string }>()
    const { enqueueSnackbar } = useSnackbar()
    const [removing, setRemoving] = useState(false)
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleCancel = () => {
        setOpen(false)
    }

    const updateAPIMutation = useMutation(
        (data: UpdateAPIRequest) => apiClient.apis.apisUpdate({ apiId: id!, requestBody: data }),
        {
            onSuccess: () => {
                apiDetail?.refetch()
                enqueueSnackbar('API updated successfully', { variant: 'success' })
            },
        },
    )

    const handleRemove = useCallback(() => {
        setRemoving(true)
        apiClient.apis.apisDelete({ apiId: id! }).then(() => {
            enqueueSnackbar('API successfully removed', { variant: 'success' })
            navigate(URLS.projects.index.route)
        })
    }, [enqueueSnackbar, id, navigate])

    if (apiDetail?.isLoading) {
        return (
            <div>
                <CircularProgress />
            </div>
        )
    }
    if (apiDetail?.isError) {
        return <Alert color="error">Error</Alert>
    }

    const errorResponse: APIErrorResponse | undefined =
        updateAPIMutation.error && (updateAPIMutation.error as ApiError).body

    const defaultState = {
        name: apiDetail?.data?.name || '',
        auth: apiDetail?.data?.auth?.type || ('NoAuth' as const),
        headerName: '',
        headerValue: '',
        password: '',
        username: '',
    }
    switch (apiDetail?.data?.auth?.type) {
        case 'HttpBasic':
            defaultState.username = apiDetail?.data?.auth?.username
            defaultState.password = apiDetail?.data?.auth?.password
            defaultState.auth = 'HttpBasic'
            break
        case 'Header':
            defaultState.headerName = apiDetail?.data?.auth?.name
            defaultState.headerValue = apiDetail?.data?.auth?.value
            defaultState.auth = 'Header'
            break
    }

    return (
        <CenteredPageLayout>
            <Helmet>
                <title>APIs | {APP_NAME}</title>
            </Helmet>
            {updateAPIMutation.isLoading && (
                <div className="absolute">
                    <CircularProgress />
                </div>
            )}

            <div className="content-cards">
                {errorResponse && <CreateApiError error={errorResponse} />}

                <APIForm
                    // key is here to force reset internal state of the form
                    key={JSON.stringify(apiDetail?.data)}
                    onFinish={updateAPIMutation.mutate}
                    defaultState={defaultState}
                />
            </div>

            <Card className="card-error">
                <div className="section-header">
                    <h2 className="h2">Danger zone</h2>
                </div>
                <div className="section-block">
                    <h3 className="h3">Remove project</h3>
                    <p>
                        Remove <em>{apiDetail?.data?.name}</em> project and all related data.
                    </p>
                    <p>This action cannot be undone.</p>
                </div>
                <div className="section-actions">
                    <LoadingButton
                        key="Remove API"
                        variant="contained"
                        color="error"
                        loading={removing}
                        onClick={handleClickOpen}
                    >
                        Remove project
                    </LoadingButton>
                </div>
                <Dialog
                    open={open}
                    handleCancel={handleCancel}
                    handleAction={handleRemove}
                    title="Are you sure you want to delete this?"
                    content="Once deleted, it will be gone forever."
                    actionText="Delete"
                />
            </Card>
        </CenteredPageLayout>
    )
}

export default APIWidgetDetail
