import { URLS } from '../urls'
import { ApiClient } from './client'

export type {
    TermsOfService,
    TokenRequest,
    AccessToken,
    AccessTokens,
    UserProfile,
    RunList,
    APIList,
    APIDetails,
    PasswordResetRequest,
    PasswordRecoveryRequest,
    ApiError,
    ShortIssue,
    ResponseMetadata,
    OrganizationMemberList,
    OrganizationInvitation,
    OrganizationMember,
    SignupRequest,
    BackUrl,
    UpdateAPIRequest,
    Failure,
    HistoryEntry,
    StringListMap,
    StringMap,
    JsonSchemaValidationError,
    JsonDeserializationContext,
    ServerError,
    MissingContentTypeHeaderError,
    UndefinedContentTypeError,
    UndefinedStatusCodeError,
    MissingHeadersError,
    MalformedMediaTypeError,
    ResponseTimeExceededError,
    RequestTimeoutError,
    API,
    NewAPIRequest,
    APIErrorResponse,
    APIIssues,
    GitHubInternalError,
    GitHubUnsupported,
    GitHubReject,
    Organization,
    OrganizationDetailsData,
    OrganizationInvitee,
    OrganizationInvitationList,
} from './client'

export const baseUrl = process.env.REACT_APP_API_HOST || 'http://127.0.0.1:8001'

export const apiClient = new ApiClient({
    BASE: baseUrl,
    WITH_CREDENTIALS: true,
})

export const buildSignInUrl = (): string => {
    return URLS.auth.signIn.buildPath({
        queryParams: {
            next: window.location.pathname.startsWith('/settings')
                ? '/'
                : window.location.pathname + window.location.search,
        },
    })
}
