import React from 'react'

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { JsonSchemaValidationError } from '../../../../../api'

const PathChunk: React.FC<{children?: React.ReactNode;}> = ({ children }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                whiteSpace: 'pre',
                fontSize: '12px',
                lineHeight: 'normal',
                color: 'black',
            }}
        >
            {children}
        </Box>
    )
}

function ObjectPath({ path }: { path: (string | number)[] }) {
    return (
        <>
            {path.length > 0 ? (
                <>
                    {path.map((p, idx) => (
                        <PathChunk key={idx}>
                            <span>{'/ '}</span>
                            <pre style={{ marginBottom: 0 }}>{p} </pre>
                        </PathChunk>
                    ))}
                </>
            ) : (
                <PathChunk>
                    <span>{'/ '}</span>
                </PathChunk>
            )}
        </>
    )
}

function HighlightedJson({ value, path }: { value: unknown; path: (string | number)[] }) {
    const indentLevel = 4

    const containsKeyword = (line: string): boolean =>
        line.startsWith(`${' '.repeat(indentLevel * path.length)}"${path[path.length - 1]}":`)

    return (
        <pre style={{ lineHeight: 'normal', marginBottom: 0 }}>
            {JSON.stringify(value, null, indentLevel)
                .split('\n')
                .map((line, idx) => {
                    let extraStyle = {}
                    if (containsKeyword(line)) {
                        extraStyle = { color: 'red', fontWeight: 'bold' }
                    }
                    return (
                        <p key={idx} style={{ marginBottom: 4, ...extraStyle }}>
                            {line}
                        </p>
                    )
                })}
        </pre>
    )
}

function JSONSchema({ context }: { context: JsonSchemaValidationError }) {
    return (
        <TableContainer>
            <Table sx={{ minWidth: 700, mb: 3 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left" width="50%" style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                            <strong>Response Fragment</strong>
                        </TableCell>
                        <TableCell align="left" width="50%">
                            <strong>Schema</strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell
                            align="left"
                            width="50%"
                            style={{
                                borderRight: '1px solid rgba(224, 224, 224, 1)',
                                verticalAlign: 'top',
                            }}
                        >
                            <HighlightedJson value={context.instance} path={context.instance_path} />
                        </TableCell>
                        <TableCell align="left" width="50%" style={{ verticalAlign: 'top' }}>
                            <HighlightedJson value={context.schema} path={context.schema_path} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            align="left"
                            width="50%"
                            style={{
                                borderRight: '1px solid rgba(224, 224, 224, 1)',
                                verticalAlign: 'top',
                                borderBottom: 0,
                            }}
                        >
                            <ObjectPath path={context.instance_path} />
                        </TableCell>
                        <TableCell align="left" width="50%" style={{ verticalAlign: 'top', borderBottom: 0 }}>
                            <ObjectPath path={context.schema_path} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default JSONSchema
