import { ApiError, OrganizationList } from '../api/client'
import { apiClient } from '../api'
import { useQuery } from 'react-query'

export const useOrganizations = ({
    queryKey,
    enabled = true,
    retry = true,
}: {
    queryKey: string[]
    enabled?: boolean
    retry?: boolean
}) =>
    useQuery<OrganizationList, ApiError>({
        queryKey,
        queryFn: async () => apiClient.organizations.organizationsList(),
        enabled: enabled,
        retry: retry,
    })
