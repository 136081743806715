import React, { useRef, useState } from 'react'
import moment from 'moment'
import { ListItem, ListItemText, Typography, Button, Box, Badge, Popover } from '@mui/material'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import { apiClient } from 'api'
import { useUsers } from 'contexts/UsersContext'
import { ListMenuItem } from 'layout/components/menuLayout'
import '../styles/notificationsSnippet.less'

const Notification: React.FC<{
    id: number
    created_at: string
    title: string
    summary: string
    onDone?: (id: number) => void
}> = ({ created_at, id, onDone, summary, title }) => {
    return (
        <ListItem alignItems="flex-start" key={id} dense>
            <ListItemText primary={<strong>{title}</strong>} secondary={<>{summary}</>} />
            <ListItemText
                primary={<Typography variant="caption">{moment(created_at).fromNow()}</Typography>}
                className="text-gray-500"
                sx={{ textAlign: 'right', maxWidth: 110, minWidth: 110 }}
                secondary={
                    onDone ? (
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                                onDone(id)
                            }}
                        >
                            Done
                        </Button>
                    ) : null
                }
            />
        </ListItem>
    )
}

const NotificationSnippet: React.FC = () => {
    const { user, userObject, hasAccess } = useUsers()
    const notifications = user?.notifications || []
    const [openedMenu, setOpenedMenu] = useState<boolean>(false)
    const wrapperRef = useRef(null)

    const handleClose = () => {
        setOpenedMenu(false)
    }
    const handleOpen = () => {
        setOpenedMenu(!openedMenu)
    }

    return (
        <div className="notifications-snippet" key="notifications" onClick={handleOpen} ref={wrapperRef}>
            <ListMenuItem
                url={''}
                icon={<NotificationsNoneOutlinedIcon />}
                tooltip="Notifications"
                children={
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Badge badgeContent={notifications.length} color="error" className="notifications-badge" />
                    </Box>
                }
            />

            <Popover
                open={openedMenu}
                onClose={handleClose}
                anchorEl={wrapperRef.current}
                className="notifications-snippet-popover"
            >
                <div className="header">
                    <h4>Notifications</h4>
                    {hasAccess('profile:admin') && (
                        <Typography
                            variant="caption"
                            component="a"
                            fontWeight="semibold"
                            onClick={() => {
                                apiClient.notifications
                                    .notificationsArchive({
                                        requestBody: {
                                            notification_ids: notifications.map((notification) => notification.id),
                                        },
                                    })
                                    .then(() => userObject.refetch())
                                    .then(() => {
                                        if (notifications.length === 1) {
                                            handleClose()
                                        }
                                    })
                            }}
                        >
                            Mark all as read
                        </Typography>
                    )}
                </div>
                {notifications.map((notification) => (
                    <div key={notification.id}>
                        <Notification
                            id={notification.id}
                            created_at={notification.created_at}
                            title={notification.title}
                            summary={notification.summary}
                            onDone={
                                hasAccess('profile:admin')
                                    ? (id) => {
                                          apiClient.notifications
                                              .notificationsArchive({
                                                  requestBody: { notification_ids: [id] },
                                              })
                                              .then(() => userObject.refetch())
                                              .then(() => {
                                                  if (notifications.length === 1) {
                                                      handleClose()
                                                  }
                                              })
                                      }
                                    : undefined
                            }
                        />
                    </div>
                ))}
            </Popover>
        </div>
    )
}

export default NotificationSnippet
