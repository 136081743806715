import { FormControl, InputLabel, MenuItem, Select, TextField, Button, Card } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { UpdateAPIRequest } from '../../../../api'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ProjectDraft } from 'api/client'
import { ProjectContext } from 'features/contexts/projects'
import { ImportSpecification } from 'features/components/specifications'

export type ApiFormState = {
    name: string
    auth: 'NoAuth' | 'HttpBasic' | 'Header'
    username: string
    password: string
    headerName: string
    headerValue: string
}

const APIForm: React.FC<{
    onFinish: (form: UpdateAPIRequest) => void
    defaultState: Partial<ApiFormState>
}> = ({ onFinish, defaultState }) => {
    const apiDetail = useContext(ProjectContext)
    const [draft, setDraft] = useState<ProjectDraft | undefined>()
    const {
        handleSubmit,
        watch,
        control,
        reset,
        formState: { errors, isDirty },
    } = useForm<ApiFormState>({
        defaultValues: {
            name: '',
            auth: 'NoAuth',
            username: '',
            password: '',
            headerName: '',
            headerValue: '',
            ...defaultState,
        },
    })
    const auth = watch('auth')

    const setDefaultSchema = useCallback(() => {
        if (apiDetail?.data)
            setDraft({
                id: '',
                specification: apiDetail.data.specification,
                name: apiDetail.data.name,
                security: [],
                operations: Array.from({ length: apiDetail.data.specification.operations_count }, () => {
                    return { name: '_' }
                }),
                environments: [],
            })
    }, [apiDetail?.data])

    useEffect(() => {
        setDefaultSchema()
    }, [apiDetail?.data])

    function onSubmit(form: ApiFormState) {
        onFinish({
            name: form.name,
            auth:
                form.auth === 'Header'
                    ? {
                          type: 'Header',
                          name: form.headerName,
                          value: form.headerValue,
                      }
                    : form.auth === 'HttpBasic'
                      ? {
                            type: 'HttpBasic',
                            username: form.username,
                            password: form.password,
                        }
                      : null,
            draft_id: draft?.id || undefined,
        })
    }

    return (
        <>
            <Card>
                <div className="section-header">
                    <h2 className="h2">Details</h2>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="section-block">
                        <div className="form-row">
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: { value: true, message: 'Name is required' } }}
                                render={({ field }) => (
                                    <TextField
                                        label="Name"
                                        variant="standard"
                                        placeholder="My API"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...field}
                                        error={!!errors.name}
                                        helperText={errors?.name?.message || ''}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="section-block">
                        <div className="form-row small">
                            <FormControl variant="standard" fullWidth>
                                <InputLabel>Authentication</InputLabel>
                                <Controller
                                    name="auth"
                                    control={control}
                                    rules={{ required: { value: true, message: 'required' } }}
                                    render={({ field }) => (
                                        <Select {...field} variant="standard">
                                            <MenuItem value="NoAuth">No auth</MenuItem>
                                            <MenuItem value="HttpBasic">HTTP Basic</MenuItem>
                                            <MenuItem value="Header">Header</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </div>
                        {auth === 'HttpBasic' ? (
                            <div className="form-row small">
                                <Controller
                                    name="username"
                                    control={control}
                                    rules={{}}
                                    render={({ field }) => (
                                        <TextField
                                            id="username"
                                            label="Username"
                                            variant="standard"
                                            {...field}
                                            fullWidth
                                            placeholder="Username"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    name="password"
                                    control={control}
                                    rules={{}}
                                    render={({ field }) => (
                                        <TextField
                                            label="Password"
                                            variant="standard"
                                            {...field}
                                            fullWidth
                                            placeholder="Password"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        ) : null}
                        {auth === 'Header' ? (
                            <div className="form-row small">
                                <Controller
                                    name="headerName"
                                    control={control}
                                    rules={{}}
                                    render={({ field }) => (
                                        <TextField
                                            label="Header name"
                                            variant="standard"
                                            {...field}
                                            fullWidth
                                            placeholder="Name"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    name="headerValue"
                                    control={control}
                                    rules={{}}
                                    render={({ field }) => (
                                        <TextField
                                            label="Header value"
                                            variant="standard"
                                            {...field}
                                            fullWidth
                                            placeholder="Value"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        ) : null}
                    </div>

                    <div className="section-block">
                        <ImportSpecification
                            draft={draft}
                            setDraft={setDraft}
                            showTip={false}
                            sourceUrl={
                                apiDetail?.data?.source.kind === 'remote' ? apiDetail?.data?.source.location : undefined
                            }
                        />
                    </div>

                    <div className="section-actions">
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                reset()
                                setDefaultSchema()
                            }}
                            disabled={!(isDirty || !draft || draft?.id)}
                        >
                            Reset
                        </Button>
                        <Button type="submit" variant="contained" disabled={!(isDirty || draft?.id)}>
                            Save
                        </Button>
                    </div>
                </form>
            </Card>
        </>
    )
}

export default APIForm
