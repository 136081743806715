import { Breadcrumb } from 'layout/models/Breadcrumbs'
import React from 'react'
import { Link } from 'react-router-dom'

export const Bread: React.FC<{
    breadcrumbs: Breadcrumb[]
}> = ({ breadcrumbs }) => {
    return (
        <>
            {breadcrumbs.map((b, i) =>
                b.url && i < breadcrumbs.length - 1 ? (
                    <React.Fragment key={i}>
                        <Link to={b.url}>
                            {b.icon && b.icon}
                            {b.title}
                        </Link>{' '}
                        <span>&raquo;</span>
                    </React.Fragment>
                ) : (
                    <span key={i}>{b.title}</span>
                )
            )}
        </>
    )
}
