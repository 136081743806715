import { Tooltip, IconButton } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

export function ExpandButton({ onClick }: { onClick: () => void }) {
    return (
        <Tooltip title="expand">
            <IconButton aria-label="collapse" size="small" onClick={onClick}>
                <ExpandMoreIcon fontSize="small" />
            </IconButton>
        </Tooltip>
    )
}

export function CollapseButton({ onClick }: { onClick: () => void }) {
    return (
        <Tooltip title="collapse">
            <IconButton aria-label="collapse" size="small" onClick={onClick}>
                <ExpandLessIcon fontSize="small" />
            </IconButton>
        </Tooltip>
    )
}
