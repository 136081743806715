/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProjectDraft } from '../models/ProjectDraft'
import type { ProjectDraftInput } from '../models/ProjectDraftInput'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class ProjectsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns ProjectDraft Project draft created.
     * @throws ApiError
     */
    public projectsDraftsCreate({ formData }: { formData: ProjectDraftInput }): CancelablePromise<ProjectDraft> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/projects/drafts/',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
                413: `Generic error response.`,
            },
        })
    }
}
