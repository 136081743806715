import { LoadingButton } from '@mui/lab'
import { Box, Button, Card, TextField } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { apiClient, ApiError, Organization } from '../../../api'
import { URLS } from '../../../urls'
import React, { useState } from 'react'
import Dialog from '../../../components/Dialog'
import { useUsers } from 'contexts/UsersContext'

type OrganizationUpdateFormState = {
    name: string
    contact_email: string
}

const OrganizationProfile: React.FC<{ organization: Organization }> = ({ organization }) => {
    const { hasAccess } = useUsers()
    const { enqueueSnackbar } = useSnackbar()

    const {
        clearErrors,
        setError,
        control,
        handleSubmit,
        watch,
        formState: { isDirty, errors },
    } = useForm<OrganizationUpdateFormState>({
        defaultValues: {
            name: organization.name,
            contact_email: organization.contact_email,
        },
    })
    const navigate = useNavigate()

    const updateProfileMutation = useMutation(
        async (requestBody: OrganizationUpdateFormState) =>
            apiClient.organizations.organizationsUpdate({
                organization: organization.slug,
                requestBody,
            }),
        {
            onMutate: () => {
                clearErrors()
            },
            onSuccess: (org) => {
                clearErrors()
                enqueueSnackbar('Organization profile updated', { variant: 'success' })
                navigate(URLS.organizations.profile.buildPath({ slug: org.slug }))
            },
            onError: (response: ApiError) => {
                clearErrors()
                if (response.body) {
                    const { errors } = response.body
                    for (const [name, fieldErrors] of Object.entries(errors)) {
                        // @ts-expect-error
                        for (const error of fieldErrors) {
                            if (name === 'name') {
                                setError(name, { type: 'custom', message: error.message })
                            }
                            if (name === 'contact_email') {
                                setError(name, {
                                    type: 'custom',
                                    message: error.message || 'Ensure you entered a valid email',
                                })
                            }
                        }
                    }
                }
            },
        },
    )

    const watchName = watch('name')

    const onSubmit = (data: OrganizationUpdateFormState) => {
        updateProfileMutation.mutate(data)
    }

    return (
        <div className="content-rows">
            <Card>
                <Box component="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="section-header">
                        <h2 className="h2">Details</h2>
                    </div>
                    <div className="section-block">
                        <Box display="flex" justifyContent="space-between">
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        label="Organization name"
                                        type="name"
                                        size="small"
                                        variant="outlined"
                                        sx={{
                                            width: '45%',
                                            mt: 2,
                                            mb: 1,
                                        }}
                                        {...field}
                                        error={!!errors.name}
                                        helperText={errors?.name?.message}
                                        disabled={!hasAccess('organization:admin')}
                                    />
                                )}
                            />
                            <Controller
                                name="contact_email"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        label="Contact email"
                                        type="contact_email"
                                        size="small"
                                        variant="outlined"
                                        sx={{
                                            width: '45%',
                                            mt: 2,
                                            mb: 1,
                                        }}
                                        {...field}
                                        error={!!errors.contact_email}
                                        helperText={errors?.contact_email?.message}
                                        disabled={!hasAccess('organization:admin')}
                                    />
                                )}
                            />
                        </Box>
                    </div>
                    {hasAccess('organization:admin') && (
                        <div className="section-actions">
                            <LoadingButton
                                // loading={updateProfileMutation.isLoading}
                                type="submit"
                                variant="contained"
                                disabled={watchName === '' || !isDirty}
                            >
                                Update
                            </LoadingButton>
                        </div>
                    )}
                </Box>
            </Card>

            {hasAccess('organization:admin') && <DeleteOrganization organization={organization} />}
        </div>
    )
}

const DeleteOrganization: React.FC<{ organization: Organization }> = ({ organization }) => {
    const [open, setOpen] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleCancel = () => {
        setOpen(false)
    }
    const handleDeleteOrganization = () => {
        apiClient.organizations.organizationsDelete({ organization: organization.slug }).then(() => {
            enqueueSnackbar(`Organization ${organization.name} was deleted`, { variant: 'success' })
            navigate(URLS.organizations.index.route)
        })
    }

    return (
        <Card className="card-error">
            <div className="section-header">
                <h2 className="h2">Danger zone</h2>
            </div>
            <div className="section-block">
                <h3 className="h3">Remove organization</h3>
                <p>Removing {organization.name} cannot be undone!</p>
            </div>
            <div className="section-actions">
                <Button variant="contained" color="error" onClick={handleClickOpen}>
                    Delete this organization
                </Button>
            </div>
            <Dialog
                open={open}
                handleCancel={handleCancel}
                handleAction={handleDeleteOrganization}
                title="Are you sure you want to delete this?"
                content="Once deleted, it will be gone forever."
                actionText="Delete"
            />
        </Card>
    )
}

export default OrganizationProfile
