import { SvgIcon } from '@mui/material'

export const IconPullRequest: React.FC = () => (
    <SvgIcon>
        <svg fill="currentColor" width="80px" height="80px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path d="M192,96a64,64,0,1,0-96,55.39V360.61a64,64,0,1,0,64,0V151.39A64,64,0,0,0,192,96ZM128,64A32,32,0,1,1,96,96,32,32,0,0,1,128,64Zm0,384a32,32,0,1,1,32-32A32,32,0,0,1,128,448Z" />
            <path d="M416,360.61V156a92.1,92.1,0,0,0-92-92H304V32a16,16,0,0,0-27.31-11.31l-64,64a16,16,0,0,0,0,22.62l64,64A16,16,0,0,0,304,160V128h20a28,28,0,0,1,28,28V360.61a64,64,0,1,0,64,0ZM384,448a32,32,0,1,1,32-32A32,32,0,0,1,384,448Z" />
        </svg>
    </SvgIcon>
)

export default IconPullRequest
