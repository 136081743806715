import {
    Button,
    Card,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import moment from 'moment'
import { useQueryClient } from 'react-query'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import React, { useState } from 'react'

import DataRow from '../../components/DataRow'
import Dialog from '../../components/Dialog'
import { TitleBlock } from 'layout/components/titles'
import { CopyValue } from 'utils/components/code'
import { AccessTokensProvider } from 'features/modelts/accessTokens'

const AccessTokens: React.FC<{ provider: AccessTokensProvider; queryKey: string[] }> = ({ provider, queryKey }) => {
    const [tokenToDelete, setTokenToDelete] = useState<number | undefined>()

    const openDeleteDialog = (tokenId: number) => {
        setTokenToDelete(tokenId)
    }
    const closeDeleteDialog = () => {
        setTokenToDelete(undefined)
    }

    const queryClient = useQueryClient()

    return (
        <div className="content-rows">
            <TitleBlock
                title="Access tokens"
                subtitle="Tokens you have generated to access Schemathesis.io API via CLI"
                extra={
                    <div className="content-cols">
                        <Button
                            variant="contained"
                            onClick={() => {
                                provider.createToken().then(() => {
                                    queryClient.invalidateQueries(queryKey)
                                })
                            }}
                        >
                            Generate new token
                        </Button>
                    </div>
                }
            />

            <Card className="card">
                <Dialog
                    open={tokenToDelete !== undefined}
                    handleCancel={closeDeleteDialog}
                    handleAction={() => {
                        if (tokenToDelete) {
                            provider
                                .deleteToken({ tokenId: tokenToDelete })
                                .then(closeDeleteDialog)
                                .then(() => {
                                    queryClient.invalidateQueries(queryKey)
                                })
                        }
                    }}
                    title={'Deleting this token will affect your current integrations using the token'}
                    actionText="I understand, delete this token"
                    content={
                        provider.data?.length === 1 && (
                            <>
                                Deleting last token. You'll need to recreate tokens for existing projects manually, as
                                they won't be auto-regenerated.
                            </>
                        )
                    }
                />

                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold', width: '45%' }}>Token</TableCell>
                            <TableCell align="right" style={{ fontWeight: 'bold', width: '15%' }}>
                                Created
                            </TableCell>
                            <TableCell align="right" style={{ fontWeight: 'bold', width: '20%' }}>
                                Last Used
                            </TableCell>
                            <TableCell align="right" style={{ fontWeight: 'bold' }} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {provider.isLoading ? (
                            <DataRow>
                                <TableCell component="th" scope="row" colSpan={4} sx={{ textAlign: 'center' }}>
                                    <CircularProgress />
                                </TableCell>
                            </DataRow>
                        ) : (
                            <>
                                {provider.data?.length === 0 ? (
                                    <DataRow>
                                        <TableCell component="th" scope="row" colSpan={4} sx={{ textAlign: 'center' }}>
                                            <Typography variant="subtitle1">
                                                You don't have any access tokens
                                            </Typography>
                                        </TableCell>
                                    </DataRow>
                                ) : (
                                    <>
                                        {provider.data?.map((row) => (
                                            <TableRow
                                                key={row.id}
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <pre
                                                        style={{
                                                            padding: 8,
                                                            margin: 0,
                                                            borderRadius: '4px',
                                                            background: 'rgb(229, 231, 235)',
                                                            maxWidth: 'min-content',
                                                        }}
                                                    >
                                                        {row.token}
                                                    </pre>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {moment(row.created_at).format('MMM Do YYYY')}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.last_used_at ? moment(row.last_used_at).fromNow() : 'never'}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <CopyValue value={row.token} />
                                                    <IconButton onClick={() => openDeleteDialog(row.id)}>
                                                        <DeleteOutlinedIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                    </TableBody>
                </Table>
            </Card>
        </div>
    )
}

export default AccessTokens
