import AlertBox from '../../../components/AlertBox'

export const FAILED_SUBSCRIPTION = (
    <AlertBox severity="error" title="Subscription Failed">
        There was an issue with your subscription. For assistance, please contact: support@schemathesis.io.
    </AlertBox>
)
export const SUCCESSFUL_SUBSCRIPTION = (
    <AlertBox severity="success" title="Subscription Successful!">
        Thank you for subscribing! If you need any help, feel free to reach out to support@schemathesis.io. <br />
        Happy testing!
    </AlertBox>
)
