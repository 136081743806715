import React from 'react'

import { Chip, Stack, Typography } from '@mui/material'
import { UndefinedContentTypeError } from '../../../../../api'

function UndefinedContentType({ context }: { context: UndefinedContentTypeError }) {
    return (
        <Stack spacing={1}>
            <Typography>
                Actual: <Chip color="error" size="small" label={context.content_type} />
            </Typography>
            <Typography style={{ marginBottom: 0 }}>
                Expected:{' '}
                {context.defined_content_types.map((c: string) => (
                    <Chip size="small" label={c} />
                ))}
            </Typography>
        </Stack>
    )
}

export default UndefinedContentType
