/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TermsOfService } from '../models/TermsOfService'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class TermsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Retrieve Terms of Service
     * @returns TermsOfService Terms of Service response
     * @throws ApiError
     */
    public termsDetails({
        version,
    }: {
        /**
         * Terms of Service version
         */
        version: 'latest'
    }): CancelablePromise<TermsOfService> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/terms/{version}/',
            path: {
                version: version,
            },
            errors: {
                404: `Generic error response.`,
            },
        })
    }
}
