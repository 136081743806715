/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest'
import type { OpenAPIConfig } from './core/OpenAPI'
import { AxiosHttpRequest } from './core/AxiosHttpRequest'
import { ApisService } from './services/ApisService'
import { AuthService } from './services/AuthService'
import { BannersService } from './services/BannersService'
import { BillingService } from './services/BillingService'
import { CliService } from './services/CliService'
import { CoverageService } from './services/CoverageService'
import { DefaultService } from './services/DefaultService'
import { GitHubService } from './services/GitHubService'
import { InvitationsService } from './services/InvitationsService'
import { IssuesService } from './services/IssuesService'
import { NotificationsService } from './services/NotificationsService'
import { OrganizationsService } from './services/OrganizationsService'
import { ProjectsService } from './services/ProjectsService'
import { ReportsService } from './services/ReportsService'
import { RunsService } from './services/RunsService'
import { TermsService } from './services/TermsService'
import { UsersService } from './services/UsersService'
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest
export class ApiClient {
    public readonly apis: ApisService
    public readonly auth: AuthService
    public readonly banners: BannersService
    public readonly billing: BillingService
    public readonly cli: CliService
    public readonly coverage: CoverageService
    public readonly default: DefaultService
    public readonly gitHub: GitHubService
    public readonly invitations: InvitationsService
    public readonly issues: IssuesService
    public readonly notifications: NotificationsService
    public readonly organizations: OrganizationsService
    public readonly projects: ProjectsService
    public readonly reports: ReportsService
    public readonly runs: RunsService
    public readonly terms: TermsService
    public readonly users: UsersService
    public readonly request: BaseHttpRequest
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'https://api.schemathesis.io',
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        })
        this.apis = new ApisService(this.request)
        this.auth = new AuthService(this.request)
        this.banners = new BannersService(this.request)
        this.billing = new BillingService(this.request)
        this.cli = new CliService(this.request)
        this.coverage = new CoverageService(this.request)
        this.default = new DefaultService(this.request)
        this.gitHub = new GitHubService(this.request)
        this.invitations = new InvitationsService(this.request)
        this.issues = new IssuesService(this.request)
        this.notifications = new NotificationsService(this.request)
        this.organizations = new OrganizationsService(this.request)
        this.projects = new ProjectsService(this.request)
        this.reports = new ReportsService(this.request)
        this.runs = new RunsService(this.request)
        this.terms = new TermsService(this.request)
        this.users = new UsersService(this.request)
    }
}
