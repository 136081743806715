import { useUsers } from '../../../contexts/UsersContext'
import { apiClient, ApiError, UserProfile } from '../../../api'
import { useQuery } from 'react-query'
import { BillingInfo, CancelablePromise } from '../../../api/client'
import { AccountBlock, CurrentSubscription, RecurringProductsList, STRIPE_INFO_BLOCK } from '../Billing/Products'
import { AccountType } from 'models/Account'
import React from 'react'
import { UserAvatar } from 'features/components/users'

export const PersonalAccountBlock: React.FC<{ user: UserProfile }> = ({ user }) => {
    const account = {
        icon: <UserAvatar alt={user.username} avatar_url={user.avatar_url} size="big" />,
        name: user.name ? (
            <>
                {user.name} ({user.username})
            </>
        ) : (
            <>{user.username}</>
        ),
        type: AccountType.INDIVIDUAL,
        subtitle: 'Your personal account',
    }
    return <AccountBlock account={account} />
}

const Billing: React.FC = () => {
    const users = useUsers()

    const { data, error, isLoading } = useQuery<BillingInfo, ApiError>(
        ['individualProductList'],
        async () => apiClient.billing.billingProductsList(),
        { retry: false }
    )

    const createCheckout = (
        price_id: string
    ): CancelablePromise<{
        checkout_url: string | null
    }> => {
        return apiClient.billing.billingCheckoutCreate({ requestBody: { price_id } })
    }

    return (
        <div className="content-rows">
            {users.user && <PersonalAccountBlock user={users.user} />}
            {STRIPE_INFO_BLOCK}
            {data && data.current_subscription !== null && (
                <CurrentSubscription subscription={data.current_subscription} />
            )}
            <RecurringProductsList
                error={error}
                isLoading={isLoading}
                products={data?.products}
                createCheckout={createCheckout}
                checkIfAvailable={() => null}
            />
        </div>
    )
}

export default Billing
