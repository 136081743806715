import React, { useContext, useMemo, useState } from 'react'
import { Alert, Button, Card, Tab, Tabs, Typography } from '@mui/material'
import { Code } from '../APIs/APIsList/Code'
import { Link, useParams } from 'react-router-dom'
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from 'react-query'
import { apiClient } from 'api'
import { AccessToken, ApiError, ProjectEnvironment } from 'api/client'
import { Chips } from 'utils/components/chips'
import { SetState } from 'models/Utils'
import { URLS } from 'urls'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import { ProjectContext } from 'features/contexts/projects'

const NO_TOKEN_MSG = '<YOU_HAVE_NO_TOKEN>'
const NO_ENV_MSG = '<YOU_HAVE_NO_ENVIRONMENT>'
const MANY_ENV_MSG = '<YOUR_ENVIRONMENT>'

type CreateToken = UseMutateFunction<
    {
        token: string
    },
    ApiError,
    void,
    unknown
>

const codeGithubActions = (conf: ProjConf) => `- name: API tests
  uses: schemathesis/action@v1
  with:
    api_name: ${conf.organization === undefined ? '' : conf.organization + '/'}${conf.name}
    token: \${{ secrets.SCHEMATHESIS_TOKEN }}`

const codeGitLabPipelines = (conf: ProjConf) => `api-tests:
  stage: test
  image:
    name: schemathesis/schemathesis:stable
    entrypoint: [""]

  variables:
    SCHEMATHESIS_TOKEN: \${{ secrets.SCHEMATHESIS_TOKEN }}

  script:
    - schemathesis run ${conf.organization === undefined ? '' : conf.organization + '/'}${conf.name} --checks=all --report`

const CODE_DOCKER_INSTALL = `docker pull schemathesis/schemathesis
# Verify installation
docker run schemathesis/schemathesis --version`

const CODE_PYTHON_INSTALL = `python -m pip install --upgrade schemathesis
# Verify installation
schemathesis --version`

const codeDockerAuth = (conf: ProjConf) => `docker run schemathesis/schemathesis auth login ${conf.pregeneratedToken}`
const codePythonAuth = (conf: ProjConf) => `schemathesis auth login ${conf.pregeneratedToken}`

const codePythonRun = (conf: ProjConf) =>
    `schemathesis run ${conf.organization === undefined ? '' : conf.organization + '/'}${
        conf.name
    } --checks all --report`
const codeDockerRun = (conf: ProjConf) =>
    `docker run schemathesis/schemathesis run ${conf.organization === undefined ? '' : conf.organization + '/'}${
        conf.name
    } --checks all --report`


enum EnvironmentTabs {
    Local,
    CI,
}

const languages = ['Docker', 'Python']

const cis = ['GitHub', 'GitLab']

type ProjConf = {
    name?: string
    organization?: string
    pregeneratedToken: string
    env?: string
}

const TabLabel: React.FC<{ value: string }> = ({ value }) => {
    return (
        <Typography m={1} sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            {value}
        </Typography>
    )
}

const DocsEnvironmentCI: React.FC<{
    show: boolean
    config: ProjConf
    tokenIsLoading: boolean
    createToken: CreateToken
}> = ({ show, config, tokenIsLoading, createToken }) => {
    const [ci, setCi] = useState<string>('GitHub')

    return (
        <div hidden={!show}>
            <p className="p">
                Integrate Schemathesis into your CI pipeline by simply copying the provided configuration. This
                automates API testing and ensures its continuous reliability. Select your CI provider to view the
                relevant setup:
            </p>

            <Chips values={cis} value={ci} setValue={setCi} />

            <div hidden={ci !== 'GitHub'}>
                <Code code={codeGithubActions(config)} highlight="yaml" />
            </div>

            <div hidden={ci !== 'GitLab'}>
                <Code code={codeGitLabPipelines(config)} highlight="yaml" />
            </div>

            <p className="p">Don't forget to add your Schemathesis token to your CI secrets:</p>

            <div className="content-cols">
                <Code code={config.pregeneratedToken} highlight="bash" />{' '}
                {config.pregeneratedToken === NO_TOKEN_MSG && (
                    <Button variant="contained" color="primary" disabled={tokenIsLoading} onClick={() => createToken()}>
                        Generate token
                    </Button>
                )}
            </div>

            <p className="p">Don't worry, you can always manage your tokens in your account settings.</p>
        </div>
    )
}

const DocsEnvironmentLocal: React.FC<{
    show: boolean
    config: ProjConf
    tokenIsLoading: boolean
    createToken: CreateToken
}> = ({ show, config, tokenIsLoading, createToken }) => {
    const [lang, setLang] = useState<string>('Docker')

    return (
        <div hidden={!show}>
            <p className="p">
                In a local environment, you'll first install Schemathesis on your machine. This allows for direct test
                execution and is ideal for initial experimentation and development. After installation, you can readily
                run tests and adjust configurations as needed.
            </p>

            <h3 className="h3">
                <SettingsOutlinedIcon /> Installation
            </h3>

            <p className="p">
                You can install Schemathesis using Docker or Python. Docker isolates Schemathesis from your system,
                eliminating potential conflicts, but might have more overhead. Python installation is more
                straightforward but requires a Python environment setup.
            </p>

            <Chips values={languages} value={lang} setValue={setLang} />

            <div hidden={lang !== 'Docker'}>
                <p className="p">
                    <strong>Prerequisites:</strong> Docker 17.05 or higher.
                </p>

                <Code code={CODE_DOCKER_INSTALL} highlight="bash" />
            </div>

            <div hidden={lang !== 'Python'}>
                <p className="p">
                    <strong>Prerequisites:</strong> Python 3.9 or higher.
                </p>

                <Code code={CODE_PYTHON_INSTALL} highlight="bash" />
            </div>

            <h3 className="h3">
                <GppGoodOutlinedIcon />
                Authentication
            </h3>

            <p className="p">
                Logging in to Schemathesis.io connects your test reports with your account, so you don't need to enter
                your token for each command:
            </p>

            <div hidden={lang !== 'Docker'}>
                <div className="content-cols">
                    <Code code={codeDockerAuth(config)} highlight="bash" />
                    {config.pregeneratedToken === NO_TOKEN_MSG && (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={tokenIsLoading}
                            onClick={() => createToken()}
                        >
                            Generate token
                        </Button>
                    )}
                </div>
            </div>

            <div hidden={lang !== 'Python'}>
                <div className="content-cols">
                    <Code code={codePythonAuth(config)} highlight="bash" />
                    {config.pregeneratedToken === NO_TOKEN_MSG && (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={tokenIsLoading}
                            onClick={() => createToken()}
                        >
                            Generate token
                        </Button>
                    )}
                </div>
            </div>

            <Alert severity="info">
                Don't worry, you can always manage your tokens in your{' '}
                {config.organization ? (
                    <Link to={URLS.organizations.tokens.buildPath({ slug: config.organization })}>
                        organization settings
                    </Link>
                ) : (
                    <Link to={URLS.settings.tokens.route}>account settings</Link>
                )}
                .
            </Alert>

            <h3 className="h3">
                <AssessmentOutlinedIcon />
                Running tests
            </h3>

            <p className="p">
                Now you're ready to start running tests against your API. Here's a simple command to get you going:
            </p>
            <Code code={lang === 'Python' ? codePythonRun(config) : codeDockerRun(config)} highlight="bash" />
        </div>
    )
}

const DocsKindLocal: React.FC<{
    config: ProjConf
    environment: EnvironmentTabs
    setEnvironment: SetState<EnvironmentTabs>
    envTabs: React.ReactNode
    tokenIsLoading: boolean
    createToken: CreateToken
}> = ({ config, environment, setEnvironment, envTabs, tokenIsLoading, createToken }) => {
    return (
      <>
        <p className="p">
            Select whether tests are initiated from your local machine for development and experimentation or from a
            Continuous Integration environment for automated testing.
        </p>

        <div className="content-center">
            <Tabs
                className="tabs"
                value={environment}
                onChange={(event, value) => {
                    setEnvironment(value)
                }}
                aria-label="Set quick-start env"
            >
                {envTabs}
            </Tabs>
        </div>

        <div className="content-padded">
            <hr className="hr space-y" />

            <DocsEnvironmentLocal
                show={environment === EnvironmentTabs.Local}
                config={config}
                tokenIsLoading={tokenIsLoading}
                createToken={createToken}
            />

            <DocsEnvironmentCI
                show={environment === EnvironmentTabs.CI}
                config={config}
                tokenIsLoading={tokenIsLoading}
                createToken={createToken}
            />

            <Alert severity="error">
                Remember, never run tests against your production environment to avoid any unintended disruptions or
                data changes.
            </Alert>
        </div>
      </>
    )
}

/**
 * A quick-start guide for working with a newly added project.
 */
export const GettingStarted: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const project = useContext(ProjectContext)

    const queryClient = useQueryClient()

    const { data: envs, isLoading: isEnvLoading } = useQuery<ProjectEnvironment[]>(['ApiEnvs', id], () =>
        apiClient.apis.apisEnvironmentsList({ apiId: id! }),
    )
    const { data: token, isLoading } = useQuery<{ token: AccessToken | null }>(['AccessTokens', id], () =>
        apiClient.apis.projectsTokensDetails({ apiId: id! }),
    )
    const tokensMutation = useMutation(
        ['AccessTokensMut', project?.data?.organization],
        async () =>
            project?.data?.organization
                ? await apiClient.organizations.organizationsTokensCreate({
                      organization: project.data.organization.slug,
                  })
                : await apiClient.users.usersTokensCreate(),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['AccessTokens'])
            },
            onError: (response: ApiError) => {
                //
            },
        },
    )

    const config = useMemo(() => {
        return {
            name: project?.data?.name || '',
            organization: project?.data?.organization?.slug,
            pregeneratedToken: isLoading ? '' : token?.token?.token || NO_TOKEN_MSG,
            env: isEnvLoading ? '' : envs?.length === 0 ? NO_ENV_MSG : envs?.length === 1 ? envs[0].name : MANY_ENV_MSG,
        }
    }, [project?.data, envs, token, isLoading, isEnvLoading])

    const [environment, setEnvironment] = useState<EnvironmentTabs>(EnvironmentTabs.Local)

    const localEnvTab = <Tab value={EnvironmentTabs.Local} key={1} label={<TabLabel value="Local development" />} />
    const ciEnvTab = <Tab value={EnvironmentTabs.CI} key={2} label={<TabLabel value="Continuous Integration" />} />

    const envTabs = [localEnvTab, ciEnvTab]

    return (
        <Card className="card">
            <div className="section-block">
                <div className="content-sides content-centered">
                    <h1 className="h1">Getting started</h1>
                    <div>
                        <Button
                            variant="outlined"
                            color="primary"
                            href="https://docs.schemathesis.io/quick-start"
                            target="_blank"
                        >
                            Full documentation
                        </Button>
                    </div>
                </div>

                <DocsKindLocal
                    config={config}
                    environment={environment}
                    setEnvironment={setEnvironment}
                    envTabs={envTabs}
                    tokenIsLoading={tokensMutation.isLoading}
                    createToken={tokensMutation.mutate}
                />
            </div>
            <div className="section-actions">
                <Link to={URLS.projects.index.route}>
                    <Button variant="contained">Go to the Project Dashboard</Button>
                </Link>
            </div>
        </Card>
    )
}
