import React from 'react'
import { Avatar } from '@mui/material'
import '../styles/notificationsSnippet.less'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'

export const OrganizationAvatar: React.FC<{
    alt: string
    avatar_url: string | null
    size?: 'medium' | 'big'
}> = ({ alt, avatar_url, size = 'medium' }) => {
    const dim = size === 'big' ? 40 : 30

    return avatar_url ? (
        <Avatar
            alt={alt}
            src={avatar_url}
            sx={{ width: dim, height: dim }}
            style={{
                border: '1px solid #938ea7',
            }}
        />
    ) : (
        <BusinessOutlinedIcon className="text-gray-700" sx={{ width: dim, height: dim }} />
    )
}
