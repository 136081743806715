import { Alert, Button, Card, CircularProgress, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiError, apiClient } from '../../../api'
import { URLS } from '../../../urls'
import CenteredPageLayout from 'components/CenteredPageLayout'
import { CancelError, ReportDetails } from 'api/client'

const WaitForReportTemplate: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
    <CenteredPageLayout>
        <Card className="card" sx={{ py: 30 }}>
            {children}
        </Card>
    </CenteredPageLayout>
)

/**
 * Wait for report & redirect to the corresponding test run page, once the report is processed.
 */
const WaitForReport: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()
    // Fetch data from the backend until the report is available or there is an error from backend
    const [isEnabled, setIsEnabled] = useState<boolean>(true)
    const {
        data: report,
        isFetching,
        error,
    } = useQuery<ReportDetails, ApiError | CancelError>(
        ['report', id],
        async () => apiClient.reports.reportsDetail({ reportId: id! }),
        {
            refetchInterval: 1000,
            enabled: isEnabled,
            onError: () => {
                setIsEnabled(false)
            },
        },
    )

    // Test report has associated run ID & api ID - redirect to the run page
    if (
        report !== undefined &&
        report.api_id !== null &&
        report.test_run_id !== null &&
        report.state === 'processing_succeeded'
    ) {
        navigate(URLS.projects.run.buildPath({ id: report.api_id, runId: report.test_run_id }))
        return <></>
    }
    // Report failed on the upload stage
    if (report?.state === 'upload_failed') {
        return (
            <WaitForReportTemplate>
                <div className="content-rows center">
                    <Alert severity="error">An error happened during report upload. Try again later.</Alert>
                </div>
            </WaitForReportTemplate>
        )
    }
    // Report failed on the processing stage
    if (report?.state === 'processing_failed') {
        return (
            <WaitForReportTemplate>
                <div className="content-rows center">
                    <Alert severity="error">Failed to process your report: {report?.detail}</Alert>
                </div>
            </WaitForReportTemplate>
        )
    }
    // Still processing
    if (isFetching || report?.state === 'pending' || report?.state === 'upload_succeeded') {
        return (
            <WaitForReportTemplate>
                <div className="content-rows center">
                    <CircularProgress />
                    <Typography sx={{ mt: 2 }}>Waiting for report processing ...</Typography>
                </div>
            </WaitForReportTemplate>
        )
    }

    return (
        <WaitForReportTemplate>
            <div className="content-rows center">
                <Alert severity="error">
                    {error && !(error instanceof CancelError) ? error.body.detail : 'Failed to load your report :('}
                </Alert>
                <Button variant="contained" sx={{ mt: 2 }} onClick={() => setIsEnabled(true)}>
                    Try again
                </Button>
            </div>
        </WaitForReportTemplate>
    )
}

export default WaitForReport
