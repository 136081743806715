/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CliProjectDetails } from '../models/CliProjectDetails'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class CliService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get project details
     * @returns CliProjectDetails Project details response
     * @throws ApiError
     */
    public cliProjectsGet({ name }: { name: string }): CancelablePromise<CliProjectDetails> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/cli/projects/{name}/',
            path: {
                name: name,
            },
        })
    }
    /**
     * Get project details
     * @returns CliProjectDetails Project details response
     * @throws ApiError
     */
    public cliProjectsOrganizationsGet({
        organization,
        name,
    }: {
        organization: string
        name: string
    }): CancelablePromise<CliProjectDetails> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/cli/projects/{organization}/{name}/',
            path: {
                organization: organization,
                name: name,
            },
        })
    }
}
