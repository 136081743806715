import { Stack, Typography } from '@mui/material'
import { JsonDeserializationContext } from '../../../../../api'

function JSONDecode({ context }: { context: JsonDeserializationContext }) {
    return (
        <Stack spacing={1} sx={{ mb: 1 }}>
            <Typography>
                <strong>Position</strong>:{context.position}
            </Typography>
            <Typography>
                <strong>Line number</strong>:{context.lineno}
            </Typography>
            <Typography>
                <strong>Column number</strong>:{context.colno}
            </Typography>
        </Stack>
    )
}

export default JSONDecode
