import { Box, CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { URLS } from '../urls'
import { apiClient } from '../api'

function ShortUrl() {
    const { key } = useParams<{ key: string }>()
    const [redirect, setRedirect] = useState<string | undefined>()

    useEffect(() => {
        if (!redirect && key) {
            apiClient.default
                .redirect({ key })
                .then(({ path }) => {
                    setRedirect(path)
                })
                .catch(() => {
                    setRedirect(URLS.projects.index.route)
                })
        }
    }, [redirect, setRedirect, key])

    return (
        <>
            {redirect === undefined && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            )}
            {redirect !== undefined && <Navigate to={redirect} />}
        </>
    )
}

export default ShortUrl
