/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackUrl } from '../models/BackUrl'
import type { CliLoginRequest } from '../models/CliLoginRequest'
import type { GitHubConnectAccounts } from '../models/GitHubConnectAccounts'
import type { GitHubDeny } from '../models/GitHubDeny'
import type { GitHubNewIdentity } from '../models/GitHubNewIdentity'
import type { GitHubSignIn } from '../models/GitHubSignIn'
import type { GitHubSignUp } from '../models/GitHubSignUp'
import type { TokenRequest } from '../models/TokenRequest'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class AuthService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get access token.
     * @returns void
     * @throws ApiError
     */
    public authToken({ formData }: { formData: TokenRequest }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/token/',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                400: `Generic error response.`,
            },
        })
    }
    /**
     * Authorize CLI.
     * @returns any Successful authorization.
     * @throws ApiError
     */
    public authCliLogin({ requestBody }: { requestBody: CliLoginRequest }): CancelablePromise<{
        username: string
        account_type: 'individual' | 'organization'
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/cli/login/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
    /**
     * Logout from the system.
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public authLogout(): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/logout/',
            errors: {
                400: `Response on malformed HTTP requests.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
    /**
     * GitHub authorization page.
     * @returns any Redirecting to GitHub.
     * @throws ApiError
     */
    public authSocialGithubAuthorize({
        requestBody,
        query,
    }: {
        requestBody: {
            back_to: BackUrl
            next: string | null
        }
        query?: Record<string, string>
    }): CancelablePromise<{
        authorize_url: string
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/social/github/',
            query,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
            },
        })
    }
    /**
     * GitHub authorization callback.
     * @returns any Successful authorization
     * @throws ApiError
     */
    public authSocialGithubCallback({
        code,
        state,
    }: {
        code?: string
        state?: string
    }): CancelablePromise<GitHubSignIn | GitHubSignUp | GitHubConnectAccounts | GitHubNewIdentity | GitHubDeny> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/social/github/callback/',
            query: {
                code: code,
                state: state,
            },
            errors: {
                400: `Invalid input`,
                401: `Failed authorization`,
                500: `Internal server error`,
            },
        })
    }
}
