import { useQuery } from 'react-query'
import { AccessTokens, ApiError, AccessToken, apiClient } from 'api'
import { AccessTokensProvider } from 'features/modelts/accessTokens'

const usePersonalAccessTokens = (queryKey: string): AccessTokensProvider => {
    const { isLoading, data } = useQuery<AccessTokens, ApiError, AccessToken[]>(
        [queryKey],
        async () => apiClient.users.usersTokensList(),
        {
            select: (data) => data.items,
        },
    )

    const createToken = () => apiClient.users.usersTokensCreate()
    const deleteToken = ({ tokenId }: { tokenId: number }) => apiClient.users.usersTokensDelete({ tokenId: tokenId })

    return {
        isLoading,
        data,
        createToken,
        deleteToken,
    }
}

export { usePersonalAccessTokens }
