import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/tests.less'
import { RunDetails } from 'api/client'
import IconPullRequest from 'utils/icons/pullRequest'

export const GhPrLink: React.FC<{
    pr: NonNullable<RunDetails['pull_request']>
}> = ({ pr }) => {
    return (
        <Link
            to={pr.url}
            target="_blank"
            rel="noreferrer"
            className="link-light secondary"
            onClick={(e) => e.stopPropagation()}
        >
            <strong>
                <IconPullRequest /> #{pr.id}
            </strong>
            <br />
            <strong>{pr.head_ref}</strong>
        </Link>
    )
}
