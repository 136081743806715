import styled from '@emotion/styled'
import { Box } from '@mui/material'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import React, { MutableRefObject, useRef, ChangeEvent, useEffect, MouseEvent } from 'react'
import { useDragging } from 'utils/hooks/useDragging'

import '../styles/uploaders.less'

const UploaderWrapper = styled.label`
    position: relative;
    &:focus-within {
        outline: 2px solid black;
    }
    & > input {
        display: block;
        opacity: 0;
        position: absolute;
        pointer-events: none;
    }
`

const drawDescription = (isDragging: boolean, inputRef: MutableRefObject<HTMLInputElement | null>) => {
    return (
        <Box className={`uploader-droparea ${isDragging && 'darker'}`}>
            {!isDragging && <CloudUploadOutlinedIcon color="action" sx={{ mr: 1, fontSize: '64px' }} />}
            <div className="text-gray-500 content-rows-s">
                <span className="text-l">Drag and drop to get started</span>
                {!isDragging && (
                    <span className="text-sx">
                        Browse to{' '}
                        <a
                            onClick={() => {
                                if (inputRef.current) {
                                    inputRef.current.click()
                                }
                            }}
                        >
                            select a file
                        </a>
                    </span>
                )}
            </div>
        </Box>
    )
}

export const SpecificationUploader: React.FC<{
    name: string
    onChange: (arg0: File) => void
    file?: File | null
}> = ({ name, onChange, file }) => {
    const labelRef = useRef<HTMLLabelElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    const blockEvent = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault()
        event.stopPropagation()
    }
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        if (inputRef && inputRef.current) {
            inputRef.current.value = ''
            inputRef.current.click()
        }
    }
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            onChange(event.target.files[0])
        }
    }

    const isDragging = useDragging({
        labelRef,
        inputRef,
        onChange,
    })

    useEffect(() => {
        if (!file && inputRef.current) inputRef.current.value = ''
    }, [file])

    return (
        <UploaderWrapper ref={labelRef} htmlFor={name} onClick={blockEvent}>
            <input
                onClick={handleClick}
                onChange={handleInputChange}
                ref={inputRef}
                type="file"
                name={name}
                multiple={false}
                required={true}
            />
            {drawDescription(isDragging, inputRef)}
        </UploaderWrapper>
    )
}
