import {
    Box,
    Alert,
    Chip,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Divider,
    FormHelperText,
    Grid,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    MenuItem,
    Menu,
} from '@mui/material'
import React, { FC, useState } from 'react'
import { ApiError, CancelablePromise, Price, Product, Subscription } from '../../../api/client'
import { useMutation } from 'react-query'
import { useNavigate, useLocation } from 'react-router-dom'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import { LoadingButton } from '@mui/lab'
import { StripeLogo } from '../../../components/Stripe/Logo'
import ErrorBlock from '../../../components/Error'
import { FAILED_SUBSCRIPTION, SUCCESSFUL_SUBSCRIPTION } from './Alerts'
import AlertBox from '../../../components/AlertBox'
import { hasAdminAccess } from '../../../utils/access'
import { useUsers } from '../../../contexts/UsersContext'
import { URLS } from '../../../urls'
import moment from 'moment'
import { isAccount, isOrganization } from 'utils/account'
import { AccountType, Account } from 'models/Account'
import { useOrganizations } from '../../../queries/useOrganizations'

interface ProductCardProps {
    product: Product
    isActive: boolean
    createCheckout: (price_id: string) => CancelablePromise<{
        checkout_url: string | null
    }>
    checkIfAvailable: (product: Product) => string | null
}

const PricingColumn: FC<{ children?: React.ReactNode }> = ({ children }) => {
    return (
        <Box>
            <Typography variant="overline">Pricing</Typography>
            <Box>{children}</Box>
        </Box>
    )
}

interface PricingBlockProps {
    prices: Price[]
    isActive: boolean
    isDisabled: boolean
    createCheckout: (price_id: string) => CancelablePromise<{
        checkout_url: string | null
    }>
}

const getCurrencySymbol = (currency: string) => {
    switch (currency) {
        case 'usd':
            return '$'
        case 'eur':
            return '€'
        default:
            return ',-'
    }
}

const PricingBlock: FC<PricingBlockProps> = ({ prices, isActive, isDisabled, createCheckout }) => {
    const { hasAccess } = useUsers()
    const activePrice = prices.find((price) => price.is_active === true)
    const [selectedPrice, setSelectedPrice] = useState<Price | undefined>(activePrice || prices[0])
    const [checkoutError, setCheckoutError] = useState<string | null>(null)
    const [isRedirecting, setIsRedirecting] = useState<boolean>(false)

    const handlePriceChange = (event: React.MouseEvent<HTMLElement>, newPriceId: string | null) => {
        if (newPriceId !== null) {
            const newPrice = prices.find((price) => price.id === newPriceId)
            setSelectedPrice(newPrice)
        }
    }

    const checkoutMutation = useMutation(async (price_id: string) => createCheckout(price_id), {
        onSuccess: (data) => {
            if (data.checkout_url !== null) {
                setIsRedirecting(true)
                window.location.href = data.checkout_url
            } else {
                setCheckoutError('Your checkout session has expired, please try again in a few seconds.')
            }
        },
        onError: (error: ApiError) => {
            if (error.body) {
                setCheckoutError(error.body.detail || 'Unable to sign up with provided credentials')
            } else {
                setCheckoutError('Could not connect to the server, please try again in a few seconds.')
            }
        },
    })

    const handleCheckout = () => {
        if (selectedPrice) {
            checkoutMutation.mutate(selectedPrice.id)
        }
    }

    const convertIntervalToText = (interval: string) => {
        switch (interval) {
            case 'day':
                return 'Daily'
            case 'week':
                return 'Weekly'
            case 'month':
                return 'Monthly'
            case 'year':
                return 'Annually'
            default:
                return interval
        }
    }

    return (
        <PricingColumn>
            {activePrice === undefined && (
                <ToggleButtonGroup
                    value={selectedPrice?.id}
                    exclusive
                    onChange={handlePriceChange}
                    size="small"
                    aria-label="price selection"
                >
                    {prices.map((price) => (
                        <ToggleButton value={price.id} aria-label={price.interval || ''} key={price.id}>
                            {convertIntervalToText(price.interval || '')}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            )}

            {selectedPrice && (
                <>
                    <Typography variant="h4" mt={1}>
                        <strong>
                            {getCurrencySymbol(selectedPrice.currency)}
                            {(selectedPrice.unit_amount / 100).toFixed(0)}
                        </strong>
                        <Typography variant="caption" display="block">
                            billed{' '}
                            {selectedPrice.interval && convertIntervalToText(selectedPrice.interval).toLowerCase()}
                        </Typography>
                        {activePrice === undefined && (
                            <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                                <LocalOfferOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                                You get 2 months free with annual subscription
                            </Typography>
                        )}
                    </Typography>
                    {hasAccess('profile:admin') &&
                        (isActive ? (
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ mt: 1 }}
                                href={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK || ''}
                                endIcon={<OpenInNewIcon />}
                                target="_blank"
                            >
                                Manage Subscription
                            </Button>
                        ) : (
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2 }}
                                onClick={handleCheckout}
                                disabled={isDisabled}
                                loading={checkoutMutation.isLoading || isRedirecting}
                            >
                                Subscribe
                            </LoadingButton>
                        ))}
                </>
            )}
            <StripeLogo width="100" height="50" />
            {checkoutError && (
                <FormHelperText sx={{ mr: 2, mt: 0 }} error={true}>
                    <ErrorOutlineOutlinedIcon />
                    &nbsp;
                    {checkoutError}
                </FormHelperText>
            )}
        </PricingColumn>
    )
}

export const RecurringProductCard: FC<ProductCardProps> = ({ product, isActive, createCheckout, checkIfAvailable }) => {
    const notAvailableMessage = checkIfAvailable(product)
    return (
        <Grid item xs={12} sm={12}>
            <Card>
                <CardContent>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h5">{product.name}</Typography>
                        {isActive && (
                            <Chip
                                size="small"
                                label={
                                    <Typography variant="subtitle2" color="text.secondary">
                                        <strong>Current Plan</strong>
                                    </Typography>
                                }
                                sx={{ background: '#ffd093' }}
                            />
                        )}
                    </Box>
                    {notAvailableMessage !== null && <Alert severity="warning">{notAvailableMessage}</Alert>}
                    <Divider sx={{ mt: 1 }} />
                    {product.description !== null && (
                        <Typography variant="subtitle2" sx={{ mt: 2 }}>
                            {product.description}
                        </Typography>
                    )}
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="overline">Includes</Typography>
                            {product.features.map((feature) => (
                                <Box display="flex" alignItems="center" gap={1} mt={1} key={feature.name}>
                                    <CheckCircleOutlinedIcon color="success" fontSize="small" />
                                    <Typography variant="subtitle2">{feature.summary}</Typography>
                                </Box>
                            ))}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {product.is_custom ? (
                                <PricingColumn>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ mt: 2 }}
                                        href="mailto:sales@schemathesis.io?subject=Enterprise%20Plan"
                                        endIcon={<EmailOutlinedIcon />}
                                        target="_blank"
                                    >
                                        Contact Us
                                    </Button>
                                </PricingColumn>
                            ) : product.prices.length > 0 ? (
                                <PricingBlock
                                    prices={product.prices}
                                    isActive={isActive}
                                    createCheckout={createCheckout}
                                    isDisabled={notAvailableMessage !== null}
                                />
                            ) : (
                                <PricingColumn>
                                    <Typography variant="h4" mt={1}>
                                        <strong>Free</strong>
                                    </Typography>
                                </PricingColumn>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}

export function isCancelled(search: string) {
    const queryParams = new URLSearchParams(search)
    return queryParams.has('cancel')
}

export function isSuccessful(search: string) {
    const queryParams = new URLSearchParams(search)
    return queryParams.has('success')
}

interface ProductListProps {
    error: Error | null
    isLoading: boolean
    products?: Product[]
    createCheckout: (price_id: string) => CancelablePromise<{
        checkout_url: string | null
    }>
    checkIfAvailable: (product: Product) => string | null
}

export const RecurringProductsList: React.FC<ProductListProps> = ({
    error,
    isLoading,
    products,
    createCheckout,
    checkIfAvailable,
}) => {
    const location = useLocation()
    return (
        <>
            {isCancelled(location.search) && FAILED_SUBSCRIPTION}
            {isSuccessful(location.search) && SUCCESSFUL_SUBSCRIPTION}
            {error ? (
                <ErrorBlock message="Failed to load available products" />
            ) : isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                products !== undefined &&
                products
                    .filter((product) => product.is_recurring)
                    .map((product: Product, index: number) => (
                        <RecurringProductCard
                            key={index}
                            product={product}
                            isActive={product.is_active}
                            createCheckout={createCheckout}
                            checkIfAvailable={checkIfAvailable}
                        />
                    ))
            )}
        </>
    )
}

interface CurrentSubscriptionProps {
    subscription: Subscription
}

export const CurrentSubscription: React.FC<CurrentSubscriptionProps> = ({ subscription }) => {
    if (subscription.items.length === 0) {
        return <></>
    }
    const nextPaymentItem = subscription.items[0]
    const nextPaymentDate = new Date(subscription.current_period_end * 1000)
    const nextPaymentAmount = nextPaymentItem.unit_amount / 100
    const currencySymbol = getCurrencySymbol(nextPaymentItem.currency)

    return (
        <Card>
            <CardContent>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">
                        <strong>Your next payment</strong>
                    </Typography>
                    <Box display="flex" flexDirection="row" alignItems="baseline">
                        <Typography fontSize="24px" fontWeight="900">{`${currencySymbol}${nextPaymentAmount.toFixed(
                            2,
                        )}`}</Typography>
                        <Typography fontSize="14px" sx={{ ml: 1, color: 'text.secondary' }}>{`Due by ${moment(
                            nextPaymentDate,
                        ).format('MMM DD, YYYY')}`}</Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}

export const STRIPE_INFO_BLOCK = (
    <AlertBox severity="info">
        <strong>Note</strong>: We manage all billing securely via Stripe. You can view invoices, upgrade plans, and
        manage transactions directly in your{' '}
        <a
            style={{ textDecoration: 'underline' }}
            href={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK || ''}
            target="_blank"
            rel="noopener noreferrer"
        >
            Stripe portal
        </a>
        .
    </AlertBox>
)

export const AccountBlock = ({
    account,
}: {
    account: { icon: JSX.Element; name: JSX.Element | string; subtitle: string; type: AccountType }
}) => {
    const navigate = useNavigate()
    const { user, hasAccess } = useUsers()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const { data } = useOrganizations({ queryKey: ['organizationList'], retry: false })

    const isOrganizationAccount = account.type === AccountType.ORGANIZATION
    const accounts: Account[] = []

    if (isOrganizationAccount && user !== undefined) {
        accounts.push(user)
    }

    accounts.push(
        ...(data?.items || []).filter((org) => {
            return (
                hasAdminAccess(org.role) &&
                hasAccess('organization:admin') &&
                !(org.name === account.name && isOrganizationAccount)
            )
        }),
    )

    return (
        <Card>
            <CardContent>
                <Box display="flex" flexDirection="row" width="100%" alignItems="center" justifyContent="space-between">
                    <Box display="flex" flexDirection="row">
                        {account.icon}
                        <Box ml={1} display="flex" flexDirection="column">
                            <Typography fontSize="14px">{account.name}</Typography>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Typography fontSize="12px" fontWeight="900">
                                    {account.subtitle}
                                </Typography>
                                {accounts.length > 0 && (
                                    <>
                                        <Box
                                            id="switch-account-block"
                                            aria-controls={open ? 'switch-account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                            sx={{ cursor: 'pointer', ml: 1 }}
                                        >
                                            <Box display="flex" flexDirection="row" alignItems="center">
                                                <SwapHorizOutlinedIcon
                                                    fontSize="small"
                                                    color="primary"
                                                    sx={{ mr: 1 }}
                                                />
                                                <Typography
                                                    fontSize="10px"
                                                    fontWeight="600"
                                                    color="primary"
                                                    lineHeight="20px"
                                                >
                                                    Switch to another account
                                                </Typography>
                                                <ArrowDropDownOutlinedIcon fontSize="small" color="primary" />
                                            </Box>
                                        </Box>
                                        <Menu
                                            anchorEl={anchorEl}
                                            id="switch-account-menu"
                                            open={open}
                                            onClose={handleClose}
                                            PaperProps={{
                                                elevation: 0,
                                                sx: {
                                                    overflow: 'visible',
                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                    mt: 1.5,
                                                    width: '230px',
                                                },
                                            }}
                                            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                        >
                                            {accounts.map((account, idx) => {
                                                return (
                                                    <MenuItem
                                                        key={idx}
                                                        onClick={() => {
                                                            if (isAccount(account)) {
                                                                navigate(URLS.settings.billing.details.route)
                                                            }
                                                            if (isOrganization(account)) {
                                                                navigate(
                                                                    URLS.organizations.billing.details.buildPath({
                                                                        slug: account.slug,
                                                                    }),
                                                                )
                                                            }
                                                        }}
                                                    >
                                                        <Typography variant="inherit" noWrap>
                                                            {isAccount(account) && account.username}
                                                            {isOrganization(account) && account.slug}
                                                        </Typography>
                                                    </MenuItem>
                                                )
                                            })}
                                        </Menu>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}
