import { Alert } from '@mui/material'
import { ValidationError } from 'api/client'
import { APIErrorResponse } from '../../../../api'
import React from 'react'

export const CreateApiError: React.FC<{ error: APIErrorResponse }> = ({ error }) => {
    return (
        <Alert color="error" className="alert highligh">
            Error: {error.detail}
            <div className="details">
                {error.errors &&
                    Object.entries(error.errors).map(([field, fieldErrors]) => (
                        <div>
                            <strong>{field}: </strong>
                            {Array.isArray(fieldErrors)
                                ? fieldErrors
                                      .map((fieldError: ValidationError) => fieldError.message || 'Invalid value')
                                      .join(', ')
                                : "'Invalid value'"}
                        </div>
                    ))}
            </div>
        </Alert>
    )
}
