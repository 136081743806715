import GitHubIcon from '@mui/icons-material/GitHub'
import LoadingButton from '@mui/lab/LoadingButton'
import React, { MouseEventHandler } from 'react'

const GitHubButton: React.FC<{
    text: string
    loading: boolean
    onClick: MouseEventHandler
}> = ({ text, loading, onClick }) => {
    return (
        <LoadingButton
            loadingPosition="start"
            loading={loading}
            type="button"
            variant="contained"
            startIcon={<GitHubIcon />}
            onClick={onClick}
            fullWidth
            sx={{
                background: '#333333',
                '&:hover': {
                    background: '#555555',
                },
            }}
        >
            {text}
        </LoadingButton>
    )
}

export default GitHubButton
