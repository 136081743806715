import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import { URLS } from 'urls'
import { APP_NAME } from 'config'

import { Box, Button, CircularProgress, Divider, IconButton } from '@mui/material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { useApiList } from 'queries/useApiList'
import { NoApiIntro } from './NoApiIntro'
import APIWidget from './APIWidget'
import { API, apiClient, ApiError, Organization, OrganizationDetailsData } from '../../../../api'
import { useQuery } from 'react-query'
import { hasAdminAccess } from '../../../../utils/access'
import React, { useMemo } from 'react'
import { TitleBlock } from 'layout/components/titles'
import { useUsers } from 'contexts/UsersContext'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { OrganizationAvatar } from 'features/components/organizations'
import { UserAvatar } from 'features/components/users'
import { useOrganizations } from 'queries/useOrganizations'

const APIList: React.FC<{ apis: API[] }> = ({ apis }) => {
    return (
        <div className="content-boxes">
            {apis.map((api, apiIdx) => (
                <Link
                    to={URLS.projects.lastRun.buildPath({
                        id: api.project.id,
                    })}
                    key={apiIdx}
                    className="api-widget-wrapper"
                >
                    <APIWidget api={api} />
                </Link>
            ))}
        </div>
    )
}

const APIHeader: React.FC<{
    label: string
    role: string
    organization?: string
    settingsLink: string
    avatar: React.ReactNode
}> = ({ label, role, organization, settingsLink, avatar }) => {
    const { hasAccess } = useUsers()
    const { data, isLoading } = useQuery<OrganizationDetailsData, ApiError>({
        queryKey: ['organizationDetailsData', organization],
        queryFn: async () => apiClient.organizations.organizationsDetails({ organization: organization || '' }),
        enabled: organization !== undefined,
        retry: false,
    })

    const loadedOrganization = data?.organization

    return (
        <TitleBlock
            title={label}
            subtitle={role}
            avatar={avatar}
            extra={
                <div className="content-cols">
                    {hasAccess('project:write') && (
                        <Link
                            key="create"
                            to={URLS.projects.create.route}
                            state={{ organization: loadedOrganization?.slug }}
                        >
                            {isLoading ? (
                                <CircularProgress />
                            ) : !organization || (loadedOrganization && hasAdminAccess(loadedOrganization.role)) ? (
                                <Button variant="contained" startIcon={<AddOutlinedIcon />}>
                                    Create Project
                                </Button>
                            ) : (
                                <></>
                            )}
                        </Link>
                    )}
                    {((loadedOrganization &&
                        hasAdminAccess(loadedOrganization.role) &&
                        hasAccess('organization:admin')) ||
                        (!loadedOrganization && hasAccess('profile:read'))) && (
                        <IconButton component={Link} to={settingsLink}>
                            <SettingsOutlinedIcon />
                        </IconButton>
                    )}
                </div>
            }
        />
    )
}

type APIs = { [key: string]: API[] }

const APIsList: React.FC = () => {
    const personalApisKey = 'Personal'
    const { user } = useUsers()

    const { isLoading, data: organizations } = useOrganizations({ queryKey: ['organizationList'] })
    const apisQuery = useApiList()

    const apis = apisQuery.data?.items || []

    const apiGroups = useMemo(() => {
        if (!organizations || !apis) return { [personalApisKey]: [] }

        return apis.reduce(
            (acc, current) => {
                const organization = current.organization?.name
                const owner = organization || personalApisKey
                acc[owner] = acc[owner] || []
                acc[owner].push(current)
                return acc
            },
            { [personalApisKey]: [] } as APIs,
        )
    }, [organizations, apis])

    return (
        <>
            <Helmet>
                <title>APIs | {APP_NAME}</title>
            </Helmet>

            {apis.length > 0 && (
                <>
                    <APIHeader
                        label={user?.name || personalApisKey}
                        role={'Personal'}
                        settingsLink={URLS.settings.account.route}
                        avatar={
                            <UserAvatar alt={user?.username} avatar_url={user?.avatar_url} size="big" color="gray" />
                        }
                    />
                    <Divider sx={{ mb: 2 }} />
                </>
            )}

            {apisQuery.isLoading || isLoading ? (
                <div className="center">
                    <CircularProgress />
                </div>
            ) : apis.length === 0 ? (
                <NoApiIntro />
            ) : (
                <>
                    <APIList apis={apiGroups[personalApisKey]} />
                    {organizations &&
                        organizations.items.map((org, idx) => (
                            <Box key={idx}>
                                <APIHeader
                                    label={org.name}
                                    organization={org.slug}
                                    role={org.role}
                                    settingsLink={URLS.organizations.profile.buildPath({
                                        slug: org.slug,
                                    })}
                                    avatar={
                                        <OrganizationAvatar alt={org.slug} avatar_url={org.avatar_url} size="big" />
                                    }
                                />
                                <Divider sx={{ mb: 2 }} />
                                {apiGroups[org.name] && <APIList key={idx} apis={apiGroups[org.name]} />}
                            </Box>
                        ))}
                </>
            )}
        </>
    )
}

export default APIsList
