import { useQuery } from 'react-query'
import { AccessTokens, ApiError, AccessToken, apiClient } from 'api'
import { AccessTokensProvider } from 'features/modelts/accessTokens'

const useOrganizationAccessTokens = (queryKey: string, id: string): AccessTokensProvider => {
    const { isLoading, data } = useQuery<AccessTokens, ApiError, AccessToken[]>(
        [queryKey, id],
        async () => apiClient.organizations.organizationsTokensList({ organization: id }),
        {
            select: (data) => data.items,
        },
    )

    const createToken = () => apiClient.organizations.organizationsTokensCreate({ organization: id })
    const deleteToken = ({ tokenId }: { tokenId: number }) =>
        apiClient.organizations.organizationsTokensDelete({ organization: id, tokenId: tokenId })

    return {
        isLoading,
        data,
        createToken,
        deleteToken,
    }
}

export { useOrganizationAccessTokens }
