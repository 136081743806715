import { Alert, Card, CircularProgress, List, Pagination, Tooltip } from '@mui/material'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'
import { URLS } from 'urls'
import CenteredPageLayout from 'components/CenteredPageLayout'
import { ApiPageHeader } from '../APIs/ApiPageHeader'
import { StatusTagIcon } from './StatusTag'
import '../../../static/styles/components/api.less'
import { useProjectRuns } from 'queries/useProjectRuns'
import { RunDetails } from 'api/client'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useState } from 'react'
import { GhPrLink } from 'features/components/tests'

const PAGE_SIZE = 25

const ApiRunList: React.FC = () => {
    const { id } = useParams<{ id: string }>()

    const [page, setPage] = useState(1)
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    }

    const { isLoading, isError, data, error } = useProjectRuns(id!, page)

    return (
        <CenteredPageLayout>
            <ApiPageHeader id={id!} page="runs" />

            <Card>
                {isLoading ? <CircularProgress /> : null}
                {isError ? <Alert severity="error">{String(error)}</Alert> : null}
                {data ? (
                    data && data.items.length ? (
                        <>
                            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                {data.items.map((run) => (
                                    <Run id={id} run={run} key={run.id} />
                                ))}
                            </List>
                        </>
                    ) : (
                        <div className="content-padded">
                            <Alert severity="info" variant="outlined">
                                No runs
                            </Alert>
                        </div>
                    )
                ) : null}
            </Card>

            {data && !!data.items.length && (
                <div className="content-center">
                    <Pagination
                        page={page}
                        count={Math.ceil(data.metadata.total / PAGE_SIZE)}
                        onChange={handleChange}
                        shape="rounded"
                    />
                </div>
            )}
        </CenteredPageLayout>
    )
}

const Run: React.FC<{ id: string | undefined; run: RunDetails }> = ({ id, run }) => {
    return (
        <Link className="list-item" to={URLS.projects.run.buildPath({ id, runId: run.id })}>
            <StatusTagIcon status={run.status} />

            <div className="text w30">
                <div>
                    <strong>#{run.sequential_id}</strong>
                </div>

                <div className="secondary">
                    {run.progress.current}/{run.progress.total} tested
                </div>
            </div>

            <div className="w30">{run.pull_request && <GhPrLink pr={run.pull_request} />}</div>

            <div className="text secondary w30">
                <Tooltip
                    title={run.started_at ? `Started at: ${moment(run.started_at * 1000).format('LLL')}` : '--:--'}
                >
                    <div>
                        <CalendarTodayIcon /> {run.started_at ? moment(run.started_at * 1000).fromNow() : '--:--'}
                    </div>
                </Tooltip>

                <Tooltip title="Duration">
                    <div>
                        <AccessTimeIcon /> {run.duration ? moment.utc(run.duration * 1000).format('mm:ss') : '--:--'}
                    </div>
                </Tooltip>
            </div>

            <ChevronRightIcon />
        </Link>
    )
}

export default ApiRunList
