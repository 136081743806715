/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessTokens } from '../models/AccessTokens'
import type { BillingInfo } from '../models/BillingInfo'
import type { CheckoutSession } from '../models/CheckoutSession'
import type { FeatureUsage } from '../models/FeatureUsage'
import type { FeatureUsageResponse } from '../models/FeatureUsageResponse'
import type { Organization } from '../models/Organization'
import type { OrganizationDetailsData } from '../models/OrganizationDetailsData'
import type { OrganizationInvitationList } from '../models/OrganizationInvitationList'
import type { OrganizationInvitee } from '../models/OrganizationInvitee'
import type { OrganizationList } from '../models/OrganizationList'
import type { OrganizationMemberList } from '../models/OrganizationMemberList'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class OrganizationsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List organizations.
     * @returns OrganizationList List of organizations.
     * @throws ApiError
     */
    public organizationsList(): CancelablePromise<OrganizationList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * Create a new organization.
     * @returns Organization Details about organization.
     * @throws ApiError
     */
    public organizationsCreate({
        requestBody,
    }: {
        requestBody: {
            name: string
            contact_email: string
        }
    }): CancelablePromise<Organization> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organizations/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
                409: `Generic error response.`,
            },
        })
    }
    /**
     * Get an organization info.
     * @returns OrganizationDetailsData Details about organization.
     * @throws ApiError
     */
    public organizationsDetails({
        organization,
    }: {
        organization: string
    }): CancelablePromise<OrganizationDetailsData> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{organization}/',
            path: {
                organization: organization,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * Update an organization.
     * @returns Organization Details about organization.
     * @throws ApiError
     */
    public organizationsUpdate({
        organization,
        requestBody,
    }: {
        organization: string
        requestBody: {
            name: string
            contact_email: string
        }
    }): CancelablePromise<Organization> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/organizations/{organization}/',
            path: {
                organization: organization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
                409: `Generic error response.`,
            },
        })
    }
    /**
     * Delete an organization.
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public organizationsDelete({ organization }: { organization: string }): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/organizations/{organization}/',
            path: {
                organization: organization,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * List all invitations of an organization.
     * @returns OrganizationInvitationList A list of organization invitations
     * @throws ApiError
     */
    public organizationsInvitationsList({
        organization,
    }: {
        organization: string
    }): CancelablePromise<OrganizationInvitationList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{organization}/invitations/',
            path: {
                organization: organization,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * Invite members to an organization.
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public organizationsInvitationsCreate({
        organization,
        requestBody,
    }: {
        organization: string
        requestBody: Array<OrganizationInvitee>
    }): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organizations/{organization}/invitations/',
            path: {
                organization: organization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
                409: `Generic error response.`,
            },
        })
    }
    /**
     * Cancel invitations.
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public organizationsInvitationsCancel({
        organization,
        requestBody,
    }: {
        organization: string
        requestBody: Array<string>
    }): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organizations/{organization}/invitations/cancel/',
            path: {
                organization: organization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * List all members of an organization.
     * @returns OrganizationMemberList A list of organization members
     * @throws ApiError
     */
    public organizationsMembersList({
        organization,
    }: {
        organization: string
    }): CancelablePromise<OrganizationMemberList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{organization}/members/',
            path: {
                organization: organization,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * Update organization member.
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public organizationsMembersUpdate({
        organization,
        member,
        requestBody,
    }: {
        organization: string
        member: string
        requestBody: {
            role: 'billing' | 'member' | 'admin' | 'manager' | 'owner'
        }
    }): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/organizations/{organization}/members/{member}/',
            path: {
                organization: organization,
                member: member,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * Remove members from an organization.
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public organizationsMembersRemove({
        organization,
        requestBody,
    }: {
        organization: string
        requestBody: Array<string>
    }): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organizations/{organization}/members/remove/',
            path: {
                organization: organization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * List available products.
     * @returns BillingInfo A list of products.
     * @throws ApiError
     */
    public organizationsBillingProductsList({
        organization,
    }: {
        organization: string
    }): CancelablePromise<BillingInfo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{organization}/billing/products/',
            path: {
                organization: organization,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * Checkout.
     * @returns any Session created.
     * @throws ApiError
     */
    public organizationsBillingCheckoutCreate({
        organization,
        requestBody,
    }: {
        organization: string
        requestBody: {
            price_id: string
        }
    }): CancelablePromise<{
        checkout_url: string | null
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organizations/{organization}/billing/checkout/',
            path: {
                organization: organization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
                409: `Generic error response.`,
                500: `Generic error response.`,
            },
        })
    }
    /**
     * Get Checkout Session details.
     * @returns CheckoutSession Details about Checkout Session
     * @throws ApiError
     */
    public organizationsBillingCheckoutDetails({
        organization,
        checkoutSessionId,
    }: {
        organization: string
        checkoutSessionId: string
    }): CancelablePromise<CheckoutSession> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{organization}/billing/checkout/{checkout_session_id}/',
            path: {
                organization: organization,
                checkout_session_id: checkoutSessionId,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
                500: `Generic error response.`,
            },
        })
    }
    /**
     * Leave an organization.
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public organizationsLeave({ organization }: { organization: string }): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organizations/{organization}/leave/',
            path: {
                organization: organization,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * List access tokens.
     * @returns AccessTokens A list of access tokens.
     * @throws ApiError
     */
    public organizationsTokensList({ organization }: { organization: string }): CancelablePromise<AccessTokens> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{organization}/tokens/',
            path: {
                organization: organization,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
    /**
     * Create a new access token for CLI.
     * @returns any A new access token.
     * @throws ApiError
     */
    public organizationsTokensCreate({ organization }: { organization: string }): CancelablePromise<{
        token: string
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organizations/{organization}/tokens/',
            path: {
                organization: organization,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
    /**
     * Delete an access token.
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public organizationsTokensDelete({
        organization,
        tokenId,
    }: {
        organization: string
        /**
         * Access token ID.
         */
        tokenId: number
    }): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/organizations/{organization}/tokens/{token_id}/',
            path: {
                organization: organization,
                token_id: tokenId,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
    /**
     * Current usage of available features.
     * @returns FeatureUsageResponse Successful operation
     * @throws ApiError
     */
    public organizationsUsageList({ organization }: { organization: string }): CancelablePromise<FeatureUsageResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{organization}/usage/',
            path: {
                organization: organization,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * Current usage of a specific feature.
     * @returns any Successful operation
     * @throws ApiError
     */
    public organizationsUsageDetails({
        organization,
        feature,
    }: {
        organization: string
        feature: string
    }): CancelablePromise<FeatureUsage | null> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{organization}/usage/{feature}/',
            path: {
                organization: organization,
                feature: feature,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
}
