import React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

interface ErrorComponentProps {
    icon?: React.ReactNode
    title?: string | React.ReactNode
    message?: string | React.ReactNode
    button?: React.ReactNode
}

const ErrorBlock: React.FC<ErrorComponentProps> = ({ icon, title, message, button }) => {
    const handleRetry = () => {
        window.location.reload()
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
                p: 2,
                color: 'primary',
                borderRadius: 1,
            }}
        >
            {icon !== undefined ? <>{icon}</> : <ErrorOutlineIcon color="error" style={{ fontSize: 100 }} />}
            <Typography variant="h6" align="center" sx={{ fontWeight: 600 }}>
                {title ? title : 'Oops, something went wrong.'}
            </Typography>
            {message && <Typography variant="body1">{message}</Typography>}
            {button !== undefined ? (
                <>{button}</>
            ) : (
                <Button variant="contained" color="primary" onClick={handleRetry}>
                    Try Again
                </Button>
            )}
        </Box>
    )
}

export default ErrorBlock
