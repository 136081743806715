import React, { useEffect, useState } from 'react'

import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined'
import { Helmet } from 'react-helmet'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

import JSONSchema from 'containers/Apps/Issues/IssueDetail/Details/JSONSchema'
import UndefinedContentType from 'containers/Apps/Issues/IssueDetail/Details/UndefinedContentType'
import MissingContentType from 'containers/Apps/Issues/IssueDetail/Details/MissingContentType'
import JSONDecode from 'containers/Apps/Issues/IssueDetail/Details/JSONDecode'
import MissingHeaders from 'containers/Apps/Issues/IssueDetail/Details/MissingHeaders'
import MalformedMediaType from 'containers/Apps/Issues/IssueDetail/Details/MalformedMediaType'
import UndefinedStatusCode from 'containers/Apps/Issues/IssueDetail/Details/UndefinedStatusCode'
import { URLS } from 'urls'
import { APP_NAME } from 'config'

import {
    Alert,
    Box,
    Breadcrumbs,
    Card,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    Link as MLink,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { Code } from '../../APIs/APIsList/Code'
import { apiClient, Failure, HistoryEntry, StringListMap, StringMap } from '../../../../api'

const DataRow: React.FC<{ name: string; value: string }> = ({ name, value }) => {
    return (
        <TableRow sx={{ td: { borderBottom: 0, px: 0, py: 1 } }}>
            <TableCell width="25%">
                <strong>{name}</strong>
            </TableCell>
            <TableCell width="75%">
                <pre
                    style={{
                        padding: 8,
                        margin: 0,
                        borderRadius: '4px',
                        background: 'rgb(229, 231, 235)',
                        minHeight: '36px',
                    }}
                >
                    {value}
                </pre>
            </TableCell>
        </TableRow>
    )
}

const DataTable: React.FC<{ title: string; items: StringListMap | StringMap }> = ({ items, title }) => {
    return (
        <>
            {Object.keys(items).length > 0 ? (
                <>
                    <Typography variant="h6">
                        <strong>{title}</strong>
                    </Typography>
                    <TableContainer>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableBody>
                                {Object.entries(items).map(([name, values], nameIdx) => {
                                    if (Array.isArray(values)) {
                                        return values.map((value: string, valueIdx: number) => (
                                            <DataRow key={`${nameIdx}-${valueIdx}`} name={name} value={value} />
                                        ))
                                    }
                                    return <DataRow key={nameIdx} name={name} value={values} />
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) : undefined}
        </>
    )
}

const RequestResult: React.FC<{ method: string; path: string; status?: number }> = ({ method, path, status }) => {
    return (
        <Typography component="pre" fontFamily="monospace">
            <strong>{method}</strong> {path}
            {status ? (
                <>
                    <ArrowForwardIcon sx={{ ml: 2, mr: 2 }} />[{status}]
                </>
            ) : (
                <></>
            )}
        </Typography>
    )
}

const IssueDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()
    const [issue, setIssue] = useState<Failure | undefined>()
    const [loading, setLoading] = useState(false)
    let responseContentType = null
    if (issue?.response) {
        for (const [header, value] of Object.entries(issue?.response.headers || {})) {
            if (header.toLowerCase() === 'content-type') {
                responseContentType = value[0]
            }
        }
    }
    let responsePayloadHighlight = 'json'
    if (responseContentType && responseContentType.startsWith('text/html')) {
        responsePayloadHighlight = 'html'
    }

    useEffect(() => {
        setLoading(true)
        apiClient.issues
            .issuesFailuresLatest({ issueId: parseInt(id!) })
            .then((issue) => {
                setIssue(issue)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }, [id])

    return (
        <>
            <Helmet>
                <title>Issues | {APP_NAME}</title>
            </Helmet>
            <Grid item xs sm md lg style={{ maxWidth: '990px', marginLeft: 'auto', marginRight: 'auto' }}>
                <Breadcrumbs
                    separator="›"
                    aria-label="breadcrumb"
                    sx={{
                        fontSize: '12px',
                    }}
                >
                    <MLink
                        underline="hover"
                        key="1"
                        color="inherit"
                        sx={{
                            '&:hover': { color: 'inherit' },
                        }}
                        onClick={() => {
                            navigate(URLS.projects.index.route)
                        }}
                    >
                        APIs
                    </MLink>
                    <MLink
                        underline="hover"
                        key="2"
                        color="inherit"
                        sx={{
                            '&:hover': { color: 'inherit' },
                        }}
                        onClick={() => {
                            navigate(
                                URLS.projects.lastRun.buildPath({
                                    id: issue?.api_id,
                                })
                            )
                        }}
                    >
                        {issue?.api_name}
                    </MLink>
                    {issue && (
                        <Typography key="3" color="text.primary" fontSize="12px">
                            <BugReportOutlinedIcon />{' '}
                            <span>
                                <strong>{issue.title}</strong>
                            </span>
                        </Typography>
                    )}
                </Breadcrumbs>
                <Divider sx={{ my: 2 }} />
                {loading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}
                {!loading && issue && (
                    <div className="content-rows">
                        <Card className="card">
                            <CardHeader
                                title={<Typography variant="h6">{issue.title}</Typography>}
                                subheader={<code>{issue.message}</code>}
                                avatar={<ErrorOutlineOutlinedIcon color="error" fontSize="large" />}
                            />
                            {issue.context && issue.type !== 'server_error' && (
                                <>
                                    <Divider sx={{ mb: 2 }} />
                                    {issue.type === 'json_schema' && (
                                        <>
                                            <JSONSchema context={issue.context} />
                                            <Alert variant="outlined" severity="error">
                                                {issue.context.validation_message}
                                            </Alert>
                                        </>
                                    )}
                                    {issue.type === 'json_decode' && (
                                        <>
                                            <JSONDecode context={issue.context} />
                                            <Alert variant="outlined" severity="error">
                                                {issue.context.validation_message}
                                            </Alert>
                                        </>
                                    )}
                                    {issue.type === 'missing_content_type' && (
                                        <MissingContentType context={issue.context} />
                                    )}
                                    {issue.type === 'undefined_content_type' && (
                                        <UndefinedContentType context={issue.context} />
                                    )}
                                    {issue.type === 'undefined_status_code' && (
                                        <UndefinedStatusCode context={issue.context} />
                                    )}
                                    {issue.type === 'missing_headers' && <MissingHeaders context={issue.context} />}
                                    {issue.type === 'malformed_media_type' && (
                                        <MalformedMediaType context={issue.context} />
                                    )}
                                </>
                            )}
                        </Card>
                        <Card className="card">
                            <CardHeader
                                title={
                                    <Typography variant="h5">
                                        <strong>Request</strong>
                                    </Typography>
                                }
                            />
                            <Divider sx={{ mb: 2 }} />
                            <Stack spacing={1}>
                                <Code code={issue.request.code_samples.curl} highlight="curl" />
                                <Box>
                                    {issue.context.history.length > 0 && (
                                        <>
                                            {issue.context.history.map((entry: HistoryEntry, idx: number) => {
                                                const chunks = entry.case.verbose_name.split(/(\s+)/)
                                                return (
                                                    <RequestResult
                                                        key={idx}
                                                        method={chunks[0]}
                                                        path={chunks[2]}
                                                        status={entry.response.status_code}
                                                    />
                                                )
                                            })}
                                        </>
                                    )}
                                    <RequestResult
                                        method={issue.request.data.method}
                                        path={issue.request.data.path}
                                        status={issue.response?.status}
                                    />
                                </Box>
                                {issue.request.data.payload && (
                                    <>
                                        <Typography variant="h6">
                                            <strong>Payload</strong>
                                        </Typography>
                                        <Code code={issue.request.data.payload.raw} highlight="json" />
                                    </>
                                )}
                                <DataTable title="Path Parameters" items={issue.request.data.path_parameters} />
                                <DataTable title="Query String" items={issue.request.data.query} />
                                <DataTable title="Headers" items={issue.request.data.headers} />
                                <DataTable title="Cookies" items={issue.request.data.cookies} />
                            </Stack>
                        </Card>
                        <Card className="card">
                            {issue.response ? (
                                <>
                                    <CardHeader
                                        title={
                                            <Typography variant="h5">
                                                <strong>[{issue.response.status}] Response</strong>
                                            </Typography>
                                        }
                                    />
                                    <Divider sx={{ mb: 2 }} />
                                    <Stack spacing={1}>
                                        {issue.response.payload && issue.response.payload.raw.length > 0 && (
                                            <>
                                                <Typography variant="h6">
                                                    <strong>Payload</strong>
                                                </Typography>
                                                <Code
                                                    code={
                                                        issue.response.payload.formatted || issue.response.payload.raw
                                                    }
                                                    highlight={responsePayloadHighlight}
                                                />
                                            </>
                                        )}
                                        <DataTable title="Headers" items={issue.response.headers} />
                                    </Stack>
                                </>
                            ) : (
                                <CardHeader
                                    title={
                                        <Typography variant="h5">
                                            <strong>No Response</strong>
                                        </Typography>
                                    }
                                />
                            )}
                        </Card>
                    </div>
                )}
            </Grid>
        </>
    )
}

export default IssueDetail
