import DefaultCheckoutDetails from '../../../components/CheckOutDetails'
import { apiClient, Organization } from '../../../api'
import React from 'react'

const CheckoutDetails: React.FC<{ organization: Organization }> = ({ organization }) => {
    return (
        <DefaultCheckoutDetails
            queryCheckoutSession={(checkoutSessionId) => {
                return apiClient.organizations.organizationsBillingCheckoutDetails({
                    organization: organization.slug,
                    checkoutSessionId,
                })
            }}
        />
    )
}

export default CheckoutDetails
