import { useApiDetail } from 'queries/useApiDetail'
import React, { useContext } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { URLS } from 'urls'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined'
import SidebarLayout from '../../../../layout/components/SidebarLayout'
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined'
import { MenuEntry } from 'layout/models/SidebarLayout'
import SchemaIcon from '../SchemaIcon'
import { Breadcrumb } from 'layout/models/Breadcrumbs'
import { ProjectContext } from 'features/contexts/projects'

const ProjectSettings: React.FC<{ id: string; children?: React.ReactNode }> = ({ id, children }) => {
    const project = useContext(ProjectContext)

    const MenuEntries: MenuEntry[] = [
        {
            title: 'General',
            url: URLS.projects.detail.buildPath({ id }),
            icon: <SettingsOutlinedIcon fontSize="small" />,
        },
        {
            title: 'Environments',
            url: URLS.projects.environments.buildPath({ id }),
            icon: <StorageOutlinedIcon fontSize="small" />,
        },
        {
            title: 'Getting Started',
            url: URLS.projects.gettingStarted.buildPath({ id }),
            icon: <RocketLaunchOutlinedIcon fontSize="small" />,
        },
    ]

    const breadcrumbsHead: Breadcrumb[] = [
        {
            title: project?.data?.name || '',
            url: URLS.projects.runs.buildPath({
                id,
            }),
            icon: (
                <SchemaIcon
                    spec_type={project?.data?.specification.type}
                    spec_version={project?.data?.specification.version}
                />
            ),
        },
        {
            title: 'Settings',
            url: URLS.projects.detail.buildPath({ id }),
        },
    ]

    return <SidebarLayout breadcrumbsHead={breadcrumbsHead} menu={MenuEntries} children={children} />
}

const ProjectSettingsRouter: React.FC = () => {
    const { id } = useParams<{ id: string }>()

    return (
        <ProjectContext.Provider value={useApiDetail(id!)}>
            <ProjectSettings id={id!}>
                <Outlet />
            </ProjectSettings>
        </ProjectContext.Provider>
    )
}

export default ProjectSettingsRouter
