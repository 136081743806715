/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class BannersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Archive banner.
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public bannersArchive({
        bannerId,
    }: {
        /**
         * Unique banner id.
         */
        bannerId: number
    }): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/banners/{banner_id}/archive/',
            path: {
                banner_id: bannerId,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
}
