import { Alert, IconButton } from '@mui/material'
import { SetState } from 'models/Utils'
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'

export const Tip: React.FC<{
    open: boolean
    setOpen: SetState<boolean>
    text: React.ReactNode
}> = ({ open, setOpen, text }) => {
    return open ? (
        <Alert
            icon={<TipsAndUpdatesOutlinedIcon />}
            color="info"
            action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setOpen(false)
                    }}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }
            sx={{ mb: 2, alignItems: 'center' }}
        >
            {text}
        </Alert>
    ) : (
        <></>
    )
}
