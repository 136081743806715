import { useQuery } from 'react-query'
import { apiClient, ApiError, Organization } from '../../../api'
import { BillingInfo, CancelablePromise, Product, SeatsFeature } from '../../../api/client'
import { AccountBlock, CurrentSubscription, RecurringProductsList, STRIPE_INFO_BLOCK } from '../Billing/Products'
import { AccountType } from 'models/Account'
import React from 'react'
import { OrganizationAvatar } from 'features/components/organizations'

export const OrganizationAccountBlock: React.FC<{ organization: Organization }> = ({ organization }) => {
    const icon = <OrganizationAvatar alt={organization.slug} avatar_url={organization.avatar_url} size="big" />

    const account = {
        icon: icon,
        name: organization.name,
        type: AccountType.ORGANIZATION,
        subtitle: 'Organization account',
    }
    return <AccountBlock account={account} />
}

const Billing: React.FC<{ organization: Organization; members: number }> = ({ organization, members }) => {
    const { data, error, isLoading } = useQuery<BillingInfo, ApiError>(
        ['organizationProductList'],
        async () => apiClient.organizations.organizationsBillingProductsList({ organization: organization.slug }),
        { retry: false }
    )

    const createCheckout = (
        price_id: string
    ): CancelablePromise<{
        checkout_url: string | null
    }> => {
        return apiClient.organizations.organizationsBillingCheckoutCreate({
            organization: organization.slug,
            requestBody: { price_id },
        })
    }

    const checkIfAvailable = (product: Product): string | null => {
        const feature = product.features.find((feature) => feature.type === 'seats')

        if (feature) {
            const seats = feature as SeatsFeature
            if (seats.amount !== null && members > seats.amount) {
                const membersWord = members > 1 ? 'members' : 'member'
                return `Your current team size of ${members} ${membersWord} exceeds the seat limit for this plan.`
            }
        }

        return null
    }

    return (
        <div className="content-rows">
            <OrganizationAccountBlock organization={organization} />
            {STRIPE_INFO_BLOCK}
            {data && data.current_subscription !== null && (
                <CurrentSubscription subscription={data.current_subscription} />
            )}
            <RecurringProductsList
                error={error}
                isLoading={isLoading}
                products={data?.products}
                createCheckout={createCheckout}
                checkIfAvailable={checkIfAvailable}
            />
        </div>
    )
}

export default Billing
