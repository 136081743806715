/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganizationInvitation } from '../models/OrganizationInvitation'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class InvitationsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Resolve an invitation.
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public invitationsResolve({
        organization,
        requestBody,
    }: {
        organization: string
        requestBody: {
            invitation_id: string
            action: 'accept' | 'decline'
        }
    }): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/organizations/{organization}/invitations/resolve/',
            path: {
                organization: organization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * Get invitation info.
     * @returns OrganizationInvitation Invitation details
     * @throws ApiError
     */
    public invitationsDetails({
        organization,
        invitationId,
    }: {
        organization: string
        /**
         * Unique invitation ID.
         */
        invitationId: string
    }): CancelablePromise<OrganizationInvitation> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/organizations/{organization}/invitations/{invitation_id}/',
            path: {
                organization: organization,
                invitation_id: invitationId,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
}
