import React from 'react'

import { Chip, Stack, Typography } from '@mui/material'
import { MalformedMediaTypeError } from '../../../../../api'

function MalformedMediaType({ context }: { context: MalformedMediaTypeError }) {
    return (
        <Stack spacing={1}>
            <Typography>
                Actual: <Chip size="small" label={context.actual} />
            </Typography>
            <Typography style={{ marginBottom: 0 }}>
                Defined: <Chip size="small" label={context.defined} />
            </Typography>
        </Stack>
    )
}

export default MalformedMediaType
