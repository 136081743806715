const KEBIBYTE = 1024
const MEBIBYTE = 1024 * KEBIBYTE

export function humanizeSize(size: number): [number, string] {
    if (size < KEBIBYTE) {
        return [size, 'B']
    } else if (size < MEBIBYTE) {
        return [Math.floor(size / KEBIBYTE), 'KiB']
    } else {
        return [Math.floor(size / MEBIBYTE), 'MiB']
    }
}
