import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import React from 'react'

const Banner: React.FC<{
    id: number
    title: string
    summary: string
    onClose?: (id: number) => void
}> = ({ id, onClose, summary, title }) => {
    return (
        <Alert
            className="space-b-m"
            severity="info"
            variant="outlined"
            onClose={
                onClose
                    ? () => {
                          onClose(id)
                      }
                    : undefined
            }
        >
            <AlertTitle sx={{ fontWeight: 'bold' }}>{title}</AlertTitle>
            <div
                dangerouslySetInnerHTML={{
                    __html: summary,
                }}
            />
        </Alert>
    )
}

export default Banner
