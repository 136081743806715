import { apiClient, ApiError } from 'api'
import { useQuery } from 'react-query'
import {FeatureUsage} from "../api/client";

export function useOrganizationFeatureUsageDetails(organization: string, feature: string) {
    return useQuery<FeatureUsage | null, ApiError>({
        queryKey: ['organizationsUsageDetails', organization, feature],
        queryFn: async () =>
            apiClient.organizations.organizationsUsageDetails({ organization, feature }),
    })
}
