import { Link, useNavigate } from 'react-router-dom'
import { URLS } from 'urls'
import { Alert, Button } from '@mui/material'

import '../../../static/styles/components/section-empty.less'
import { trackOnboardingEvent } from './events'
import { useMutation } from 'react-query'
import { NewAPIRequest, apiClient } from 'api'
import { EXAMPLE_PROJECT_SPECIFICATION_URL } from 'config'
import { buildRemoteUpload } from 'utils/functions/forms'
import React, { useState } from 'react'
import { ApiError, ProjectDraft, ProjectDraftInput } from 'api/client'
import { LoadingStatus } from 'utils/components/loaders'

const Onboarding: React.FC = () => {
    const navigate = useNavigate()

    const [error, setError] = useState<string>()

    const importDraftMutation = useMutation(
        async (formData: ProjectDraftInput) => {
            return apiClient.projects.projectsDraftsCreate({ formData })
        },
        {
            onSuccess: (draft: ProjectDraft) => {
                trackOnboardingEvent('specification_import_success')
                createProject(draft)
            },
            onError: (error: ApiError) => {
                trackOnboardingEvent('specification_import_failure')
                if (error.body) setError(error.body.detail)
                else setError('Failed to create example project')
            },
        }
    )

    const createAPIMutation = useMutation(
        async (requestBody: NewAPIRequest) => await apiClient.apis.apisCreate({ requestBody }),
        {
            onSuccess: ({ project }) => {
                trackOnboardingEvent('success')

                apiClient.apis
                    .apisTest({ apiId: project.id })
                    .then(({ run_id }) => {
                        navigate(
                            URLS.projects.run.buildPath({
                                id: project.id,
                                runId: run_id,
                            })
                        )
                    })
                    .catch(() => {
                        navigate(
                            URLS.projects.lastRun.buildPath({
                                id: project.id,
                            })
                        )
                    })
            },
            onError: (error: ApiError) => {
                trackOnboardingEvent('error')
                if (error.body) setError(error.body.detail)
                else setError('Failed to create example project')
            },
        }
    )

    const setupProject = () => {
        importDraftMutation.mutate(buildRemoteUpload(EXAMPLE_PROJECT_SPECIFICATION_URL))
    }

    function createProject(form: ProjectDraft) {
        const payload = {
            organization: null,
            name: form.name || 'Example API',
            draft_id: form.id,
            environments: form.environments,
            auth: null,
        }

        createAPIMutation.mutate(payload)
    }

    return (
        <div className="section-empty">
            <h1>Welcome to Schemathesis.io!</h1>
            <p>🚀 Begin your journey towards resilient APIs</p>

            {(importDraftMutation.isLoading || createAPIMutation.isLoading) && (
                <LoadingStatus title={importDraftMutation.isLoading ? 'Preparing API data ...' : 'Creating API ...'} />
            )}

            {error && (
                <Alert className="alert highligh" severity="error">
                    {error}
                </Alert>
            )}

            <div className="action-list">
                <div className="item">
                    <div className="content-rows-s">
                        <h3>Explore Demo Project</h3>
                        <p>See tests run on our demo project to discover the issues Schemathesis can find</p>
                    </div>
                    <Button
                        variant="contained"
                        disabled={importDraftMutation.isLoading || createAPIMutation.isLoading}
                        onClick={() => {
                            trackOnboardingEvent('click', 'create_example')
                            setupProject()
                        }}
                    >
                        Start
                    </Button>
                </div>
                <div className="item">
                    <div className="content-rows-s">
                        <h3>Setup your project</h3>
                        <p>
                            Configure your project and explore how to enhance your API's reliability with Schemathesis
                        </p>
                    </div>
                    <Link
                        key="onboarding-own-project"
                        to={URLS.projects.create.route}
                        onClick={() => {
                            trackOnboardingEvent('click', 'create_own')
                        }}
                    >
                        <Button variant="contained">Proceed</Button>
                    </Link>
                </div>
            </div>
            <p className="center">
                Easily test your API and integrate with CI pipelines like GitHub Actions once set up.
                <br />
                Need guidance?
                Check our{' '}
                <a
                    href="https://docs.schemathesis.io/quick-start"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                        trackOnboardingEvent('click', 'visit_quick_start_guide')
                    }}
                >
                    Quick Start Guide
                </a>
            </p>
        </div>
    )
}

export default Onboarding
