import { LoadingButton } from '@mui/lab'
import { Box, Card, Checkbox } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useMutation, useQuery } from 'react-query'
import { apiClient, ApiError } from '../../../api'
import { EmailPreferences } from '../../../api/client'
import React from 'react'

const Notifications: React.FC = () => {
    return <EmailPreferencesUpdateForm />
}

type EmailPreferencesFormState = {
    ProductUpdate: boolean
}

const EmailPreferencesUpdateForm: React.FC = () => {
    useQuery('emailPreferences', () =>
        apiClient.users
            .usersEmailPreferencesDetail()
            .then((preferences) => {
                setValue('ProductUpdate', preferences.ProductUpdate)
            })
            .catch((error: ApiError) => {
                enqueueSnackbar(error.body.detail || 'Unable to get email preferences', {
                    variant: 'error',
                })
                throw error
            })
    )

    const {
        control,
        handleSubmit,
        setValue,
        formState: { isDirty },
    } = useForm<EmailPreferencesFormState>()

    const { enqueueSnackbar } = useSnackbar()

    const updateEmailPreferencesMutation = useMutation(
        (data: EmailPreferences) => apiClient.users.usersEmailPreferencesUpdate({ requestBody: data }),
        {
            onSuccess: ({ message }) => {
                enqueueSnackbar(message, { variant: 'success' })
            },
        }
    )

    const onSubmit = (data: EmailPreferencesFormState) => {
        updateEmailPreferencesMutation.mutate(data)
    }

    return (
        <div className="content-rows">
            <Card className="card">
                <Box
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete="off"
                    display="flex"
                    flexDirection="column"
                    width="50%"
                >
                    <FormControlLabel
                        control={
                            <Controller
                                name="ProductUpdate"
                                control={control}
                                defaultValue={false}
                                render={({ field: { value, ref, ...field } }) => (
                                    <Checkbox
                                        {...field}
                                        inputRef={ref}
                                        checked={!!value}
                                        color="primary"
                                        size="medium"
                                        disableRipple
                                    />
                                )}
                            />
                        }
                        sx={{ mb: 0, height: '24px' }}
                        label="Product updates"
                    />
                    <Box sx={{ ml: 4, mb: 2 }}>
                        <span style={{ fontSize: '12px', color: '#777' }}>
                            New features, enhancements, and other updates
                        </span>
                    </Box>
                    <Box>
                        <LoadingButton
                            loading={updateEmailPreferencesMutation.isLoading}
                            type="submit"
                            variant="contained"
                            disabled={!isDirty}
                        >
                            Update preferences
                        </LoadingButton>
                    </Box>
                </Box>
            </Card>
        </div>
    )
}

export default Notifications
