import React from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { Container } from '@mui/material'
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined'
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined'
import { URLS } from 'urls'

import APIsRouter from 'containers/Apps/APIs'
import UserSettingsRouter from '../../Apps/UserSettings'
import ProjectSettingsRouter from 'containers/Apps/APIs/ProjectSettings'

import ShortUrl from '../../../components/ShortUrl'
import NotificationSnippet from 'features/components/notificationsSnippet'
import MenuLayout from 'layout/components/menuLayout'
import { IconDiscord } from 'utils/icons/products'
import { MenuEntry } from 'layout/models/menuLayout'

import WaitForReport from '../../Apps/Reports/WaitForReport'
import GitHubAppInstalled from '../../Apps/Onboarding/GitHubAppInstalled'
import Onboarding from '../../Apps/Onboarding'
import { useUsers } from '../../../contexts/UsersContext'
import IssueDetail from '../../Apps/Issues/IssueDetail'
import Organizations from '../../Apps/Organizations/Organizations'
import CreateOrganization from '../../Apps/Organizations/CreateOrganization'
import { Setup as GitHubAppSetup } from '../../Apps/GitHubApp/Setup'
import Invite from '../../Apps/Organizations/Invite'
import OrganizationSettingsRouter from 'containers/Apps/Organizations'
import { UserAvatar } from 'features/components/users'
import NotFound from 'containers/Apps/Errors/NotFound'
import APIsList from 'containers/Apps/APIs/APIsList'
import CreateProject from 'containers/Apps/Projects/CreateProject'
import ApiRunList from 'containers/Apps/Runs/ApiRunList'
import { ApiRun } from 'containers/Apps/APIs/ApiRun'
import { ApiLastRun } from 'containers/Apps/APIs/ApiLastRun'
import IssuesList from 'containers/Apps/Issues/IssuesList'
import { GettingStarted } from 'containers/Apps/Projects/GettingStarted'
import { ApiEnvironments } from 'containers/Apps/APIs/ApiEnvironments'
import APIWidgetDetail from 'containers/Apps/APIs/ProjectSettings/APIWidgetDetail'
import { noParent } from 'utils/urls'

const ContainerLayoutRouter: React.FC = () => {
    return (
        <Container
            maxWidth={false}
            disableGutters
            sx={{
                background: '#f0f2f5',
                minHeight: 'calc(100vh)',
                p: 2,
            }}
        >
            <Outlet />
        </Container>
    )
}

const MenuLayoutRouter: React.FC = () => {
    const { user, hasAccess } = useUsers()

    const menuEntriesBottom: MenuEntry[] = [
        {
            title: 'Notifications',
            component: <NotificationSnippet />,
        },
        {
            title: '',
            tooltip: 'Documentation',
            url: 'https://docs.schemathesis.io/',
            icon: <MenuBookOutlinedIcon />,
        },
        {
            title: '',
            tooltip: 'Discord',
            url: 'https://discord.com/invite/R9ASRAmHnA',
            icon: <IconDiscord />,
        },
    ]

    if (hasAccess('profile:admin'))
        menuEntriesBottom.unshift({
            title: 'Upgrade',
            tooltip: 'Upgrade to Pro',
            url: URLS.settings.billing.details.route,
            icon: <WorkspacePremiumOutlinedIcon />,
        })

    if (hasAccess('profile:read'))
        menuEntriesBottom.push({
            title: '',
            tooltip: 'Profile',
            url: URLS.settings.account.route,
            icon: <UserAvatar alt={user?.username || 'user avatar'} avatar_url={user?.avatar_url} size="medium" />,
        })

    const menuEntries: MenuEntry[] = []

    if (hasAccess('organization:read'))
        menuEntries.unshift({
            title: 'Organizations',
            url: URLS.organizations.index.route,
            icon: <BusinessOutlinedIcon />,
        })

    if (hasAccess('project:read'))
        menuEntries.unshift({
            title: 'Projects',
            url: URLS.projects.index.route,
            icon: <InsertChartOutlinedIcon />,
        })

    return (
        <MenuLayout menuTop={menuEntries} menuBottom={menuEntriesBottom} mainLink={URLS.projects.index.route}>
            <Outlet />
        </MenuLayout>
    )
}

const InternalLayout: React.FC = () => {
    const { hasAccess } = useUsers()

    return (
        <SnackbarProvider maxSnack={2} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <Routes>
                <Route path={URLS.onboarding.index.route} element={<Onboarding />} />
                <Route path={'/'} element={<MenuLayoutRouter />}>
                    <Route path={URLS.organizations.invite.route} element={<Invite />} />
                    {hasAccess('profile:read') && <Route path="/settings/*" element={<UserSettingsRouter />} />}
                    {hasAccess('project:admin') && (
                        <Route path="/projects/:id/settings/*" element={<ProjectSettingsRouter />}>
                            <Route
                                path={noParent(URLS.projects.detail.route, '/projects/:id/settings/')}
                                element={<APIWidgetDetail />}
                            />
                            <Route
                                path={noParent(URLS.projects.environments.route, '/projects/:id/settings/')}
                                element={<ApiEnvironments />}
                            />
                            <Route
                                path={noParent(URLS.projects.gettingStarted.route, '/projects/:id/settings/')}
                                element={<GettingStarted />}
                            />
                        </Route>
                    )}
                    {hasAccess('organization:write') && (
                        <Route path="/organizations/create/" element={<CreateOrganization />} />
                    )}
                    {hasAccess('organization:read') && (
                        <Route path="/organizations/:slug/*" element={<OrganizationSettingsRouter />} />
                    )}
                    {hasAccess('organization:read') && <Route path="/organizations/" element={<Organizations />} />}
                    <Route path={'/'} element={<ContainerLayoutRouter />}>
                        {hasAccess('project:read') && <Route path={URLS.projects.index.route} element={<APIsList />} />}
                        {hasAccess('project:write') && (
                            <Route path={URLS.projects.create.route} element={<CreateProject />} />
                        )}
                        {hasAccess('project:read') && (
                            <Route path={'/projects/:id'} element={<APIsRouter />}>
                                <Route
                                    path={noParent(URLS.projects.runs.route, '/projects/:id/')}
                                    element={<ApiRunList />}
                                />
                                <Route
                                    path={noParent(URLS.projects.run.route, '/projects/:id/')}
                                    element={<ApiRun />}
                                />
                                <Route
                                    path={noParent(URLS.projects.lastRun.route, '/projects/:id/')}
                                    element={<ApiLastRun />}
                                />
                                <Route
                                    path={noParent(URLS.projects.issues.route, '/projects/:id/')}
                                    element={<IssuesList />}
                                />
                                <Route
                                    path={noParent(URLS.projects.gettingStartedNew.route, '/projects/:id/')}
                                    element={<GettingStarted />}
                                />
                            </Route>
                        )}
                        {hasAccess('project:read') && (
                            <Route path={URLS.issues.detail.route} element={<IssueDetail />} />
                        )}
                        {hasAccess('project:read') && (
                            <Route path={URLS.reports.wait.route} element={<WaitForReport />} />
                        )}
                        <Route path={URLS.short_url.route} element={<ShortUrl />} />
                        <Route path={URLS.github.appSetup.route} element={<GitHubAppSetup />} />
                        <Route path={URLS.onboarding.gitHubAppInstalled.route} element={<GitHubAppInstalled />} />
                        <Route path={URLS.onboarding.index.route} element={<Onboarding />} />
                        {hasAccess('project:read') && (
                            <Route path="/" element={<Navigate to={URLS.projects.index.route} replace />} />
                        )}
                        <Route path="/auth/*" element={<Navigate to={URLS.projects.index.route} replace />} />
                        <Route path="/*" element={<NotFound />} />
                    </Route>
                </Route>
            </Routes>
        </SnackbarProvider>
    )
}

export default InternalLayout
