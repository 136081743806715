import {
    Autocomplete,
    CircularProgress,
    IconButton,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import React, { useEffect, useImperativeHandle, useRef } from 'react'
import DataRow from 'components/DataRow'
import { Controller, useFieldArray, useWatch, useFormContext } from 'react-hook-form'
import { URL_REGEX } from 'utils/constants'
import {
    addEmptyEnv,
    deleteDuplicateEmptyEnv,
    deselectOtherDefaults,
    isEmptyEnv,
    selectNewDefaultEnv,
} from 'features/functions/environments'
import { EnvironmentsFormType } from 'features/modelts/environments'
import { EnvironmentNameOptions } from 'features/constants/environments'

export type EnvFormRef = { beforeSubmit: () => void; afterSubmit: () => void }

const ApiEnvironmentsForm: React.ForwardRefRenderFunction<
    EnvFormRef,
    {
        isLoading: boolean
    }
> = ({ isLoading }, forwardedRef) => {
    const editingRef = useRef<boolean>(true)
    useImperativeHandle(forwardedRef, () => ({
        beforeSubmit() {
            editingRef.current = false
            deleteDuplicateEmptyEnv(environments, editingRef.current, remove)
        },
        afterSubmit() {
            editingRef.current = true
            addEmptyEnv(environments, editingRef.current, append)
        },
    }))

    const {
        control,
        setValue,
        getValues,
        formState: { errors },
    } = useFormContext<EnvironmentsFormType>()

    const { fields, append, remove } = useFieldArray({
        name: 'environments',
    })

    const environments = useWatch({ control, name: 'environments' })

    useEffect(() => {
        selectNewDefaultEnv(environments, setValue)
        addEmptyEnv(environments, editingRef.current, append)
        deleteDuplicateEmptyEnv(environments, editingRef.current, remove)
    }, [append, environments, remove, setValue])

    return (
        <>
            <Table className="table" size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }} width="100px">
                            Is default
                        </TableCell>
                        <TableCell style={{ fontWeight: 'bold' }} width="180px">
                            Name
                        </TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Url</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }} width="60px" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading ? (
                        <DataRow>
                            <TableCell component="th" scope="row" colSpan={4} sx={{ textAlign: 'center' }}>
                                <CircularProgress />
                            </TableCell>
                        </DataRow>
                    ) : (
                        <>
                            {fields.map((field, index) => {
                                return (
                                    <TableRow
                                        key={field.id}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                        }}
                                    >
                                        <TableCell scope="row">
                                            <Controller
                                                name={`environments.${index}.is_default`}
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <Radio
                                                            {...field}
                                                            checked={field.value}
                                                            disabled={
                                                                environments.length > index &&
                                                                isEmptyEnv(environments[index])
                                                            }
                                                            onChange={(_, v) => {
                                                                setValue(`environments.${index}.is_default`, v)
                                                                deselectOtherDefaults(index, getValues, setValue)
                                                            }}
                                                        />
                                                    )
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell scope="row">
                                            <Controller
                                                name={`environments.${index}.name`}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: 'Name is required',
                                                    },
                                                    validate: (v) => {
                                                        const values = getValues()
                                                        const count = values.environments.reduce(
                                                            (c, e) => c + (v === e.name ? 1 : 0),
                                                            0,
                                                        )
                                                        return count > 1 ? 'Name must be unique' : undefined
                                                    },
                                                }}
                                                render={({ field }) => {
                                                    const { onChange, value, ref } = field
                                                    return (
                                                        <>
                                                            <Autocomplete
                                                                value={
                                                                    value
                                                                        ? EnvironmentNameOptions.find((option) => {
                                                                              return value === option.label
                                                                          }) ?? value
                                                                        : value
                                                                }
                                                                freeSolo={true}
                                                                autoSelect
                                                                onChange={(e, newValue) => {
                                                                    onChange(
                                                                        typeof newValue === 'string'
                                                                            ? newValue
                                                                            : newValue?.id,
                                                                    )
                                                                }}
                                                                options={EnvironmentNameOptions}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        variant="standard"
                                                                        {...params}
                                                                        inputRef={ref}
                                                                        autoComplete="off"
                                                                        error={!!errors.environments?.[index]?.name}
                                                                        helperText={
                                                                            errors.environments?.[index]?.name
                                                                                ?.message || ''
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </>
                                                    )
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell scope="row">
                                            <Controller
                                                name={`environments.${index}.url`}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: 'Url is required',
                                                    },
                                                    pattern: {
                                                        value: URL_REGEX,
                                                        message: 'Invalid URL',
                                                    },
                                                    validate: (v) => {
                                                        const values = getValues()
                                                        const count = values.environments.reduce(
                                                            (c, e) => c + (v === e.url ? 1 : 0),
                                                            0,
                                                        )
                                                        return count > 1 ? 'Url must be unique' : undefined
                                                    },
                                                }}
                                                render={({ field, fieldState }) => (
                                                    <TextField
                                                        variant="standard"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        {...field}
                                                        error={!!fieldState.error}
                                                        helperText={fieldState.error?.message}
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            {!isEmptyEnv(getValues().environments[index]) && (
                                                <IconButton onClick={() => remove(index)}>
                                                    <DeleteOutlinedIcon />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </>
                    )}
                </TableBody>
            </Table>
        </>
    )
}

export default React.forwardRef(ApiEnvironmentsForm)
