import { MutableRefObject, useState, useCallback, useEffect } from 'react'

let draggingCount = 0

export function useDragging({
    labelRef,
    inputRef,
    onChange,
}: {
    labelRef: MutableRefObject<HTMLLabelElement | null>
    inputRef: MutableRefObject<HTMLInputElement | null>
    onChange: (arg0: File) => void
}): boolean {
    const [dragging, setDragging] = useState(false)
    const handleClick = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.click()
        }
    }, [inputRef])

    const handleDragIn = useCallback((ev: any) => {
        ev.preventDefault()
        ev.stopPropagation()
        draggingCount++
        if (ev.dataTransfer.items && ev.dataTransfer.items.length !== 0) {
            setDragging(true)
        }
    }, [])
    const handleDragOut = useCallback((ev: any) => {
        ev.preventDefault()
        ev.stopPropagation()
        draggingCount--
        if (draggingCount > 0) return
        setDragging(false)
    }, [])
    const handleDrag = useCallback((ev: any) => {
        ev.preventDefault()
        ev.stopPropagation()
    }, [])
    const handleDrop = useCallback(
        (ev: any) => {
            ev.preventDefault()
            ev.stopPropagation()
            setDragging(false)
            draggingCount = 0

            const eventFiles = ev.dataTransfer.files
            if (eventFiles && eventFiles.length > 0) {
                onChange(eventFiles[0])
            }
        },
        [onChange]
    )
    useEffect(() => {
        const element = labelRef.current
        if (element) {
            element.addEventListener('dragenter', handleDragIn)
            element.addEventListener('dragleave', handleDragOut)
            element.addEventListener('dragover', handleDrag)
            element.addEventListener('drop', handleDrop)
            return () => {
                element.removeEventListener('dragenter', handleDragIn)
                element.removeEventListener('dragleave', handleDragOut)
                element.removeEventListener('dragover', handleDrag)
                element.removeEventListener('drop', handleDrop)
            }
        }
    }, [handleClick, handleDragIn, handleDragOut, handleDrag, handleDrop, labelRef])

    return dragging
}
