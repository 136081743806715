import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import React from 'react'

const ExternalLinkIcon: React.FC = () => {
    return (
        <>
            <span>
                <OpenInNewIcon style={{ height: '14px', width: '14px' }} htmlColor="black" />
            </span>{' '}
        </>
    )
}

export default ExternalLinkIcon
