import { Card, Grid, CircularProgress, Link, Button, Box, Typography } from '@mui/material'
import { GitHubLogo } from './Logo'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import ErrorBlock from '../../../components/Error'
import { apiClient, ApiError } from '../../../api'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import { URLS } from '../../../urls'
import useQueryParams from '../../../hooks/useQueryParams'

const Block: React.FC<{children?: React.ReactNode;}> = ({ children }) => {
    return (
        <Grid container spacing={2} sx={{ mt: 0, background: '#f0f2f5', minHeight: 'calc(100vh - 64px)' }}>
            <Grid
                item
                xs
                sm
                md
                lg
                sx={{ pr: 2 }}
                style={{ maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto' }}
            >
                <div className="content-rows">
                    <Card className="card">{children}</Card>
                </div>
            </Grid>
        </Grid>
    )
}

const CenteredBox: React.FC<{children?: React.ReactNode;}> = ({ children }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 6,
                p: 2,
                color: 'primary',
                borderRadius: 1,
            }}
        >
            {children}
        </Box>
    )
}

const UNEXPECTED_CALLBACK = (
    <ErrorBlock
        title="Unexpected GitHub callback"
        message={
            <>
                Please, try to install the app again. If the error persists, contact us at&nbsp;
                <Link
                    href="mailto:support@schemathesis.io?subject=GitHub app installation error"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    sx={{ fontSize: '1rem' }}
                >
                    support@schemathesis.io
                </Link>
            </>
        }
        button={
            <Button
                variant="contained"
                color="primary"
                href="https://github.com/apps/schemathesis"
                sx={{ fontSize: '1rem' }}
            >
                Return to GitHub
            </Button>
        }
    />
)

const NETWORK_ERROR = (
    <ErrorBlock
        title="Server unreachable"
        icon={<CloudOffIcon color="error" style={{ fontSize: 100 }} />}
        message="Check your network and try again"
    />
)

const WAITING_BLOCK = (
    <CenteredBox>
        <GitHubLogo />
        <CircularProgress size={80} />
        <Typography variant="h6" align="center" sx={{ fontWeight: 600 }}>
            Connecting to GitHub ...
        </Typography>
    </CenteredBox>
)

export const Setup: React.FC = () => {
    const navigate = useNavigate()
    const { installation_id, setup_action } = useQueryParams()
    const [content, setContent] = useState<React.ReactNode>(WAITING_BLOCK)

    useEffect(() => {
        if (installation_id === undefined || setup_action === undefined) {
            setContent(UNEXPECTED_CALLBACK)
        } else {
            apiClient.gitHub
                .githubAppSetup({
                    requestBody: {
                        installation_id: parseInt(installation_id),
                        setup_action,
                    },
                })
                .then((response) => {
                    switch (response.type) {
                        case 'Success':
                            navigate(URLS.onboarding.gitHubAppInstalled.route)
                            break
                    }
                })
                .catch((error) => {
                    if (error.message === 'Network Error') {
                        setContent(NETWORK_ERROR)
                    } else {
                        const response = error as ApiError
                        if (response.status === 409) {
                            navigate(URLS.onboarding.gitHubAppInstalled.route)
                        } else {
                            setContent(
                                <ErrorBlock
                                    title={response.body.title}
                                    message={response.body.detail || response.body.message}
                                />
                            )
                        }
                    }
                })
        }
    }, [installation_id, setup_action, navigate])

    return <Block>{content}</Block>
}
