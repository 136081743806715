import React from 'react'
import { Helmet } from 'react-helmet'
import { APP_NAME } from 'config'
import logo from '../static/images/logo-dark.png'

const UnauthorizedPageLayout: React.FC<{
    content: React.ReactNode
    header?: React.ReactNode
    footer: React.ReactNode
    title: string
}> = ({ content, header, footer, title }) => {
    return (
        <div className="layout-unauthorized">
            <Helmet>
                <title>
                    {title} | {APP_NAME}
                </title>
            </Helmet>
            <div className="header">
                <img src={logo} alt="Schemathesis.io" />
            </div>

            <div className="body">
                {header}
                <h1>{title}</h1>
                {content}
            </div>
            {footer}
        </div>
    )
}

export default UnauthorizedPageLayout
