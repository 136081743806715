import { Alert, Button, Card, Skeleton } from '@mui/material'
import { useParams } from 'react-router-dom'
import CenteredPageLayout from 'components/CenteredPageLayout'
import React, { useEffect, useRef, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import ApiEnvironmentsForm from 'features/components/environments'
import { EnvironmentsFormType } from 'features/modelts/environments'
import { useMutation, useQuery } from 'react-query'
import { APIErrorResponse, ApiError, ProjectEnvironment } from 'api/client'
import { apiClient } from 'api'
import { trackCreateProjectEnvironmentsEvent } from '../Projects/events'
import { useSnackbar } from 'notistack'

type FormType = EnvironmentsFormType

const defaultData = {
    environments: [],
}

const mapDefaults = (defaults: FormType) => {
    return {
        environments: defaults.environments,
    }
}

type EnvFormHandle = React.ElementRef<typeof ApiEnvironmentsForm>

export const ApiEnvironments: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const envFormRef = useRef<EnvFormHandle>(null)
    const [apiError, setApiError] = useState<string | undefined>(undefined)
    const { enqueueSnackbar } = useSnackbar()

    const methods = useForm<FormType>({
        defaultValues: defaultData,
    })

    const { handleSubmit, setValue, clearErrors } = methods

    const { data: envs, isLoading } = useQuery<ProjectEnvironment[]>(['ApiEnvs', id], () =>
        apiClient.apis.apisEnvironmentsList({ apiId: id! })
    )

    useEffect(() => {
        if (!envs) return

        const data = mapDefaults({ environments: envs })
        for (const [key, value] of Object.entries(data)) {
            setValue(key as keyof FormType, value)
        }
    }, [envs, setValue])

    const apiMutation = useMutation(
        async (requestBody: ProjectEnvironment[]) =>
            await apiClient.apis.apisEnvironmentsUpdate({ apiId: id!, requestBody }),
        {
            onMutate: () => {
                clearErrors()
            },
            onSuccess: () => {
                trackCreateProjectEnvironmentsEvent('submit_form_patch_success')
                enqueueSnackbar('Environments successfully edited', { variant: 'success' })
            },
            onError: (response: ApiError) => {
                trackCreateProjectEnvironmentsEvent('submit_form_patch_error')
                clearErrors()
                const data: APIErrorResponse = response.body
                setApiError(data.detail)
            },
        }
    )

    function onSubmit(form: FormType) {
        trackCreateProjectEnvironmentsEvent('submit_form_patch', {
            environments: form.environments.length,
        })
        apiMutation.mutate(form.environments)
    }

    return (
        <CenteredPageLayout>
            {apiError ? <Alert severity="error">{String(apiError)}</Alert> : null}
            {isLoading ? (
                <Skeleton variant="rectangular" height="200px" />
            ) : (
                <div className="content-cards">
                    <Card className="card">
                        <FormProvider {...methods}>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    envFormRef.current?.beforeSubmit()
                                    handleSubmit(onSubmit)(e)
                                    envFormRef.current?.afterSubmit()
                                }}
                            >
                                <ApiEnvironmentsForm ref={envFormRef} isLoading={false} />

                                <div className="section-actions">
                                    <Button type="submit" variant="contained">
                                        Save environments
                                    </Button>
                                </div>
                            </form>
                        </FormProvider>
                    </Card>
                </div>
            )}
        </CenteredPageLayout>
    )
}
