export const APP_NAME = 'Schemathesis'

export const GITHUB_AUTHORIZATIONS_URL = 'https://github.com/settings/connections/applications/Iv1.63178291110570c2'
export const EXAMPLE_PROJECT_SPECIFICATION_URL = 'https://example.schemathesis.io/openapi.json'

// ORDER
export const ORDER_REFETCH_INTERVAL = 2 * 1000
export const ORDER_WAIT_DURATION = 15 * 1000

export const ENV = process.env.REACT_APP_ENVIRONMENT || 'development'
