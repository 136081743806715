import { Box, Chip, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { MenuEntry } from 'layout/models/SidebarLayout'
import { CustomNavLink } from 'utils/components/navigation'
import '../styles/SidebarLayout.less'
import { Bread } from './Breadcrumbs'
import { Breadcrumb } from 'layout/models/Breadcrumbs'

export const ListMenuItem: React.FC<{
    url?: string
    noHighlight?: boolean
    icon?: React.ReactNode
    buttonSx?: object
    iconSx?: object
    action?: () => void
    children?: React.ReactNode
}> = ({ buttonSx, children, icon, iconSx, noHighlight, url, action }) => {
    return (
        <ListItem disablePadding className="layout-sidebar-item">
            <ListItemButton
                dense
                disableRipple
                component={CustomNavLink}
                to={url || ''}
                sx={buttonSx}
                activeClassName={(isActive) => (isActive && !noHighlight && url ? 'active' : '')}
                onClick={() => action && action()}
            >
                {icon && (
                    <ListItemIcon
                        sx={{
                            minWidth: {
                                xs: '20px',
                                sm: '26px',
                            },
                            ...iconSx,
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                )}
                <ListItemText primary={children} className="text" />
            </ListItemButton>
        </ListItem>
    )
}

const MAX_CHIP = 99

function generateBreadcrumbs(menu: MenuEntry[], currentPath: string): Breadcrumb[] {
    const breadcrumbs: Breadcrumb[] = []

    function traverseMenu(entries: MenuEntry[], path: string) {
        for (const entry of entries) {
            if (entry.url === path || (entry.isPrefix && entry.url && path.startsWith(entry.url))) {
                breadcrumbs.push({ title: entry.title, url: entry.url })
                return
            }

            if (entry.children) {
                traverseMenu(entry.children, path)
                if (breadcrumbs.length > 0) {
                    breadcrumbs.unshift({ title: entry.title, url: entry.url })
                    return
                }
            }
        }
    }

    traverseMenu(menu, currentPath)

    return breadcrumbs
}

const SidebarLayout: React.FC<{
    breadcrumbsHead: Breadcrumb[]
    menu: MenuEntry[]
    children?: React.ReactNode
}> = ({ breadcrumbsHead, menu, children }) => {
    const location = useLocation()
    const breadcrumbsTail = generateBreadcrumbs(menu, location.pathname)

    return (
        <div className="layout-sidebar-wrapper">
            <div className="layout-sidebar-header">
                <Bread breadcrumbs={breadcrumbsHead.concat(breadcrumbsTail)} />
            </div>
            <Grid className="layout-sidebar" container>
                <Grid className="layout-sidebar-sections" item>
                    <List
                        dense
                        sx={{
                            '.active span': { fontWeight: 900 },
                        }}
                    >
                        {menu.map((entry) => (
                            <React.Fragment key={entry.url || entry.title}>
                                <>
                                    {entry.sep && <div className="sep" />}
                                    <ListMenuItem
                                        url={entry.url}
                                        icon={entry.icon}
                                        action={entry.action}
                                        buttonSx={{ height: '38px' }}
                                        children={
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                {entry.title}
                                                {entry.chip !== undefined && entry.chip > 0 && (
                                                    <Chip
                                                        className="chip"
                                                        label={entry.chip > MAX_CHIP ? `${MAX_CHIP}+` : entry.chip}
                                                        size="small"
                                                    />
                                                )}
                                            </Box>
                                        }
                                    />
                                </>
                            </React.Fragment>
                        ))}
                    </List>
                </Grid>
                <Grid
                    item
                    xs
                    sm
                    md
                    lg
                    sx={{ px: 2, my: 2 }}
                    style={{ maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto' }}
                >
                    {children}
                </Grid>
            </Grid>
        </div>
    )
}

export default SidebarLayout
