import React from 'react'

import { Chip, Typography } from '@mui/material'
import { MissingContentTypeHeaderError } from '../../../../../api'

function MissingContentType({ context }: { context: MissingContentTypeHeaderError }) {
    return (
        <Typography style={{ marginBottom: 0 }}>
            Defined Content Types:{' '}
            {context.media_types.map((c: string) => (
                <Chip size="small" label={c} />
            ))}
        </Typography>
    )
}

export default MissingContentType
