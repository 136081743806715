import { Box, Button, Card, CardContent, CircularProgress, Container, FormHelperText, Typography } from '@mui/material'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { apiClient, ApiError, APIErrorResponse, OrganizationInvitation } from '../../../api'
import { useSnackbar } from 'notistack'
import { URLS } from '../../../urls'
import React, { useState } from 'react'

const Invite: React.FC = () => {
    const { id, slug } = useParams<{ id: string, slug: string }>()
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const [apiError, setApiError] = useState<string | undefined>()
    const {
        data: invitation,
        isLoading,
        error,
    } = useQuery<OrganizationInvitation, ApiError>({
        queryKey: ['organizationInviteData', slug],
        queryFn: async () => apiClient.invitations.invitationsDetails({ organization: slug!, invitationId: id! }),
        enabled: apiError === undefined,
        retry: false,
    })

    const handleResolve = (action: 'accept' | 'decline') => {
        apiClient.invitations
            .invitationsResolve({
                organization: slug!,
                requestBody: {
                    action,
                    invitation_id: id!,
                },
            })
            .then(({ message }) => {
                enqueueSnackbar(message, { variant: 'success' })
                navigate(URLS.projects.index.route)
            })
            .catch((error: ApiError) => {
                const data: APIErrorResponse = error.body
                setApiError(data.detail)
            })
    }

    return (
        <Container maxWidth={false} disableGutters sx={{ background: '#f0f2f5', minHeight: '100vh' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <Card>
                        <CardContent
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                '&:last-child': { pb: 2 },
                            }}
                        >
                            {error?.status === 404 ? (
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <WarningAmberOutlinedIcon sx={{ alignSelf: 'center' }} />
                                    <Typography variant="h6" sx={{ pt: 1 }}>
                                        Invitation not found
                                    </Typography>
                                    <Typography sx={{ fontSize: '12px', alignSelf: 'center', color: '#666', pt: 1 }}>
                                        We could not find your invitation
                                    </Typography>
                                </Box>
                            ) : error?.body.detail !== undefined || apiError !== undefined ? (
                                <FormHelperText sx={{ mr: 2, mt: 0 }} error={true}>
                                    <ErrorOutlineOutlinedIcon />
                                    &nbsp;
                                    {error?.body.detail || apiError}
                                </FormHelperText>
                            ) : (
                                <>
                                    <Typography variant="h6">
                                        You've been invited to the {invitation?.organization} organization!
                                    </Typography>
                                    <Typography sx={{ fontSize: '12px', alignSelf: 'center', color: '#666', pt: 2 }}>
                                        Invited by <strong>{invitation?.invited_by}</strong>
                                    </Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', pt: 3 }}>
                                        <Button
                                            onClick={() => handleResolve('accept')}
                                            autoFocus
                                            variant="contained"
                                            sx={{ mx: 1 }}
                                        >
                                            Join {invitation?.organization}
                                        </Button>
                                        <Button
                                            onClick={() => handleResolve('decline')}
                                            variant="contained"
                                            sx={{
                                                background: '#ddd',
                                                color: 'black',
                                                mx: 1,
                                                '&:hover': { background: '#ccc' },
                                            }}
                                        >
                                            Decline
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </CardContent>
                    </Card>
                )}
            </Box>
        </Container>
    )
}

export default Invite
