import { Box, Card, CircularProgress, Divider, Grid, LinearProgress, Typography } from '@mui/material'
import { ApiError, CancelablePromise, FeatureUsage, FeatureUsageResponse } from '../api/client'
import { useQuery } from 'react-query'
import ErrorBlock from './Error'
import React, { Fragment } from 'react'
import { humanizeSize } from 'utils/size'

interface UsageProps {
    loadUsage: () => CancelablePromise<FeatureUsageResponse>
}

export const Usage: React.FC<UsageProps> = ({ loadUsage }) => {
    const { data, error, isLoading } = useQuery<FeatureUsageResponse, ApiError>(
        ['featureUsageList'],
        async () => loadUsage(),
        { retry: false }
    )

    const renderFeature = (entry: FeatureUsage) => {
        let rightText = ''
        let progressBar = null
        const description = entry.feature.description
        switch (entry.type) {
            case 'seats':
            case 'active_projects':
                if (entry.feature.amount === null) {
                    rightText = 'Unlimited'
                } else {
                    rightText = `${entry.usage} of ${entry.feature.amount} included`
                    progressBar = (
                        <LinearProgress
                            variant="determinate"
                            value={(entry.usage / entry.feature.amount) * 100}
                            sx={{ height: 10, borderRadius: 5 }}
                        />
                    )
                }
                break
            case 'data_retention':
                rightText = `${entry.feature.amount} days`
                break
            case 'report_size':
                const [amount, unit] = humanizeSize(entry.feature.amount)
                rightText = `${amount} ${unit}`
                break
            case 'github_public_repositories':
            case 'github_private_repositories':
            case 'on_premise_deployment':
                rightText = 'Included'
                break
            case 'customer_support':
                rightText = entry.feature.level
                break
        }
        if (progressBar) {
            return (
                <Box>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography>
                                <strong>{entry.feature.name}</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ alignSelf: 'center' }}>
                            {progressBar}
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 1 }}>
                        <Grid item xs={9}>
                            <Typography variant="body2" color="textSecondary">
                                {description}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" align="right">
                                <strong>{rightText}</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            )
        } else {
            return (
                <Box>
                    <Grid container>
                        <Grid item xs={9}>
                            <Typography>
                                <strong>{entry.feature.name}</strong>
                            </Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                {description}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} sx={{ alignSelf: 'center' }}>
                            <Typography align="right" sx={{ textTransform: 'capitalize' }}>
                                <strong>{rightText}</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            )
        }
    }

    return (
        <div className="content-rows">
            <Card className="card">
                {error ? (
                    <ErrorBlock message="Failed to load available products" />
                ) : isLoading || data === undefined ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    data.items.map((item, index, array) => {
                        return (
                            <Fragment key={index}>
                                {renderFeature(item)}
                                {index !== array.length - 1 && <Divider sx={{ my: 2 }} />}
                            </Fragment>
                        )
                    })
                )}
            </Card>
        </div>
    )
}

