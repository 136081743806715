/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class DefaultService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Health check probe.
     * @returns void
     * @throws ApiError
     */
    public healthCheck(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/health_check',
        })
    }
    /**
     * Get a path to redirect to within Schemathesis.io
     * @returns any Redirect exists.
     * @throws ApiError
     */
    public redirect({
        key,
    }: {
        /**
         * Redirect key
         */
        key: string
    }): CancelablePromise<{
        path: string
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/redirect/{key}/',
            path: {
                key: key,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
}
