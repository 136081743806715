import {
    Box,
    Card,
    CircularProgress,
    Table,
    Skeleton,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    Menu,
    MenuItem,
    Tooltip,
    IconButton,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    TableHead,
} from '@mui/material'
import { useQuery } from 'react-query'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { OrganizationMemberList, apiClient, ApiError, Organization, OrganizationMember } from '../../../api'
import DataRow from '../../../components/DataRow'
import { hasAdminAccess } from '../../../utils/access'
import React, { useState } from 'react'
import InviteMembersDialog from './InviteMembersDialog'
import ConfirmationDialog from './ConfirmDialog'
import { useSeatsCheck } from '../../../hooks/useSeatsCheck'
import { SEATS_LIMIT_REACHED_ALERT, SEATS_NOT_INCLUDED_ALERT } from './Invitations'
import { LoadingButton } from '@mui/lab'
import { Refetch } from 'models/Utils'
import { Role } from 'models/Account'
import { TitleBlock } from 'layout/components/titles'
import { UserAvatar } from 'features/components/users'
import { useUsers } from 'contexts/UsersContext'

const OrganizationMembers: React.FC<{
    organization: Organization
    refetch: Refetch
}> = ({ organization, refetch }) => {
    const { hasAccess } = useUsers()
    const role = organization?.role || ''

    const { isLoading, data } = useQuery<OrganizationMemberList, ApiError>({
        queryKey: ['OrganizationMembers', organization.slug],
        queryFn: async () => apiClient.organizations.organizationsMembersList({ organization: organization.slug }),
    })
    const members = data?.items || []

    const [selectedMember, setSelectedMember] = useState<OrganizationMember | undefined>()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const openSettingMenu = Boolean(anchorEl)

    const handleSettingsMenuClick = (event: React.MouseEvent<HTMLElement>, member: OrganizationMember) => {
        setAnchorEl(event.currentTarget)
        setSelectedMember(member)
    }

    const handleSettingMenuClose = () => {
        setAnchorEl(null)
    }

    const [openRemoveMemberDialog, setOpenRemoveMemberDialog] = useState<boolean>(false)

    const handleRemoveMemberDialogClick = () => {
        setOpenRemoveMemberDialog(true)
    }

    const onRemoveMemberDialogClose = () => {
        refetch().then(() => {
            setOpenRemoveMemberDialog(false)
        })
    }

    const [openChangeRoleDialog, setOpenChangeRoleDialog] = useState<boolean>(false)

    const handleChangeRoleDialogClick = () => {
        setOpenChangeRoleDialog(true)
    }

    const onChangeRoleDialogClose = () => {
        refetch().then(() => {
            setOpenChangeRoleDialog(false)
        })
    }

    const [openInviteNewMembers, setOpenInviteNewMembers] = useState<boolean>(false)

    const handleInviteNewMembersDialogClose = () => {
        refetch().then(() => {
            setOpenInviteNewMembers(false)
        })
    }

    const { activeSeats } = useSeatsCheck(organization)

    if (isLoading) {
        return <Skeleton variant="rectangular" height="25%" />
    }

    return (
        <>
            <div className="content-rows">
                <TitleBlock
                    title="Members"
                    extra={
                        <>
                            {hasAdminAccess(organization.role) && hasAccess('organization:admin') && (
                                <LoadingButton
                                    variant="contained"
                                    onClick={() => {
                                        setOpenInviteNewMembers(true)
                                    }}
                                    loading={activeSeats.isLoading()}
                                    disabled={!activeSeats.isPassed()}
                                >
                                    Invite new members
                                </LoadingButton>
                            )}
                        </>
                    }
                />

                {activeSeats.isLimitExceeded() && SEATS_LIMIT_REACHED_ALERT}
                {activeSeats.isNotIncluded() && SEATS_NOT_INCLUDED_ALERT}
                <Card className="card">
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan={5} sx={{ fontWeight: 600 }}>
                                    {members.length === 1 ? `${members.length} member` : `${members.length} members`}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading ? (
                                <DataRow>
                                    <TableCell component="th" scope="row" colSpan={4} sx={{ textAlign: 'center' }}>
                                        <CircularProgress />
                                    </TableCell>
                                </DataRow>
                            ) : (
                                <>
                                    {members.length === 0 ? (
                                        <DataRow>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                colSpan={4}
                                                sx={{ textAlign: 'center' }}
                                            >
                                                <Typography variant="subtitle1">
                                                    Organization does not have any members
                                                </Typography>
                                            </TableCell>
                                        </DataRow>
                                    ) : (
                                        <>
                                            {members.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                    }}
                                                >
                                                    <TableCell>
                                                        <Box
                                                            display="flex"
                                                            flexDirection="row"
                                                            width="100%"
                                                            alignItems="center"
                                                        >
                                                            <UserAvatar
                                                                alt={row.username}
                                                                avatar_url={row.avatar_url}
                                                                size="big"
                                                            />

                                                            {row.name ? (
                                                                <Box ml={1} display="flex" flexDirection="column">
                                                                    <Typography fontSize="14px">{row.name}</Typography>
                                                                    <Typography fontSize="12px">
                                                                        {row.username}
                                                                    </Typography>
                                                                </Box>
                                                            ) : (
                                                                <Typography ml={1} fontSize="14px">
                                                                    {row.username}
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        <Box
                                                            display="flex"
                                                            flexDirection="row"
                                                            width="100%"
                                                            alignItems="center"
                                                            justifyContent="flex-end"
                                                        >
                                                            <Box sx={{ textTransform: 'capitalize' }}>{row.role}</Box>
                                                            {hasAdminAccess(role) &&
                                                                hasAccess('organization:admin') && (
                                                                    <Tooltip
                                                                        title="Settings"
                                                                        placement="right"
                                                                        sx={{ marginLeft: 2 }}
                                                                    >
                                                                        <IconButton
                                                                            onClick={(event) => {
                                                                                handleSettingsMenuClick(event, row)
                                                                            }}
                                                                        >
                                                                            <SettingsOutlinedIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )}
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </Card>
            </div>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openSettingMenu}
                onClose={handleSettingMenuClose}
                onClick={handleSettingMenuClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    onClick={() => {
                        handleChangeRoleDialogClick()
                    }}
                >
                    Change role...
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleRemoveMemberDialogClick()
                    }}
                >
                    Remove from organization...
                </MenuItem>
            </Menu>
            {selectedMember && organization ? (
                <>
                    <RemoveMemberDialog
                        organization={organization}
                        member={selectedMember}
                        open={openRemoveMemberDialog}
                        onClose={onRemoveMemberDialogClose}
                    />
                    <ChangeRoleDialog
                        organization={organization}
                        member={selectedMember}
                        open={openChangeRoleDialog}
                        onClose={onChangeRoleDialogClose}
                    />
                </>
            ) : (
                <></>
            )}
            {organization ? (
                <InviteMembersDialog
                    organization={organization}
                    open={openInviteNewMembers}
                    handleCancel={handleInviteNewMembersDialogClose}
                />
            ) : (
                <></>
            )}
        </>
    )
}

const RemoveMemberDialog: React.FC<{
    organization: Organization
    member: OrganizationMember
    open: boolean
    onClose: () => void
}> = ({ organization, member, open, onClose }) => {
    const handleRemoveMember = () => {
        return apiClient.organizations.organizationsMembersRemove({
            organization: organization.slug,
            requestBody: [member.id],
        })
    }

    return (
        <ConfirmationDialog
            title={`Removing ${member.username} from ${organization.name}`}
            successMessage={`Removed ${member.username} from ${organization.name}`}
            confirmButtonText="Remove member"
            open={open}
            onClose={onClose}
            handleConfirm={handleRemoveMember}
        />
    )
}

const ChangeRoleDialog: React.FC<{
    organization: Organization
    member: OrganizationMember
    open: boolean
    onClose: () => void
}> = ({ organization, member, open, onClose }) => {
    const [role, setRole] = useState<Role>(member.role)

    const handleChangeRole = () => {
        return apiClient.organizations.organizationsMembersUpdate({
            organization: organization.slug,
            member: member.id,
            requestBody: { role },
        })
    }

    return (
        <ConfirmationDialog
            title={`Change the role of ${member.username}?`}
            successMessage={`Changed the role of ${member.username} to ${role}`}
            confirmButtonText="Change role"
            confirmButtonDisabled={role === member.role}
            content={<ChoseRoleRadio role={role} setRole={setRole} />}
            open={open}
            onClose={onClose}
            handleConfirm={handleChangeRole}
        />
    )
}

const RadioLabelDescription: React.FC<{ text: string }> = ({ text }) => {
    return (
        <Box display="flex">
            <div style={{ width: '31px' }}></div>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px' }}>{text}</Typography>
        </Box>
    )
}

const ChoseRoleRadio: React.FC<{ role: Role; setRole: (v: Role) => void }> = ({ role, setRole }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRole((event.target as HTMLInputElement).value as Role)
    }

    return (
        <FormControl>
            <FormLabel id="new-role-radio-buttons-group" sx={{ fontSize: '14px' }}>
                Select a new role
            </FormLabel>
            <RadioGroup
                aria-labelledby="new-role-radio-buttons-group"
                name="new-role-radio-buttons-group"
                value={role}
                onChange={handleChange}
                sx={{
                    '.MuiFormControlLabel-root': {
                        paddingTop: '14px',
                    },
                    '.MuiRadio-root': {
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                }}
            >
                <Box>
                    <FormControlLabel
                        value="owner"
                        control={<Radio />}
                        label={<strong style={{ fontSize: '14px' }}>Owner</strong>}
                    />
                    <RadioLabelDescription text="Has full access to the entire organization" />
                </Box>
                <Box>
                    <FormControlLabel
                        value="member"
                        control={<Radio />}
                        label={<strong style={{ fontSize: '14px' }}>Member</strong>}
                    />
                    <RadioLabelDescription text="Can see every member and create new APIs" />
                </Box>
            </RadioGroup>
        </FormControl>
    )
}

export default OrganizationMembers
