/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingInfo } from '../models/BillingInfo'
import type { CheckoutSession } from '../models/CheckoutSession'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class BillingService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List available products.
     * @returns BillingInfo A list of products.
     * @throws ApiError
     */
    public billingProductsList(): CancelablePromise<BillingInfo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/billing/products/',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * Checkout.
     * @returns any Session created.
     * @throws ApiError
     */
    public billingCheckoutCreate({
        requestBody,
    }: {
        requestBody: {
            price_id: string
        }
    }): CancelablePromise<{
        checkout_url: string | null
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/billing/checkout/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
                409: `Generic error response.`,
                500: `Generic error response.`,
            },
        })
    }
    /**
     * Get Checkout Session details.
     * @returns CheckoutSession Details about Checkout Session
     * @throws ApiError
     */
    public billingCheckoutDetails({
        checkoutSessionId,
    }: {
        checkoutSessionId: string
    }): CancelablePromise<CheckoutSession> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/billing/checkout/{checkout_session_id}/',
            path: {
                checkout_session_id: checkoutSessionId,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
                500: `Generic error response.`,
            },
        })
    }
}
