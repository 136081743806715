import CenteredPageLayout from 'components/CenteredPageLayout'
import { useParams } from 'react-router-dom'
import RunDetail from '../Runs/RunDetail'
import { ApiPageHeader } from './ApiPageHeader'

export const ApiRun: React.FC = () => {
    const { id, runId } = useParams<{ id: string; runId: string }>()
    return (
        <CenteredPageLayout>
            <ApiPageHeader id={id!} page={null} />
            <RunDetail key={runId} id={runId!} apiId={id!} />
        </CenteredPageLayout>
    )
}
