import { LoadingButton } from '@mui/lab'
import { Box, Card, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { apiClient, ApiError } from '../../../api'
import { URLS } from '../../../urls'
import React from 'react'
import { TitleBlock } from 'layout/components/titles'
import { useUsers } from 'contexts/UsersContext'

type CreateOrganizationFormState = {
    name: string
    contact_email: string
}

const CreateOrganization: React.FC = () => {
    const { hasAccess } = useUsers()
    const {
        clearErrors,
        setError,
        control,
        handleSubmit,
        watch,
        formState: { isDirty, errors },
    } = useForm<CreateOrganizationFormState>({})

    const watchName = watch('name')

    const navigate = useNavigate()

    const createOrganizationMutation = useMutation(
        async (requestBody: CreateOrganizationFormState) =>
            apiClient.organizations.organizationsCreate({
                requestBody,
            }),
        {
            onSuccess: (organization) => {
                navigate(
                    hasAccess('organization:admin')
                        ? URLS.organizations.onboarding.buildPath({ slug: organization.slug })
                        : URLS.organizations.profile.buildPath({ slug: organization.slug }),
                )
            },
            onError: (response: ApiError) => {
                clearErrors()
                if (response.status === 409) {
                    setError('name', {
                        type: 'custom',
                        message: `Organization '${watchName}' already exists`,
                    })
                } else if (response.body) {
                    const { errors } = response.body
                    for (const [name, fieldErrors] of Object.entries(errors)) {
                        // @ts-expect-error
                        for (const error of fieldErrors) {
                            if (name === 'name') {
                                setError(name, { type: 'custom', message: error.message })
                            }
                            if (name === 'contact_email') {
                                setError(name, { type: 'custom', message: error.message })
                            }
                        }
                    }
                }
            },
        },
    )

    const onSubmit = (data: CreateOrganizationFormState) => {
        createOrganizationMutation.mutate(data)
    }

    return (
        <div className="content-rows content-padded">
            <TitleBlock
                title="Set up your organization"
                subtitle="You'll be able to group your APIs within an organization as well as provide your colleagues with
                        organization-wide permissions."
            />

            <Card className="card">
                <Box
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete="off"
                    display="flex"
                    flexDirection="column"
                    width="50%"
                >
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Organization name"
                                type="name"
                                fullWidth
                                size="small"
                                variant="outlined"
                                sx={{
                                    mb: 2,
                                }}
                                {...field}
                                error={!!errors.name}
                                helperText={errors?.name?.message}
                            />
                        )}
                    />
                    <Controller
                        name="contact_email"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Contact email"
                                type="contact_email"
                                fullWidth
                                size="small"
                                variant="outlined"
                                sx={{
                                    mb: 2,
                                }}
                                {...field}
                                error={!!errors.contact_email}
                                helperText={errors?.contact_email?.message}
                            />
                        )}
                    />
                    <Box>
                        <LoadingButton
                            loading={createOrganizationMutation.isLoading}
                            type="submit"
                            variant="contained"
                            disabled={watchName === '' || !isDirty}
                        >
                            Create Organization
                        </LoadingButton>
                    </Box>
                </Box>
            </Card>
        </div>
    )
}

export default CreateOrganization
