import React from 'react'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Tooltip, IconButton } from '@mui/material'

export function ReadyToCopyIcon({ onClick }: { onClick: () => void }) {
    return (
        <Tooltip title="copy to clipboard">
            <IconButton aria-label="copy-to-clipboard" size="small" onClick={onClick}>
                <ContentCopyIcon fontSize="small" />
            </IconButton>
        </Tooltip>
    )
}

export function CopySucceedIcon() {
    return (
        <Tooltip title="copied">
            <span>
                <IconButton aria-label="copy-to-clipboard" size="small" disabled>
                    <CheckCircleOutlineIcon htmlColor="green" fontSize="small" />
                </IconButton>
            </span>
        </Tooltip>
    )
}
