import React from 'react'

import '../styles/titles.less'
import { Card } from '@mui/material'

export const TitleBlock: React.FC<{
    title: React.ReactNode
    subtitle?: React.ReactNode
    avatar?: React.ReactNode
    extra?: React.ReactNode
}> = ({ title, subtitle, avatar, extra }) => {
    return (
        <Card>
            <div className="section-headline">
                <div className="main">
                    {avatar}
                    <div>
                        <h1>{title}</h1>
                        {subtitle && <span className="sub">{subtitle}</span>}
                    </div>
                </div>
                {extra}
            </div>
        </Card>
    )
}
