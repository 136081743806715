import React from 'react'
import { Alert, AlertTitle, Box } from '@mui/material'

interface AlertBoxProps {
    severity: 'success' | 'error' | 'info' | 'warning'
    title?: string
    children?: React.ReactNode
}

const AlertBox: React.FC<AlertBoxProps> = ({ severity, title, children }) => (
    <Box
        boxShadow={'0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)'}
        sx={{ marginTop: 2, marginBottom: 2, borderRadius: '8px', borderColor: 'rgba(0, 0, 0, 0.87)' }}
    >
        <Alert severity={severity}>
            {title && (
                <AlertTitle>
                    <strong>{title}</strong>
                </AlertTitle>
            )}
            {children}
        </Alert>
    </Box>
)

export default AlertBox
