import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

export const CustomNavLink = React.forwardRef<
    HTMLAnchorElement,
    NavLinkProps & { activeClassName?: (isActive: boolean) => string }
>((props, ref) => (
    <NavLink
        ref={ref}
        {...props}
        className={({ isActive }) =>
            `${props.className} ${isActive && props.activeClassName ? props.activeClassName(isActive) : ''}`
        }
    />
))
