import React from 'react'
import '../styles/errors.less'
import { Link } from 'react-router-dom'
import { useUsers } from 'contexts/UsersContext'

const Error: React.FC<{ message?: string; ctaText?: string; ctaLink?: string }> = ({
    message = 'Page not found',
    ctaText,
    ctaLink,
}) => {
    const { userObject } = useUsers()

    if (userObject.isLoading) return null

    return (
        <div className="snippet-error">
            <div className="title">
                4
                <div className="logo-wrapper">
                    <div className="logo" />
                </div>
                4
            </div>
            <div className="message">{message}</div>

            {ctaText && ctaLink && (
                <Link className="cta" to={ctaLink}>
                    {ctaText}
                </Link>
            )}
        </div>
    )
}

export { Error }
