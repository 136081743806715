/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessTokens } from '../models/AccessTokens'
import type { EmailPreferences } from '../models/EmailPreferences'
import type { FeatureUsage } from '../models/FeatureUsage'
import type { FeatureUsageResponse } from '../models/FeatureUsageResponse'
import type { PasswordRecoveryRequest } from '../models/PasswordRecoveryRequest'
import type { PasswordResetRequest } from '../models/PasswordResetRequest'
import type { SignupRequest } from '../models/SignupRequest'
import type { UserProfile } from '../models/UserProfile'
import type { VerifyAccountRequest } from '../models/VerifyAccountRequest'
import type { VerifyPasswordResetTokenRequest } from '../models/VerifyPasswordResetTokenRequest'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class UsersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * List access tokens.
     * @returns AccessTokens A list of access tokens.
     * @throws ApiError
     */
    public usersTokensList(): CancelablePromise<AccessTokens> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/tokens/',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
    /**
     * Create a new access token for CLI.
     * @returns any A new access token.
     * @throws ApiError
     */
    public usersTokensCreate(): CancelablePromise<{
        token: string
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/tokens/',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
    /**
     * Delete an access token.
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public usersTokensDelete({
        tokenId,
    }: {
        /**
         * Access token ID.
         */
        tokenId: number
    }): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/tokens/{token_id}/',
            path: {
                token_id: tokenId,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
    /**
     * Current usage of available features.
     * @returns FeatureUsageResponse Successful operation
     * @throws ApiError
     */
    public usersUsageList(): CancelablePromise<FeatureUsageResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/usage/',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
            },
        })
    }
    /**
     * Current usage of a specific feature.
     * @returns any Successful operation
     * @throws ApiError
     */
    public usersUsageDetails({ feature }: { feature: string }): CancelablePromise<FeatureUsage | null> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/usage/{feature}/',
            path: {
                feature: feature,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * Sign up a new user
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public usersSignup({
        requestBody,
        query,
    }: {
        requestBody: SignupRequest
        query?: Record<string, string>
    }): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/signup/',
            query,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                409: `Generic error response.`,
            },
        })
    }
    /**
     * Verify user's account.
     * @returns any Account verification response.
     * @throws ApiError
     */
    public usersVerify({ requestBody }: { requestBody: VerifyAccountRequest }): CancelablePromise<{
        message: string
        next: string
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/verify/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
    /**
     * Get user email preferences.
     * @returns EmailPreferences Information about user's email preferences
     * @throws ApiError
     */
    public usersEmailPreferencesDetail(): CancelablePromise<EmailPreferences> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/email-preferences/',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
    /**
     * Update user email preferences.
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public usersEmailPreferencesUpdate({ requestBody }: { requestBody: EmailPreferences }): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/email-preferences/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
    /**
     * Initiate password recovery.
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public usersPasswordsRecovery({ requestBody }: { requestBody: PasswordRecoveryRequest }): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/password/recovery/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
            },
        })
    }
    /**
     * Reset user's password
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public usersPasswordsReset({ requestBody }: { requestBody: PasswordResetRequest }): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/password/reset/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
    /**
     * Verify that the password reset token is valid
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public usersPasswordsVerifyResetToken({
        requestBody,
    }: {
        requestBody: VerifyPasswordResetTokenRequest
    }): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/password/verify-reset-token/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
    /**
     * Get information about the currently authenticated user.
     * @returns UserProfile User's profile information.
     * @throws ApiError
     */
    public usersMe(): CancelablePromise<UserProfile> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/me/',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
    /**
     * Update user's profile
     * @returns UserProfile User's profile information.
     * @throws ApiError
     */
    public usersUpdate({
        requestBody,
    }: {
        requestBody: {
            name: string
        }
    }): CancelablePromise<UserProfile> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/me/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
    /**
     * Deletes user account
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public usersClose(): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/me/',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
}
