import {
    Box,
    Button,
    Dialog as MaterialDialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormHelperText,
} from '@mui/material'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import React from 'react'

const Dialog: React.FC<{
    open: boolean
    handleCancel: () => void
    handleAction: () => void
    title: string
    content?: string | React.ReactNode
    actionText: string
    error?: string
    actionButtonDisabled?: boolean
    maxWidth?: 'sm' | 'md'
}> = ({ open, handleCancel, handleAction, title, content, actionText, error, actionButtonDisabled, maxWidth }) => {
    const cancelHoverStyle = {
        '&:hover': {
            background: '#eee',
            borderColor: '#aaa',
        },
    }
    const actionHoverStyle = {
        '&:hover': {
            background: '#dc5959',
        },
    }
    return (
        <MaterialDialog
            open={open}
            onClose={handleCancel}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            maxWidth={maxWidth || 'sm'}
            fullWidth={true}
            PaperProps={{
                sx: {
                    p: 0,
                    position: 'absolute',
                    top: '100px',
                },
            }}
        >
            <DialogTitle id="dialog-title" sx={{ fontSize: '14px', px: 4, pt: 4, pb: 3 }}>
                <strong>{title}</strong>
            </DialogTitle>
            {content && <DialogContent sx={{ fontSize: '14px', px: 4, pb: 3 }}>{content}</DialogContent>}
            <Divider />
            <DialogActions sx={{ px: 4, py: 3, display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ alignItems: 'center' }}>
                    {error && (
                        <FormHelperText sx={{ mr: 2 }} error={true}>
                            <ErrorOutlineOutlinedIcon />
                            &nbsp;
                            {error}
                        </FormHelperText>
                    )}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Button
                        onClick={handleCancel}
                        autoFocus
                        variant="outlined"
                        sx={{ borderColor: '#aaa', background: '#fff', color: '#000', ...cancelHoverStyle }}
                    >
                        <strong>Cancel</strong>
                    </Button>
                    <Box sx={{ m: 1 }} />
                    <Button
                        onClick={handleAction}
                        variant="contained"
                        color="error"
                        sx={{ ...actionHoverStyle }}
                        disabled={actionButtonDisabled}
                    >
                        <strong>{actionText}</strong>
                    </Button>
                </Box>
            </DialogActions>
        </MaterialDialog>
    )
}

export default Dialog
