import { Card, Tooltip } from '@mui/material'
import React, { useRef, useState } from 'react'
import { ProjectDraft } from '../../../api/client'
import { APP_NAME } from '../../../config'
import CenteredPageLayout from '../../../components/CenteredPageLayout'
import { Helmet } from 'react-helmet'
import { TitleBlock } from 'layout/components/titles'
import ConfigurationCreateForm from './ConfigurationCreateForm'
import { getSpecificationIcon } from 'utils/components/icons'
import '../../../utils/styles/uploaders.less'
import { useLocation } from 'react-router-dom'
import { ImportSpecification } from 'features/components/specifications'

const CreateProject: React.FC = () => {
    const [draft, setDraft] = useState<ProjectDraft | undefined>()
    const fastTrackRef = useRef<boolean>(false)
    const { state: routerState } = useLocation()

    return (
        <CenteredPageLayout>
            <Helmet>
                <title>Create Project | {APP_NAME}</title>
            </Helmet>
            <div className="content-rows">
                <TitleBlock
                    title="Create a new project"
                    subtitle="Organize and manage your API tests by setting up individual projects for each API."
                />

                <div className="content-cards">
                    <Card className="card">
                        <div className="section-block content-sides">
                            <h2>1. Import API specification</h2>
                            <div className="content-cols-s text-sm text-gray-500">
                                Supported formats:{' '}
                                <Tooltip title="OpenAPI 2.0">
                                    <div>{getSpecificationIcon('openapi', '2.0', 30)}</div>
                                </Tooltip>
                                <Tooltip title="OpenAPI 3.0">
                                    <div>{getSpecificationIcon('openapi', '3.0', 30)}</div>
                                </Tooltip>
                                <Tooltip title="GraphQL">
                                    <div>{getSpecificationIcon('graphql', '', 30)}</div>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="section-block">
                            <ImportSpecification draft={draft} setDraft={setDraft} fastTrackRef={fastTrackRef} />
                        </div>
                        <div className="section-block">
                            <h2>2. Configure project</h2>
                        </div>
                        <ConfigurationCreateForm defaults={{ ...draft, ...routerState }} fastTrackRef={fastTrackRef} />
                    </Card>
                </div>
            </div>
        </CenteredPageLayout>
    )
}

export default CreateProject
