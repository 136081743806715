import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    IconButton,
    Tooltip,
    Card,
    Box,
    Typography,
    Skeleton,
    CircularProgress,
    TableHead,
} from '@mui/material'
import { apiClient, ApiError, Organization, OrganizationInvitationList, OrganizationInvitation } from '../../../api'
import React, { useState } from 'react'
import EmailOutlined from '@mui/icons-material/EmailOutlined'
import CalendarTodayOutlined from '@mui/icons-material/CalendarTodayOutlined'
import CancelOutlined from '@mui/icons-material/CancelOutlined'
import InviteMembersDialog from './InviteMembersDialog'
import { useQuery } from 'react-query'
import ConfirmationDialog from './ConfirmDialog'
import { hasAdminAccess } from '../../../utils/access'
import { URLS } from '../../../urls'
import { Navigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { useSeatsCheck } from '../../../hooks/useSeatsCheck'
import AlertBox from '../../../components/AlertBox'
import { Refetch } from 'models/Utils'
import { TitleBlock } from 'layout/components/titles'
import { useUsers } from 'contexts/UsersContext'

enum Action {
    Cancel,
}

export const SEATS_LIMIT_REACHED_ALERT = (
    <AlertBox severity="error" title="Upgrade Needed">
        We're sorry, but your organization has reached its maximum number of seats and cannot send any more invitations
        at this time. Please manage your current seats or consider upgrading your plan to increase your capacity.
    </AlertBox>
)
export const SEATS_NOT_INCLUDED_ALERT = (
    <AlertBox severity="error" title="Upgrade Needed">
        Additional seats are not included in your current subscription. To invite more members, please contact our
        support team to upgrade your subscription.
    </AlertBox>
)

const OrganizationInvitations: React.FC<{
    organization: Organization
    refetch: Refetch
}> = ({ organization, refetch }) => {
    const { hasAccess } = useUsers()
    const isAdmin = hasAdminAccess(organization.role) && hasAccess('organization:admin')

    const { isLoading, data } = useQuery<OrganizationInvitationList, ApiError>({
        queryKey: ['OrganizationInvitations', organization.slug],
        queryFn: async () => apiClient.organizations.organizationsInvitationsList({ organization: organization.slug }),
        enabled: isAdmin,
    })
    const invitations = data?.items || []

    const [openInviteNewMembers, setOpenInviteNewMembers] = useState<boolean>(false)

    const handleInviteNewMembersDialogClose = () => {
        refetch().then(() => {
            setOpenInviteNewMembers(false)
        })
    }

    const [selectedInvitation, setSelectedInvitation] = useState<
        { invitation: OrganizationInvitation; action: Action } | undefined
    >()

    const { activeSeats } = useSeatsCheck(organization)

    if (!isAdmin) {
        return <Navigate to={URLS.organizations.index.route} />
    }

    const handleCancelInvitationDialogClick = (invitation: OrganizationInvitation) => {
        setSelectedInvitation({ invitation, action: Action.Cancel })
    }

    const onCancelInvitationDialogClose = () => {
        refetch().then(() => {
            setSelectedInvitation(undefined)
        })
    }

    if (isLoading) {
        return <Skeleton variant="rectangular" height="25%" />
    }

    return (
        <>
            <div className="content-rows">
                <TitleBlock
                    title="Invitations"
                    extra={
                        <LoadingButton
                            variant="contained"
                            onClick={() => {
                                setOpenInviteNewMembers(true)
                            }}
                            loading={activeSeats.isLoading()}
                            disabled={!activeSeats.isPassed()}
                        >
                            Invite new members
                        </LoadingButton>
                    }
                />

                {activeSeats.isLimitExceeded() && SEATS_LIMIT_REACHED_ALERT}
                {activeSeats.isNotIncluded() && SEATS_NOT_INCLUDED_ALERT}
                <Card className="card">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan={5} sx={{ fontWeight: 600 }}>
                                    {invitations.length === 1
                                        ? `${invitations.length} invitation`
                                        : `${invitations.length} invitations`}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading ? (
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                    }}
                                >
                                    <TableCell component="th" scope="row" colSpan={5} sx={{ textAlign: 'center' }}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>
                                    {invitations.length === 0 ? (
                                        <TableRow
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                colSpan={5}
                                                sx={{ textAlign: 'center' }}
                                            >
                                                <Typography variant="subtitle1">No invitations</Typography>
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        <>
                                            {invitations.map((invitation) => (
                                                <TableRow
                                                    key={invitation.id}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                    }}
                                                >
                                                    <TableCell>
                                                        <EmailOutlined />
                                                        &nbsp;
                                                        {invitation.email}
                                                    </TableCell>
                                                    <TableCell sx={{ textTransform: 'capitalize' }}>
                                                        {invitation.role}
                                                    </TableCell>
                                                    <TableCell>
                                                        <CalendarTodayOutlined />
                                                        &nbsp; Invited on {formatDate(invitation.invited_at)}
                                                    </TableCell>
                                                    <TableCell>by {invitation.invited_by}</TableCell>
                                                    <TableCell align="right">
                                                        <Tooltip title="Cancel">
                                                            <IconButton
                                                                onClick={() =>
                                                                    handleCancelInvitationDialogClick(invitation)
                                                                }
                                                            >
                                                                <CancelOutlined />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </Card>
            </div>
            {organization && (
                <>
                    {selectedInvitation && selectedInvitation.action === Action.Cancel && (
                        <CancelInvitationDialog
                            organization={organization}
                            invitation={selectedInvitation.invitation}
                            open={true}
                            onClose={onCancelInvitationDialogClose}
                        />
                    )}
                    <InviteMembersDialog
                        organization={organization}
                        open={openInviteNewMembers}
                        handleCancel={handleInviteNewMembersDialogClose}
                    />
                </>
            )}
        </>
    )
}

const CancelInvitationDialog: React.FC<{
    organization: Organization
    invitation: OrganizationInvitation
    open: boolean
    onClose: () => void
}> = ({ organization, invitation, open, onClose }) => {
    const cancelInvitation = () => {
        return apiClient.organizations.organizationsInvitationsCancel({
            organization: organization.slug,
            requestBody: [invitation.id],
        })
    }

    return (
        <ConfirmationDialog
            title={`Cancelling invitation from ${organization.name}`}
            successMessage={`Cancelled invitation from ${organization.name} for ${invitation.email}`}
            content={
                <Box sx={{ fontSize: '14px' }}>
                    <Typography sx={{ fontSize: '14px' }}>
                        Invitation for the following member will be cancelled
                    </Typography>
                    <Box sx={{ mt: 2, pb: 1, pt: 1, border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: 1 }}>
                        <EmailOutlined sx={{ ml: 1 }} />
                        &nbsp;
                        <strong>{invitation.email}</strong>
                    </Box>
                </Box>
            }
            confirmButtonText="Cancel invitation"
            open={open}
            onClose={onClose}
            handleConfirm={cancelInvitation}
        />
    )
}

function formatDate(dateString: string): string {
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
}

export default OrganizationInvitations
