import { Box, Divider, FormHelperText, Typography } from '@mui/material'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { ApiError, OrganizationInvitation } from '../../../api'
import { URLS } from '../../../urls'
import React from 'react'

export function extractInviteFromParameters(next: string) {
    const inviteMatch = URLS.organizations.invite.match(next)
    const inviteParams = inviteMatch ? (inviteMatch.params as { slug: string; id: string }) : { slug: '', id: '' }
    const organization = inviteParams.slug
    const invitationId = inviteParams.id
    return { inviteMatch, organization, invitationId }
}

const InvitationHeader: React.FC<{
    error: ApiError | null
    invitation: OrganizationInvitation | undefined
}> = ({ error, invitation }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            {error?.status === 404 ? (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <WarningAmberOutlinedIcon sx={{ alignSelf: 'center' }} />
                    <Typography variant="h6" sx={{ pt: 1, textAlign: 'center' }}>
                        Invitation not found
                    </Typography>
                    <Typography sx={{ fontSize: '12px', alignSelf: 'center', color: '#666', pt: 1 }}>
                        We could not find your invitation
                    </Typography>
                    <Divider sx={{ mt: 2 }} />
                </Box>
            ) : error?.body.detail !== undefined ? (
                <>
                    <FormHelperText sx={{ mr: 2, mt: 0, textAlign: 'center' }} error={true}>
                        <ErrorOutlineOutlinedIcon />
                        &nbsp;
                        {error?.body.detail}
                    </FormHelperText>
                    <Divider sx={{ mt: 2 }} />
                </>
            ) : (
                invitation !== undefined && (
                    <>
                        <Typography variant="h6" sx={{ textAlign: 'center' }}>
                            You've been invited to the <strong>{invitation?.organization}</strong> organization!
                        </Typography>
                        <Typography sx={{ fontSize: '12px', alignSelf: 'center', color: '#666', pt: 2 }}>
                            Invited by <strong>{invitation?.invited_by}</strong>
                        </Typography>
                        <Divider sx={{ mt: 2 }} />
                    </>
                )
            )}
        </Box>
    )
}

export default InvitationHeader
