import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { init as SentryInit } from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { createTheme, ThemeProvider } from '@mui/material'
import ReactGA from 'react-ga4'

import moment from 'moment'
import 'moment/locale/es-us'

import './static/styles/theme.less'
import './static/styles/base.less'
import './static/styles/layout.less'
import './static/styles/components-basic.less'
import './static/styles/forms.less'

import AppLayouts from 'containers/AppLayouts'

import { QueryClient, QueryClientProvider } from 'react-query'
import { UsersContextProvider } from './contexts/UsersContext'
import { AuthContextProvider } from './contexts/AuthContext'
import { useEffect } from 'react'
import { ENV } from 'config'

if (process.env.REACT_APP_SENTRY_DSN) {
    SentryInit({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    })
}

moment.locale('en')

const queryClient = new QueryClient()

const theme = createTheme({
    palette: {
        primary: {
            main: '#0097a0',
        },
    },
    typography: {
        button: {
            textTransform: 'none',
        },
    },

    components: {
        MuiCard: {
            styleOverrides: {
                // We want to have the shadow compatible with the Tailwind default shadow
                root: {
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
                    borderRadius: '8px',
                    border: 'none',
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
                disableElevation: true,
            },
        },
    },
})

const App: React.FC = () => {
    console.log(`App environment: ${ENV}`)
    useEffect(() => {
        if (ENV === 'production') ReactGA.initialize('G-2P7XEN3QBS')
    }, [])

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <AuthContextProvider>
                    <QueryClientProvider client={queryClient}>
                        <UsersContextProvider>
                            <AppLayouts />
                        </UsersContextProvider>
                    </QueryClientProvider>
                </AuthContextProvider>
            </ThemeProvider>
        </BrowserRouter>
    )
}

const container = document.getElementById('root')
const root = createRoot(container!) // createRoot(container!) if you use TypeScript
root.render(<App />)
