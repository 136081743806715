import { url } from './utils/urls'

export const URLS = {
    auth: {
        resetPassword: url('/auth/reset-password/'),
        resetPasswordCallback: url('/auth/reset-password/:token/'),
        signIn: url('/auth/sign-in/'),
        signUp: url('/auth/sign-up/'),
        githubCallback: url('/auth/github/callback/'),
        verify: url('/auth/verify-account/:token/'),
    },
    github: {
        appSetup: url('/github/setup/'),
    },
    short_url: url('/r/:key/'),
    reports: {
        wait: url('/reports/:id/wait/'),
    },
    projects: {
        index: url('/projects/'),
        create: url('/projects/create/'),
        detail: url('/projects/:id/settings/details/'),
        environments: url('/projects/:id/settings/environments/'),
        gettingStarted: url('/projects/:id/settings/getting-started/'),
        runs: url('/projects/:id/runs/'),
        run: url('/projects/:id/runs/:runId/'),
        lastRun: url('/projects/:id/last-run/'),
        issues: url('/projects/:id/issues/'),
        gettingStartedNew: url('/projects/:id/getting-started/'),
    },
    issues: {
        detail: url('/issues/:id/details/'),
    },
    onboarding: {
        gitHubAppInstalled: url('/onboarding/gh/'),
        index: url('/onboarding/'),
    },
    organizations: {
        index: url('/organizations/'),
        create: url('/organizations/create/'),
        onboarding: url('/organizations/:slug/onboarding/'),
        profile: url('/organizations/:slug/profile/'),
        members: url('/organizations/:slug/members/'),
        invitations: url('/organizations/:slug/invitations/'),
        invite: url('/organizations/:slug/invitations/:id/'),
        billing: {
            details: url('/organizations/:slug/billing/'),
            checkout: url('/organizations/:slug/billing/checkout/:sessionId/'),
        },
        usage: url('/organizations/:slug/usage/'),
        tokens: url('/organizations/:slug/tokens/'),
    },
    settings: {
        account: url('/settings/account/'),
        billing: {
            details: url('/settings/billing/'),
            checkout: url('/settings/billing/checkout/:sessionId/'),
        },
        usage: url('/settings/usage/'),
        notifications: url('/settings/notifications/'),
        tokens: url('/settings/tokens/'),
    },
}
