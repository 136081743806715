import { ProjectEnvironment } from 'api/client'
import { EnvironmentsFormType } from 'features/modelts/environments'
import {
    FieldValues,
    UseFieldArrayAppend,
    UseFieldArrayRemove,
    UseFormGetValues,
    UseFormSetValue,
} from 'react-hook-form'

export const isEmptyEnv = (environment: ProjectEnvironment) =>
    //!environment.is_default &&
    environment.name === '' && environment.url === ''

export const selectNewDefaultEnv = (
    environments: ProjectEnvironment[],
    setValue: UseFormSetValue<EnvironmentsFormType>
) => {
    for (let i = 0; i < environments.length; i++) {
        if (environments[i].is_default) return
    }
    if (environments.length) setValue(`environments.0.is_default`, true)
}

export const addEmptyEnv = (
    environments: ProjectEnvironment[],
    editing: boolean,
    append: UseFieldArrayAppend<FieldValues, 'environments'>
) => {
    if (!editing) return

    for (let i = 0; i < environments.length; i++) {
        if (isEmptyEnv(environments[i])) return
    }
    append({ name: '', url: '', description: '', is_default: false })
}

export const deleteDuplicateEmptyEnv = (
    environments: ProjectEnvironment[],
    editing: boolean,
    remove: UseFieldArrayRemove
) => {
    let hasEmpty = false
    for (let i = 0; i < environments.length; i++) {
        if (isEmptyEnv(environments[i])) {
            if (!hasEmpty && editing) hasEmpty = true
            else remove(i)
        }
    }
}

export const deselectOtherDefaults = (
    newIndex: number,
    getValues: UseFormGetValues<EnvironmentsFormType>,
    setValue: UseFormSetValue<EnvironmentsFormType>
) => {
    const values = getValues()
    for (let i = 0; i < values.environments.length; i++) {
        if (newIndex !== i && values.environments[i].is_default) {
            setValue(`environments.${i}.is_default`, false)
        }
    }
}
