import { useParams, Outlet } from 'react-router-dom'
import React from 'react'

import Banner from 'components/Banner'
import { useUsers } from 'contexts/UsersContext'
import { apiClient } from 'api'
import { ProjectContext } from 'features/contexts/projects'
import { useApiDetail } from 'queries/useApiDetail'

const APIsRouter: React.FC = () => {
    const { user, userObject, hasAccess } = useUsers()
    const { id } = useParams<{ id: string }>()

    return (
        <>
            {user?.banner && hasAccess('profile:read') && (
                <Banner
                    {...user.banner}
                    onClose={
                        hasAccess('profile:admin')
                            ? (bannerId: number) => {
                                  apiClient.banners.bannersArchive({ bannerId }).then(() => userObject.refetch())
                              }
                            : undefined
                    }
                />
            )}
            <ProjectContext.Provider value={useApiDetail(id!)}>
                <Outlet />
            </ProjectContext.Provider>
        </>
    )
}

export default APIsRouter
