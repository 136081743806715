export enum FeatureCheckStatus {
    PASSED,
    NOT_INCLUDED,
    LIMIT_EXCEEDED,
    LOADING
}

export class FeatureCheck {
    private readonly status: FeatureCheckStatus;
    private readonly usage: number | null;
    private readonly limit: number | null;

    private constructor(status: FeatureCheckStatus, usage: number | null = null, limit: number | null = null) {
        this.status = status;
        this.usage = usage;
        this.limit = limit;
    }

    static Passed(usage: number | null = null, limit: number | null = null): FeatureCheck {
        return new FeatureCheck(FeatureCheckStatus.PASSED, usage, limit);
    }

    static Loading(): FeatureCheck {
        return new FeatureCheck(FeatureCheckStatus.LOADING);
    }

    static NotIncluded(): FeatureCheck {
        return new FeatureCheck(FeatureCheckStatus.NOT_INCLUDED);
    }

    static LimitExceeded(usage: number | null = null, limit: number | null = null): FeatureCheck {
        return new FeatureCheck(FeatureCheckStatus.LIMIT_EXCEEDED, usage, limit);
    }

    getStatus(): FeatureCheckStatus {
        return this.status;
    }

    getUsage(): number | null {
        return this.usage;
    }

    getLimit(): number | null {
        return this.limit;
    }

    isPassed(): boolean {
        return this.status === FeatureCheckStatus.PASSED;
    }

    isLoading(): boolean {
        return this.status === FeatureCheckStatus.LOADING;
    }

    isNotIncluded(): boolean {
        return this.status === FeatureCheckStatus.NOT_INCLUDED;
    }

    isLimitExceeded(): boolean {
        return this.status === FeatureCheckStatus.LIMIT_EXCEEDED;
    }
}
