import React from 'react'

import { Chip, Stack, Typography } from '@mui/material'
import { UndefinedStatusCodeError } from '../../../../../api'

function UndefinedStatusCode({ context }: { context: UndefinedStatusCodeError }) {
    return (
        <Stack spacing={1}>
            <Typography>
                Received response with a status code, which is not defined in the schema:{' '}
                <Chip color="error" size="small" label={context.status_code} />
            </Typography>
            <Typography>
                Defined status codes:{' '}
                {context.defined_status_codes.map((c: string) => (
                    <Chip size="small" label={c} />
                ))}
            </Typography>
            <Typography style={{ marginBottom: 0 }}>
                Allowed status codes:{' '}
                {context.allowed_status_codes.map((c: number) => (
                    <Chip size="small" label={c} />
                ))}
            </Typography>
        </Stack>
    )
}

export default UndefinedStatusCode
