import { Skeleton, Tooltip } from '@mui/material'
import { getSchemaImage } from '../Runs/getSchemaImage'
import React from 'react'

const SchemaIcon: React.FC<{
    spec_type?: string | null
    spec_version?: string | null
    size?: number
}> = ({ spec_type, spec_version, size = 40 }) => {
    const schemaImage = spec_type && spec_version ? getSchemaImage(spec_type, spec_version) : null
    const schemaTitle = spec_type && spec_version ? `${spec_type} ${spec_version}` : null

    return (
        <div className="avatar">
            <Tooltip title={schemaTitle || 'Schema is not loaded yet'}>
                {spec_type && spec_version ? (
                    schemaImage ? (
                        <img width={size} height={size} alt="api" src={schemaImage} />
                    ) : (
                        <div
                            className="placeholder"
                            style={{
                                width: size,
                                height: size,
                            }}
                        >
                            ?
                        </div>
                    )
                ) : (
                    <Skeleton variant="circular" width={size} height={size} />
                )}
            </Tooltip>
        </div>
    )
}

export default SchemaIcon
