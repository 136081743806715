import React from 'react'
import { useQuery } from 'react-query'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined'
import { URLS } from '../../../urls'
import { ApiError, Organization, OrganizationDetailsData, apiClient } from '../../../api'
import SidebarLayout from '../../../layout/components/SidebarLayout'
import { MenuEntry } from 'layout/models/SidebarLayout'
import { hasAdminAccess } from 'utils/access'
import OrganizationInvitations from './Invitations'
import OrganizationMembers from './Members'
import OrganizationOnboarding from './Onboarding'
import OrganizationProfile from './Profile'
import Billing from './Billing'
import Usage from './Usage'
import CheckoutDetails from './CheckoutDetails'
import { Breadcrumb } from 'layout/models/Breadcrumbs'
import { OrganizationAvatar } from 'features/components/organizations'
import { useOrganizationAccessTokens } from 'hooks/useOrganizationAccessTokens'
import AccessTokens from 'features/components/accessTokens'
import { useUsers } from 'contexts/UsersContext'

const OrganizationSettingsSidebar: React.FC<{
    slug: string
    organization: Organization
    members: number
    invitations?: number
    children?: React.ReactNode
}> = ({ slug, organization, members, invitations, children }) => {
    const { hasAccess } = useUsers()
    const organizationProfileUrl = URLS.organizations.profile.buildPath({ slug })
    const icon = <OrganizationAvatar alt={organization.slug} avatar_url={organization.avatar_url} size="medium" />

    const breadcrumbsHead: Breadcrumb[] = [
        {
            title: organization.name,
            url: organizationProfileUrl,
            icon: icon,
        },
    ]

    const menu: MenuEntry[] = [
        {
            title: 'General',
            url: organizationProfileUrl,
            icon: <SettingsOutlinedIcon fontSize="small" />,
        },
    ]

    if (hasAccess('organization:admin'))
        menu.splice(
            1,
            0,
            {
                title: 'Billing',
                url: URLS.organizations.billing.details.buildPath({ slug }),
                icon: <CreditCardOutlinedIcon fontSize="small" />,
                children: [
                    {
                        title: 'Checkout',
                        url: URLS.organizations.billing.details.buildPath({ slug }),
                        isPrefix: true,
                        icon: <CreditCardOutlinedIcon fontSize="small" />,
                    },
                ],
            },
            {
                title: 'Usage',
                url: URLS.organizations.usage.buildPath({ slug }),
                icon: <SpeedOutlinedIcon fontSize="small" />,
            },
            {
                title: 'Members',
                url: URLS.organizations.members.buildPath({ slug }),
                icon: <PeopleOutlinedIcon fontSize="small" />,
                chip: members,
            },
            {
                title: 'Invitations',
                url: URLS.organizations.invitations.buildPath({ slug }),
                icon: <EmailOutlinedIcon fontSize="small" />,
                chip: invitations,
            },
        )

    if (hasAccess('organization:admin') && hasAdminAccess(organization.role))
        menu.push({
            title: 'Access tokens',
            url: URLS.organizations.tokens.buildPath({ slug }),
            icon: <KeyOutlinedIcon fontSize="small" />,
        })

    return <SidebarLayout breadcrumbsHead={breadcrumbsHead} menu={menu} children={children} />
}

const OrganizationSettingsRouter: React.FC = () => {
    const { hasAccess } = useUsers()
    const { slug } = useParams<{ slug: string }>()
    const { data, error, refetch } = useQuery<OrganizationDetailsData, ApiError>(
        ['organizationDetailsData', slug],
        async () => apiClient.organizations.organizationsDetails({ organization: slug! }),
        { retry: false },
    )
    const accessTokenProvider = useOrganizationAccessTokens('OrganizationalAccessTokens', slug!)

    if (error !== null && error.status === 404) {
        return <Navigate to={URLS.organizations.index.route} />
    }

    if (data === undefined) {
        return <></>
    }

    const { organization, members, invitations } = data as OrganizationDetailsData

    // TODO: DRY with internal router UnknownPath
    if (!hasAdminAccess(organization.role)) {
        return <Navigate to={URLS.projects.index.route} />
    }

    return (
        <OrganizationSettingsSidebar
            slug={slug!}
            organization={organization}
            members={members}
            invitations={invitations}
        >
            <Routes>
                {hasAccess('organization:admin') && (
                    <Route
                        path={`onboarding/`}
                        element={<OrganizationOnboarding organization={organization} refetch={refetch} />}
                    />
                )}
                {hasAccess('organization:read') && (
                    <Route path={`profile/`} element={<OrganizationProfile organization={organization} />} />
                )}
                {hasAccess('organization:admin') && (
                    <Route
                        path={`members/`}
                        element={<OrganizationMembers organization={organization} refetch={refetch} />}
                    />
                )}
                {hasAccess('organization:admin') && (
                    <Route
                        path={`invitations/`}
                        element={<OrganizationInvitations organization={organization} refetch={refetch} />}
                    />
                )}
                {hasAccess('organization:admin') && (
                    <Route path={`billing/`} element={<Billing organization={organization} members={members} />} />
                )}
                {hasAccess('organization:admin') && (
                    <Route path={`usage/`} element={<Usage organization={organization} />} />
                )}
                {hasAccess('organization:admin') && (
                    <Route
                        path={`billing/checkout/:sessionId/`}
                        element={<CheckoutDetails organization={organization} />}
                    />
                )}
                {['admin', 'owner'].includes(organization.role) && (
                    <Route
                        path={`tokens/`}
                        element={
                            <AccessTokens provider={accessTokenProvider} queryKey={['OrganizationalAccessTokens']} />
                        }
                    />
                )}
                <Route element={<Navigate to={URLS.projects.index.route} />} />
            </Routes>
        </OrganizationSettingsSidebar>
    )
}

export default OrganizationSettingsRouter
