import React, { useContext, useState } from 'react'

import { apiClient, TokenRequest } from 'api'
import { SetState } from 'models/Utils'

type AuthContextData = {
    isAuthenticated: boolean
    setIsAuthenticated: SetState<boolean>
    passwordRecoveryError: string | undefined
    setPasswordRecoveryError: SetState<string | undefined>
    signIn: ({ formData }: { formData: TokenRequest }) => Promise<void>
    signOut: () => Promise<void>
}

const AuthContext = React.createContext<AuthContextData>({} as AuthContextData)

export const AuthContextProvider: React.FC<{children?: React.ReactNode;}> = ({ children }) => {
    // A flag for UI to decide if the user should try to access the internal UI or be redirected to sign-in.
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated') === '1')

    const signIn = ({ formData }: { formData: TokenRequest }) =>
        apiClient.auth.authToken({ formData }).then(() => {
            localStorage.setItem('isAuthenticated', '1')
        })
    const signOut = () =>
        apiClient.auth.authLogout().then(() => {
            localStorage.removeItem('isAuthenticated')
        })

    const [passwordRecoveryError, setPasswordRecoveryError] = useState<string | undefined>(undefined)

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                setIsAuthenticated,
                passwordRecoveryError,
                setPasswordRecoveryError,
                signIn,
                signOut,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext)
