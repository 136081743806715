/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SchemaCoverage } from '../models/SchemaCoverage'
import type { SchemaCoverageInput } from '../models/SchemaCoverageInput'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class CoverageService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Measure schema coverage
     * @returns SchemaCoverage Schema coverage data
     * @throws ApiError
     */
    public coverageSchema({ requestBody }: { requestBody: SchemaCoverageInput }): CancelablePromise<SchemaCoverage> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/coverage/schema/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
}
