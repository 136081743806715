import React, { useState, useEffect } from 'react'

import { Link, useNavigate, useParams } from 'react-router-dom'

import { URLS } from 'urls'

import { useMutation } from 'react-query'
import { Alert, Box, CircularProgress, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import LoadingButton from '@mui/lab/LoadingButton'
import { apiClient, ApiError, PasswordResetRequest } from '../../../api'
import UnauthorizedPageLayout from '../../../components/UnauthorizedPageLayout'
import { passwordValidationRules } from '../../../utils/constants'
import Footer from '../../AppLayouts/PublicLayout/Footer'
import { useAuth } from '../../../contexts/AuthContext'
import { VerifyPasswordResetTokenRequest } from '../../../api/client'

function ResetPassword() {
    const [tokenError, setTokenError] = useState<string | undefined>()
    const navigate = useNavigate()
    const { setPasswordRecoveryError } = useAuth()
    const { token } = useParams<{ token: string }>()

    const verifyTokenMutation = useMutation(
        async (requestBody: VerifyPasswordResetTokenRequest) =>
            apiClient.users.usersPasswordsVerifyResetToken({ requestBody }),
        {
            onError: (error: ApiError) => {
                if (error.body) {
                    setPasswordRecoveryError(
                        'It looks like you clicked on an invalid password reset link. Please try again'
                    )
                    navigate(URLS.auth.resetPassword.route)
                } else {
                    setTokenError('Could not connect to the server, please try again in a few seconds.')
                }
            },
        }
    )
    useEffect(() => {
        if (verifyTokenMutation.isIdle) {
            verifyTokenMutation.mutate({ token: token! })
        }
    }, [verifyTokenMutation, token])

    const [passwordResetMessage, setPasswordResetMessage] = useState<string | undefined>()
    const [passwordResetError, setPasswordResetError] = useState<string | undefined>()
    const {
        control,
        handleSubmit,
        formState: { errors, dirtyFields },
    } = useForm({
        defaultValues: {
            password: '',
            token: token!,
        },
    })

    const resetPasswordMutation = useMutation(
        async (requestBody: PasswordResetRequest) => apiClient.users.usersPasswordsReset({ requestBody }),
        {
            onMutate: () => {
                setPasswordResetError(undefined)
            },
            onSuccess: () => {
                setPasswordResetError(undefined)
                setPasswordResetMessage(
                    'Your password successfully changed. You may sign in using your credentials now.'
                )
            },
            onError: (error: ApiError) => {
                if (error.body) {
                    setPasswordResetError(error.body.detail)
                } else {
                    setPasswordResetError('Could not connect to the server, please try again in a few seconds.')
                }
            },
        }
    )
    const onSubmit = (data: PasswordResetRequest) => resetPasswordMutation.mutate(data)

    return (
        <UnauthorizedPageLayout
            title="Reset your password"
            content={
                <>
                    {verifyTokenMutation.isLoading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </Box>
                    )}
                    {tokenError ? (
                        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                            {tokenError}
                        </Alert>
                    ) : null}
                    {passwordResetMessage ? (
                        <Alert severity="success" sx={{ mt: 2, mb: 2 }}>
                            {passwordResetMessage}
                        </Alert>
                    ) : null}
                    {passwordResetError ? (
                        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                            {passwordResetError}
                        </Alert>
                    ) : null}
                    {verifyTokenMutation.isSuccess &&
                        (resetPasswordMutation.isIdle || resetPasswordMutation.isLoading) && (
                            <form onSubmit={handleSubmit(onSubmit)} className="content-rows">
                                <Controller
                                    name="password"
                                    control={control}
                                    rules={{
                                        ...passwordValidationRules,
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            type="password"
                                            label="New password"
                                            variant="standard"
                                            {...field}
                                            error={!!errors.password}
                                            helperText={errors?.password?.message}
                                        />
                                    )}
                                />

                                <LoadingButton
                                    loadingPosition="start"
                                    loading={resetPasswordMutation.isLoading}
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 2 }}
                                    disabled={!('password' in dirtyFields)}
                                >
                                    Reset password
                                </LoadingButton>
                            </form>
                        )}
                </>
            }
            footer={
                <Footer>
                    <Link to={URLS.auth.signIn.route}>Back to sign in</Link>
                </Footer>
            }
        />
    )
}

export default ResetPassword
