/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GitHubAppSuccess } from '../models/GitHubAppSuccess'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class GitHubService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GitHub App setup.
     * @returns GitHubAppSuccess Successful authorization
     * @throws ApiError
     */
    public githubAppSetup({
        requestBody,
    }: {
        requestBody: {
            installation_id: number
            setup_action: string
        }
    }): CancelablePromise<GitHubAppSuccess> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/github/app/setup/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                401: `Generic error response.`,
                404: `NotFound`,
                409: `NotFound`,
                500: `Internal server error`,
            },
        })
    }
}
