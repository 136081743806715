import { Organization } from '../api'
import { SeatsUsage } from '../api/client'
import { useOrganizationFeatureUsageDetails } from '../queries/useOrganizationFeatureUsageDetails'
import { FeatureCheck } from '../utils/features'

export const useSeatsCheck = (organization: Organization) => {
    const activeSeatsQuery = useOrganizationFeatureUsageDetails(organization.slug, 'seats')

    const checkActiveSeats = () => {
        if (activeSeatsQuery.data === null) {
            return FeatureCheck.NotIncluded()
        }
        if (activeSeatsQuery.data !== undefined) {
            const data = activeSeatsQuery.data as SeatsUsage
            if (data.remaining === null || data.remaining !== 0) {
                return FeatureCheck.Passed(data.usage, data.feature.amount)
            } else {
                return FeatureCheck.LimitExceeded(data.usage, data.feature.amount)
            }
        } else {
            return FeatureCheck.Loading()
        }
    }

    return { activeSeats: checkActiveSeats() }
}
