import copy from 'copy-to-clipboard'
import React, { useEffect, useState } from 'react'
import Highlight from 'react-highlight'
import 'highlight.js/styles/github.css'
import { CopySucceedIcon, ReadyToCopyIcon } from '../../../../components/CopyToClipboardButton'
import '../../../../static/styles/components/code.less'
import { CollapseButton, ExpandButton } from 'components/ExpandButton'

export enum CodeAlign {
    LEFT = 'left',
    CENTER = 'center',
}
const COPY_ICON_RESET_TIMEOUT = 2000

export const Code: React.FC<{
    code: string
    regenerateCode?: () => Promise<string>
    codeAlign?: CodeAlign
    highlight?: string
    expandable?: boolean
}> = ({ code, regenerateCode, codeAlign, highlight, expandable = false }) => {
    const [copiedCode, setCopiedCode] = useState(false)
    const [expanded, setExpanded] = useState(!(expandable && code.split('\n').length))
    const align = (codeAlign || CodeAlign.LEFT).valueOf()

    useEffect(() => {
        // If the code is copied, reset the icon after delay
        if (copiedCode) {
            const timeout = setTimeout(() => {
                setCopiedCode(false)
            }, COPY_ICON_RESET_TIMEOUT)
            return () => clearTimeout(timeout)
        }
    }, [copiedCode, setCopiedCode])

    return (
        <pre className="grow">
            <code className={`code text-${align} ${expandable && code.split('\n').length ? 'expandable' : ''}`}>
                <div className="action">
                    {copiedCode ? (
                        <CopySucceedIcon />
                    ) : (
                        <ReadyToCopyIcon
                            onClick={() => {
                                if (regenerateCode !== undefined) {
                                    regenerateCode().then((updatedCode) => {
                                        setCopiedCode(true)
                                        copy(updatedCode)
                                    })
                                } else {
                                    setCopiedCode(true)
                                    copy(code)
                                }
                            }}
                        />
                    )}

                    {expandable &&
                        code.split('\n').length > 1 &&
                        (expanded ? (
                            <CollapseButton
                                onClick={() => {
                                    setExpanded(!expanded)
                                }}
                            />
                        ) : (
                            <ExpandButton
                                onClick={() => {
                                    setExpanded(!expanded)
                                }}
                            />
                        ))}
                </div>
                {highlight ? (
                    <Highlight className={highlight}>{expanded ? code : code.split('\n')[0]}</Highlight>
                ) : (
                    code
                )}
            </code>
        </pre>
    )
}
