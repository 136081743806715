/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class NotificationsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Archive notifications.
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public notificationsArchive({
        requestBody,
    }: {
        requestBody: {
            notification_ids: Array<number>
        }
    }): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/notifications/archive/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
            },
        })
    }
}
