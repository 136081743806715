import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { ApiError, APIErrorResponse } from '../../../api'
import { CancelablePromise } from '../../../api/client'
import Dialog from '../../../components/Dialog'

type ConfirmationDialogProps = {
    title: string
    successMessage: string
    confirmButtonText: string
    confirmButtonDisabled?: boolean
    content?: React.ReactNode
    open: boolean
    onClose: () => void
    handleConfirm: () => CancelablePromise<{
        message: string
    }>
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    title,
    successMessage,
    confirmButtonText,
    confirmButtonDisabled,
    content,
    open,
    onClose,
    handleConfirm,
}) => {
    const { enqueueSnackbar } = useSnackbar()
    const [error, setError] = useState<string | undefined>()

    const handleDialogConfirm = () => {
        handleConfirm()
            .then(() => {
                enqueueSnackbar(successMessage, { variant: 'success' })
                onClose()
            })
            .catch((error: ApiError) => {
                const data: APIErrorResponse = error.body
                setError(data.detail)
            })
    }

    return (
        <Dialog
            open={open}
            handleCancel={onClose}
            handleAction={handleDialogConfirm}
            title={title}
            error={error}
            content={content}
            actionText={confirmButtonText}
            actionButtonDisabled={confirmButtonDisabled}
        />
    )
}

export default ConfirmationDialog
