import { Alert, Box, Button, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, useRef, useState, ClipboardEvent, KeyboardEvent, useCallback } from 'react'
import { useMutation } from 'react-query'
import { ApiError, CancelablePromise, CancelError, ProjectDraft, ProjectDraftInput } from '../../api/client'
import { apiClient } from '../../api'
import { EXAMPLE_PROJECT_SPECIFICATION_URL } from '../../config'
import { PreformattedText } from 'layout/components/texts'
import { SpecificationUploader } from 'utils/components/uploaders'
import {
    SpecificationSource,
    buildRemoteUpload,
    detectSpecificationSource,
    getSpecification,
} from 'utils/functions/forms'
import { Tip } from 'utils/components/alerts'
import { SetState } from '../../models/Utils'
import { Clear, Replay } from '@mui/icons-material'
import { getSpecificationIcon } from 'utils/components/icons'
import { ProgressBar } from 'utils/components/loaders'
import '../../utils/styles/uploaders.less'
import { trackCreateProjectEvent } from 'containers/Apps/Projects/events'

enum SpecificationInputViewMode {
    SingleLine,
    MultiLine,
}

type ImportState = {
    data: string
    error?: string
    widget: SpecificationInputViewMode
}

export const ImportSpecification: React.FC<{
    draft?: ProjectDraft
    setDraft: SetState<ProjectDraft | undefined>
    fastTrackRef?: React.MutableRefObject<boolean>
    showTip?: boolean
    sourceUrl?: string
}> = ({ draft, setDraft, fastTrackRef, showTip = true, sourceUrl = undefined }) => {
    const [importState, setImportState] = useState<ImportState>({
        data: '',
        widget: SpecificationInputViewMode.SingleLine,
    })
    const [openTip, setOpenTip] = React.useState(true)
    const [source, setSource] = React.useState(sourceUrl)
    const [isDirty, setIsDirty] = React.useState(false)
    const height = openTip ? '328px' : '244px'
    const uploadingBlockHeight = openTip ? '368px' : '284px'
    const updateImportState = useCallback((updates: Partial<typeof importState>) => {
        setImportState((prevState) => ({ ...prevState, ...updates }))
    }, [])
    const setError = (error: string) => {
        updateImportState({ error })
    }
    const clearError = () => {
        updateImportState({ error: undefined })
    }
    const enlargeInputWidget = useCallback(() => {
        trackCreateProjectEvent('input_widget_enlarged')
        updateImportState({ widget: SpecificationInputViewMode.MultiLine, error: undefined })
    }, [updateImportState])
    const shrinkInputWidget = useCallback(() => {
        trackCreateProjectEvent('input_widget_shrunk')
        updateImportState({
            data: '',
            error: undefined,
            widget: SpecificationInputViewMode.SingleLine,
        })
    }, [updateImportState])

    const requestRef = useRef<CancelablePromise<ProjectDraft> | undefined>()

    const mutation = useMutation(
        async (formData: ProjectDraftInput) => {
            requestRef.current = apiClient.projects.projectsDraftsCreate({ formData })
            return requestRef.current
        },
        {
            onMutate: clearError,
            onSuccess: (draft: ProjectDraft) => {
                trackCreateProjectEvent('specification_import_success')
                setDraft(draft)
                setIsDirty(true)
            },
            onError: (error: ApiError | CancelError) => {
                trackCreateProjectEvent('specification_import_failure')
                if (!(error instanceof CancelError)) {
                    if (error.body) {
                        setError(error.body.detail)
                    } else {
                        setError('Could not connect to the server, please try again in a few seconds.')
                    }
                }
            },
        },
    )
    const onSubmit = useCallback((data: ProjectDraftInput) => mutation.mutate(data), [mutation])

    const onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
        trackCreateProjectEvent('submit_method', 'paste')
        const data = event.clipboardData.getData('text/plain')
        const payload = getSpecification(data)
        if (detectSpecificationSource(data) === SpecificationSource.Remote) setSource(data)
        onSubmit(payload)
    }

    const onReload = () => {
        if (!source) return
        trackCreateProjectEvent('submit_method', 'reload')
        const payload = getSpecification(source)
        onSubmit(payload)
    }

    const onKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (
                importState.widget === SpecificationInputViewMode.MultiLine &&
                (event.key === 'Escape' || event.key === 'Backspace' || event.key === 'Delete')
            ) {
                // Exit multiline mode immediately
                if (event.key === 'Escape') {
                    shrinkInputWidget()
                }
                // Exit only if there is no input left after pressing the key
                const currentLength = importState.data.length
                const currentCaret = (event.target as HTMLInputElement).selectionEnd
                if (
                    currentLength === 1 &&
                    ((event.key === 'Backspace' && currentCaret === 1) ||
                        (event.key === 'Delete' && currentCaret === 0))
                ) {
                    shrinkInputWidget()
                }
            } else if (event.key === 'Enter' && event.ctrlKey) {
                trackCreateProjectEvent('submit_method', 'manual_input')
                const payload = getSpecification(importState.data)
                if (detectSpecificationSource(importState.data) === SpecificationSource.Remote)
                    setSource(importState.data)
                onSubmit(payload)
            } else if (event.key.length === 1 && !event.ctrlKey) {
                enlargeInputWidget()
            }
        },
        [enlargeInputWidget, importState.data, importState.widget, onSubmit, shrinkInputWidget],
    )
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        updateImportState({
            data: event.target.value,
        })
    }

    const createExampleProject = () => {
        if (fastTrackRef) fastTrackRef.current = true
        onSubmit(buildRemoteUpload(EXAMPLE_PROJECT_SPECIFICATION_URL))
    }

    return (
        <>
            {mutation.isLoading ? (
                <Box
                    sx={{
                        height: uploadingBlockHeight,
                        flexDirection: 'column',
                        gap: 4,
                    }}
                    className="uploader-droparea"
                >
                    <div className="text-gray-500 content-rows-s">
                        {fastTrackRef?.current
                            ? 'Creating Example Project ...'
                            : 'Importing your project specification ...'}
                    </div>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            trackCreateProjectEvent('cancel_button_clicked')
                            if (requestRef.current) {
                                requestRef.current.cancel()
                            }
                        }}
                    >
                        Cancel
                    </Button>
                    <ProgressBar />
                </Box>
            ) : draft ? (
                <Box
                    sx={{
                        height: uploadingBlockHeight,
                        flexDirection: 'column',
                        gap: 4,
                    }}
                    className="uploader-droparea"
                >
                    {getSpecificationIcon(draft.specification.type, draft.specification.version)}
                    <div className="content-rows-s center">
                        {draft.name && <div className="text-gray-500 text-xm">{draft.name}</div>}
                        {draft.operations.length && (
                            <div className="text-gray-500">Operations: {draft.operations.length}</div>
                        )}
                    </div>
                    <div className="content-cols">
                        {source && (
                            <Button
                                variant="contained"
                                size="small"
                                color="error"
                                onClick={() => {
                                    trackCreateProjectEvent('reload_button_clicked')
                                    onReload()
                                }}
                                startIcon={<Replay className="icon-m" aria-hidden="true" />}
                            >
                                Reload
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            size="small"
                            color="error"
                            onClick={() => {
                                trackCreateProjectEvent('clear_button_clicked')
                                shrinkInputWidget()
                                setDraft(undefined)
                                setSource(undefined)
                            }}
                            startIcon={<Clear className="icon-m" aria-hidden="true" />}
                        >
                            Remove specification
                        </Button>
                    </div>
                </Box>
            ) : (
                <>
                    <TextField
                        id="specification-by-text"
                        placeholder="Paste raw text or URL..."
                        multiline={importState.widget === SpecificationInputViewMode.MultiLine}
                        rows={importState.widget === SpecificationInputViewMode.MultiLine ? (openTip ? 13 : 9) : 1}
                        defaultValue={importState.data}
                        autoFocus
                        variant="outlined"
                        onPaste={onPaste}
                        onKeyDown={onKeyDown}
                        onChange={onChange}
                        sx={{
                            width: '100%',
                            mb: 2,
                        }}
                        InputProps={{
                            sx: {
                                height:
                                    importState.widget === SpecificationInputViewMode.MultiLine ? height : 'inherit',
                            },
                        }}
                    />
                    {importState.widget === SpecificationInputViewMode.SingleLine ? (
                        <>
                            {showTip && (
                                <Tip
                                    open={openTip}
                                    setOpen={setOpenTip}
                                    text={
                                        <>
                                            <Typography variant="body2" fontWeight="bold">
                                                New to Schemathesis?
                                            </Typography>
                                            Click{' '}
                                            <a
                                                onClick={() => {
                                                    trackCreateProjectEvent('click_example_project_link')
                                                    createExampleProject()
                                                }}
                                            >
                                                here
                                            </a>{' '}
                                            to explore and learn with our guided example project
                                        </>
                                    }
                                />
                            )}
                            <SpecificationUploader
                                onChange={(file) => {
                                    trackCreateProjectEvent('submit_method', 'uploader')
                                    onSubmit({ local: file })
                                }}
                                name="file"
                            />
                        </>
                    ) : (
                        <div className="content-rows-s">
                            <div className="content-cols-s">
                                <PreformattedText>
                                    <code>Ctrl + Enter</code>
                                </PreformattedText>
                                <span className="text-gray">to import</span>
                            </div>
                            <div className="content-cols-s">
                                <PreformattedText>
                                    <code>Esc</code>
                                </PreformattedText>
                                <span className="text-gray">to exit multiline mode</span>
                            </div>
                        </div>
                    )}
                </>
            )}
            {importState.error && (
                <Alert severity="error" sx={{ my: 2 }}>
                    {importState.error}
                </Alert>
            )}
            {draft && isDirty && (
                <Alert severity="success" sx={{ my: 2 }}>
                    Specification successfully loaded
                </Alert>
            )}
        </>
    )
}
