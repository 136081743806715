export const passwordValidationRules = {
    required: {
        value: true,
        message: 'Please input password',
    },
    minLength: {
        value: 8,
        message: 'Minimum length of password is 8',
    },
}

export const URL_REGEX =
    /((https?):\/\/)?(www.)?(\d+\.\d+\.\d+\.\d+(:\d+)?|([a-z0-9]+(\.[a-z]{2,}){1,3}))(#?\/?[a-zA-Z0-9-#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
