import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { URLS } from 'urls'
import { Avatar, Box, Button, CircularProgress, Typography } from '@mui/material'
import { Helmet } from 'react-helmet'
import { apiClient, ApiError, BackUrl, GitHubInternalError, GitHubReject, GitHubUnsupported } from '../../../api'
import { useAuth } from '../../../contexts/AuthContext'
import { APP_NAME } from 'config'
import logo from '../../../static/images/logo-dark.png'
import useQueryParams from '../../../hooks/useQueryParams'

type ErrorResponse = GitHubInternalError | GitHubUnsupported | GitHubReject

const LogInButton: React.FC<{ text: string; onClick: () => void }> = ({ text, onClick }) => {
    return (
        <Button type="button" variant="contained" sx={{ mt: 2 }} onClick={onClick}>
            {text}
        </Button>
    )
}

const GitHubCallback: React.FC<{
    setGitHubAuthError: (value: string | undefined) => void
}> = ({ setGitHubAuthError }) => {
    const navigate = useNavigate()
    const { isAuthenticated, setIsAuthenticated } = useAuth()
    const [cta, setCta] = useState<React.ReactNode | undefined>(<CircularProgress />)
    const [content, setContent] = useState<React.ReactNode | undefined>(undefined)

    const params = useQueryParams()

    useEffect(() => {
        const DEFAULT_BACK_TO_PAGE = 'sign_up'

        function toAuthPageWithError(message: string, back_to: BackUrl) {
            setGitHubAuthError(message)
            toPage(back_to)
        }
        function toPage(back_to: BackUrl) {
            switch (back_to) {
                case 'sign_up':
                    navigate(URLS.auth.signUp.route)
                    break
                case 'sign_in':
                    navigate(URLS.auth.signIn.route)
                    break
            }
        }
        function logIn(next: string | null) {
            localStorage.setItem('isAuthenticated', '1')
            setIsAuthenticated(true)
            navigate(next || URLS.projects.index.route)
        }

        apiClient.auth
            .authSocialGithubCallback({
                state: params.state,
                code: params.code,
            })
            .then((data) => {
                switch (data.type) {
                    case 'SignIn':
                    case 'SignUp':
                        logIn(data.next)
                        break
                    case 'ConnectAccounts':
                        setContent(
                            <Content
                                text="We are going to associate this account with your Schemathesis account:"
                                email={data.email}
                                name={data.name}
                                avatar_url={data.avatar_url}
                            />
                        )
                        setCta(<LogInButton text="Continue" onClick={() => logIn(data.next)} />)
                        break
                    case 'NewIdentity':
                        setContent(
                            <Content
                                text="We couldn't find a verified Schemathesis account matching your GitHub email:"
                                email={data.email}
                                name={data.name}
                                avatar_url={data.avatar_url}
                            />
                        )
                        setCta(<LogInButton text="Create new account" onClick={() => logIn(data.next)} />)
                        break
                    case 'Deny':
                        toAuthPageWithError(
                            'Could not authenticate you from GitHub because "The user has denied your application access."',
                            data.back_to
                        )
                        break
                }
            })
            .catch((error: ApiError) => {
                if (error.body) {
                    if (error.body.type !== undefined) {
                        const data: ErrorResponse = error.body
                        console.log('auth.social.github.callback.error', data, error.status)
                        switch (data.type) {
                            case 'Unsupported':
                                toAuthPageWithError(data.message, 'sign_up')
                                break
                            case 'Reject':
                            case 'InternalError':
                                toAuthPageWithError(data.message, data.back_to)
                                break
                        }
                    } else if (params.length) {
                        toAuthPageWithError(
                            'Could not authenticate you from GitHub because of an internal error on our side. Please, retry in a few minutes.',
                            DEFAULT_BACK_TO_PAGE
                        )
                    } else {
                        // No params, regular GET request
                        toPage(DEFAULT_BACK_TO_PAGE)
                    }
                } else {
                    toAuthPageWithError(
                        'Could not connect to the server, please try again in a few seconds.',
                        DEFAULT_BACK_TO_PAGE
                    )
                }
            })
    }, [navigate, setGitHubAuthError, setIsAuthenticated, params, isAuthenticated])

    return (
        <div className="layout-unauthorized">
            <Helmet>
                <title>GitHub Auth | {APP_NAME}</title>
            </Helmet>
            <div className="header">
                <img src={logo} alt="Schemathesis.io" />
            </div>

            <div className="body">
                {content}
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>{cta}</Box>
            </div>
        </div>
    )
}

const Content: React.FC<{ text: string; email: string; avatar_url: string; name: string }> = ({
    text,
    email,
    avatar_url,
    name,
}) => {
    return (
        <>
            <Typography align="center">{text}</Typography>
            <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Avatar alt={name} src={avatar_url} />
            </Box>
            <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography sx={{ fontSize: 12, fontFamily: 'Monospace', fontWeight: '500' }}>{email}</Typography>
            </Box>
        </>
    )
}

export default GitHubCallback
