/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RunDetails } from '../models/RunDetails'
import type { RunList } from '../models/RunList'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class RunsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get a list of test runs for a project.
     * @returns RunList Information about test runs
     * @throws ApiError
     */
    public projectsRunsList({
        projectId,
        page,
    }: {
        /**
         * Unique project ID.
         */
        projectId: string
        /**
         * The page number
         */
        page?: number
    }): CancelablePromise<RunList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/projects/{project_id}/runs/',
            path: {
                project_id: projectId,
            },
            query: {
                page: page,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * Get the test runs details.
     * @returns any Successful operation
     * @throws ApiError
     */
    public projectsRunsDetails({
        projectId,
        runId,
    }: {
        /**
         * Unique project ID.
         */
        projectId: string
        /**
         * Run ID or `latest`.
         */
        runId: string | 'latest'
    }): CancelablePromise<RunDetails | null> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/projects/{project_id}/runs/{run_id}/',
            path: {
                project_id: projectId,
                run_id: runId,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                403: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
    /**
     * Request the test run to be cancelled.
     * @returns any Generic response with a message inside.
     * @throws ApiError
     */
    public runsCancel({
        runId,
    }: {
        /**
         * Unique test run ID.
         */
        runId: string
    }): CancelablePromise<{
        message: string
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/runs/{run_id}/cancel/',
            path: {
                run_id: runId,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                404: `Generic error response.`,
                409: `Generic error response.`,
            },
        })
    }
}
