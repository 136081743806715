import { compile, PathFunction, match, MatchFunction } from 'path-to-regexp'
import queryString from 'query-string'

interface BuildPathProps {
    [key: string]: string | { [key: string]: string } | undefined | number
    queryParams?: {
        [key: string]: string
    }
}

type Route = string

export class Url {
    route: Route

    reverse: PathFunction

    match: MatchFunction

    constructor(route: Route) {
        this.route = route
        this.reverse = compile(route)
        this.match = match(route)
    }

    buildPath({ queryParams = {}, ...params }: BuildPathProps) {
        const search = queryString.stringify(queryParams)
        try {
            return search ? `${this.reverse(params)}?${search}` : this.reverse(params)
        } catch (err) {
            console.error(err)
            return '#'
        }
    }
}

export function url(route: string) {
    return new Url(route)
}

export function isUsableLocation(location: string): boolean {
    try {
        const url = new URL(location)
        const host = url.hostname
        return !(
            // Local domain
            (
                host === 'localhost' ||
                host.endsWith('.local') ||
                // Private IPv4 addresses (without parsing octets for simplicity)
                host.startsWith('10.') ||
                host.startsWith('172.16.') ||
                host.startsWith('172.17.') ||
                host.startsWith('172.18.') ||
                host.startsWith('172.19.') ||
                host.startsWith('172.20.') ||
                host.startsWith('172.21.') ||
                host.startsWith('172.22.') ||
                host.startsWith('172.23.') ||
                host.startsWith('172.24.') ||
                host.startsWith('172.25.') ||
                host.startsWith('172.26.') ||
                host.startsWith('172.27.') ||
                host.startsWith('172.28.') ||
                host.startsWith('172.29.') ||
                host.startsWith('172.30.') ||
                host.startsWith('172.31.') ||
                host.startsWith('192.168.') ||
                // Loopback address
                host.startsWith('127.') ||
                host === '::1' ||
                // Link-local
                host.startsWith('169.254.') ||
                // Broadcast
                host === '255.255.255.255' ||
                // Multicast
                host === 'ff00::' ||
                // Documentation
                host.startsWith('192.0.2.') ||
                host.startsWith('198.51.100.') ||
                host.startsWith('203.0.113.') ||
                // Unspecified
                host === '0.0.0.0' ||
                host === '::'
            )
        )
    } catch {
        // Likely a filepath
        return false
    }
}

export const noParent = (route: string, parent: string) => {
    return route.replace(parent, '')
}
