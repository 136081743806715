import { apiClient } from 'api'
import { RunDetails, RunGroup, RunIssue } from 'api/client'
import { useQuery } from 'react-query'

type RunStatsEndpoints = {
    [key in RunGroup['status']]: number
}
type RunStatsIssues = {
    [key in RunIssue['category']]: number
}

export type Run =
    | (RunDetails & {
          stats: {
              endpoints: RunStatsEndpoints
              issues: RunStatsIssues
              issuesCount: number
          }
          isDeselected: boolean
      })
    | null

export function useRunDetail(projectId: string, id: string) {
    return useQuery<Run>(
        ['RunDetail', id],
        async () => {
            const details = await apiClient.runs.projectsRunsDetails({ projectId: projectId, runId: id })
            if (!details) return null

            const stats = {
                endpoints:
                    details.groups?.reduce((prev, g) => {
                        if (!prev[g.status]) prev[g.status] = 0
                        prev[g.status] += 1
                        return prev
                    }, {} as RunStatsEndpoints) || ({} as RunStatsEndpoints),
                issues:
                    details.groups?.reduce((prev, g) => {
                        if (g.status === 'failed') {
                            g.issues.map((i) => {
                                if (!prev[i.category]) prev[i.category] = 0
                                prev[i.category] += 1
                            })
                        }

                        return prev
                    }, {} as RunStatsIssues) || ({} as RunStatsIssues),
                issuesCount:
                    details.groups?.reduce((prev, g) => (g.status === 'failed' ? prev + g.issues.length : prev), 0) ||
                    0,
            }

            return {
                ...details,
                stats,
                isDeselected: !!(
                    details.groups?.length && details.groups.map((g) => g.status).every((s) => s === 'deselected')
                ),
            }
        },
        {
            refetchInterval: (data) => (data?.status === 'in_progress' ? 1000 : false),
        },
    )
}
