import { useQuery } from 'react-query'
import { useParams, useNavigate } from 'react-router-dom'
import { CircularProgress, Box, Typography, Card, CardContent } from '@mui/material'
import { ApiError } from '../api'
import { CancelablePromise, CheckoutSession } from '../api/client'
import React, { useState, useEffect } from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { ORDER_REFETCH_INTERVAL, ORDER_WAIT_DURATION } from 'config'

const Block: React.FC<{children?: React.ReactNode;}> = ({ children }) => (
    <Box display="flex" justifyContent="center">
        <Card sx={{ width: '50%', mt: 12 }}>
            <CardContent>
                <Box display="flex" justifyContent="center">
                    {children}
                </Box>
            </CardContent>
        </Card>
    </Box>
)

interface IconBlockProps {
    Icon: React.ReactNode
    color?: string
    children?: React.ReactNode;
}

const IconBlock: React.FC<IconBlockProps> = ({ children, Icon, color }) => (
    <Block>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ alignSelf: 'center', color }}>{Icon}</Box>
            {children}
        </Box>
    </Block>
)

interface CheckoutSessionStatusProps {
    title: string
    subtitle: string
    Icon: React.ReactNode
    color?: string
}

const CheckoutSessionStatus: React.FC<CheckoutSessionStatusProps> = ({ title, subtitle, Icon, color }) => (
    <IconBlock Icon={Icon} color={color}>
        <Typography variant="h6" sx={{ pt: 1, textAlign: 'center' }}>
            {title}
        </Typography>
        <Typography sx={{ fontSize: '12px', textAlign: 'center', color: '#666', pt: 1 }}>{subtitle}</Typography>
    </IconBlock>
)

const LoadingBlock: React.FC = () => {
    return (
        <CheckoutSessionStatus
            title="Processing ..."
            subtitle="Hang tight, we're waiting on the payment provider..."
            Icon={<CircularProgress />}
        />
    )
}

interface CheckOutDetailsProps {
    queryCheckoutSession: (sessionId: string) => CancelablePromise<CheckoutSession>
}

const CheckOutDetails: React.FC<CheckOutDetailsProps> = ({ queryCheckoutSession }) => {
    const { sessionId } = useParams<{ sessionId: string }>()
    const navigate = useNavigate()
    const [enabled, setEnabled] = useState(true)
    const [noChange, setNoChange] = useState(false)
    const {
        isLoading,
        error,
        data: checkoutSession,
    } = useQuery<CheckoutSession, ApiError>({
        queryKey: ['checkoutSessionDetail', sessionId],
        queryFn: () => queryCheckoutSession(sessionId!),
        refetchInterval: ORDER_REFETCH_INTERVAL,
        retry: 1,
        enabled: !!(enabled && sessionId),
    })

    useEffect(() => {
        if (checkoutSession?.status === 'complete' || error) {
            setEnabled(false)
            if (checkoutSession?.url) {
                navigate(checkoutSession.url)
            }
        } else {
            setTimeout(() => {
                setNoChange(true)
                setEnabled(false)
            }, ORDER_WAIT_DURATION)
        }
    }, [checkoutSession, error, navigate])

    if (error) {
        if (error.status === 404) {
            return (
                <CheckoutSessionStatus
                    title="Oops"
                    subtitle="We couldn't find your Checkout Session. Please try again."
                    Icon={<WarningAmberOutlinedIcon fontSize="large" />}
                    color="error.main"
                />
            )
        }
        return (
            <CheckoutSessionStatus
                title="Payment processing failed 🙈"
                subtitle={error.body.detail}
                Icon={<WarningAmberOutlinedIcon fontSize="large" />}
                color="error.main"
            />
        )
    }

    if (noChange) {
        return (
            <CheckoutSessionStatus
                title="Checkout seems to be taking a while 🙄"
                subtitle="We're experiencing an issue on our side. Please contact our support team for assistance."
                Icon={<WarningAmberOutlinedIcon fontSize="large" />}
                color="error.main"
            />
        )
    }

    if (isLoading || !checkoutSession) {
        return <LoadingBlock />
    }
    switch (checkoutSession.status) {
        case 'open':
            return <LoadingBlock />
        case 'complete':
            return (
                <CheckoutSessionStatus
                    title="Hooray! Your payment was successful!"
                    subtitle="Taking you to your billing..."
                    Icon={<CheckCircleOutlineIcon fontSize="large" />}
                    color="success.main"
                />
            )
        case 'expired':
            return (
                <CheckoutSessionStatus
                    title="Session has expired"
                    subtitle="Your session has expired. No worries, try again in a few minutes."
                    Icon={<WarningAmberOutlinedIcon fontSize="large" />}
                    color="warning.main"
                />
            )
    }
}

export default CheckOutDetails
