import {
    Alert,
    Button,
    Card,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import { useQueryClient } from 'react-query'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { Organization } from '../../../api/client'
import { URLS } from '../../../urls'
import { apiClient, ApiError, APIErrorResponse } from '../../../api'
import DataRow from 'components/DataRow'
import { hasAdminAccess } from '../../../utils/access'
import Dialog from '../../../components/Dialog'
import { useOrganizations } from '../../../queries/useOrganizations'
import { TitleBlock } from 'layout/components/titles'
import { OrganizationAvatar } from 'features/components/organizations'
import { useUsers } from 'contexts/UsersContext'

const Organizations: React.FC = () => {
    const { hasAccess } = useUsers()
    const navigate = useNavigate()

    const queryClient = useQueryClient()

    const queryKey = ['organizationList']
    const { isLoading, data } = useOrganizations({ queryKey })

    const organizations = data?.items || []

    const [orgToLeave, setOrgToLeave] = useState<Organization | undefined>()

    const openLeaveDialog = (organization: Organization) => {
        setOrgToLeave(organization)
    }

    const closeLeaveDialog = () => {
        setOrgToLeave(undefined)
    }

    const [error, setError] = useState<string | undefined>()

    return (
        <div className="content-rows content-padded">
            {error && (
                <Alert severity="error" onClose={() => setError(undefined)}>
                    {error}
                </Alert>
            )}
            <TitleBlock
                title="Organizations"
                extra={
                    hasAccess('organization:write') ? (
                        <Button
                            variant="contained"
                            href={URLS.organizations.create.route}
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                navigate(URLS.organizations.create.route)
                            }}
                        >
                            New organization
                        </Button>
                    ) : null
                }
            />
            <Card className="card">
                <Dialog
                    open={orgToLeave !== undefined}
                    handleCancel={closeLeaveDialog}
                    handleAction={() => {
                        if (orgToLeave) {
                            apiClient.organizations
                                .organizationsLeave({ organization: orgToLeave.slug })
                                .then(closeLeaveDialog)
                                .then(() => {
                                    queryClient.invalidateQueries(queryKey)
                                })
                                .catch((error: ApiError) => {
                                    closeLeaveDialog()
                                    const data: APIErrorResponse = error.body
                                    setError(data.detail)
                                })
                        }
                    }}
                    title={`Are you sure you want to leave ${orgToLeave && orgToLeave.name}?`}
                    content="You will lose access to all APIs."
                    actionText="Leave"
                />
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '5%' }} />
                            <TableCell style={{ fontWeight: 'bold', width: '45%' }}>Name</TableCell>
                            <TableCell align="right" style={{ fontWeight: 'bold', width: '15%' }}>
                                Role
                            </TableCell>
                            <TableCell align="right" style={{ fontWeight: 'bold' }} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (
                            <DataRow>
                                <TableCell component="th" scope="row" colSpan={4} sx={{ textAlign: 'center' }}>
                                    <CircularProgress />
                                </TableCell>
                            </DataRow>
                        ) : (
                            <>
                                {organizations.length === 0 ? (
                                    <DataRow>
                                        <TableCell component="th" scope="row" colSpan={4} sx={{ textAlign: 'center' }}>
                                            <Typography variant="subtitle1">
                                                You don't have any organizations
                                            </Typography>
                                        </TableCell>
                                    </DataRow>
                                ) : (
                                    <>
                                        {organizations.map((organization) => (
                                            <TableRow
                                                key={organization.slug}
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                }}
                                            >
                                                <TableCell scope="row">
                                                    <OrganizationAvatar
                                                        alt={organization.slug}
                                                        avatar_url={organization.avatar_url}
                                                        size="medium"
                                                    />
                                                </TableCell>
                                                <TableCell scope="row">
                                                    <strong>{organization.name}</strong>
                                                </TableCell>
                                                <TableCell align="right">{organization.role}</TableCell>
                                                <TableCell align="right">
                                                    {hasAdminAccess(organization.role) &&
                                                        hasAccess('organization:admin') && (
                                                            <>
                                                                <Tooltip title="Settings" placement="right">
                                                                    <IconButton
                                                                        onClick={(event) => {
                                                                            event.stopPropagation()
                                                                            event.preventDefault()
                                                                            navigate(
                                                                                URLS.organizations.profile.buildPath({
                                                                                    slug: organization.slug,
                                                                                }),
                                                                            )
                                                                        }}
                                                                    >
                                                                        <SettingsOutlinedIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>
                                                        )}
                                                    {hasAccess('organization:admin') && (
                                                        <Tooltip title="Leave" placement="right">
                                                            <IconButton onClick={() => openLeaveDialog(organization)}>
                                                                <LogoutOutlinedIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                    </TableBody>
                </Table>
            </Card>
        </div>
    )
}

export default Organizations
