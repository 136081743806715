import React, { useEffect, useRef, useState } from 'react'
import {
    Button,
    Card,
    CardContent,
    CardActions,
    Typography,
    Table,
    TableBody,
    TableRow,
    Divider,
    Box,
    FormHelperText,
} from '@mui/material'
import { apiClient, ApiError, APIErrorResponse, Organization, OrganizationInvitee } from '../../../api'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import {
    emptyInvitee,
    InviteeTableHeader,
    Error,
    validateInvitee,
    InviteeEmailCell,
    InviteeRoleCell,
    RemoveInviteeCell,
    AddInviteeButton,
} from './InviteMembersDialog'
import { URLS } from '../../../urls'
import { useNavigate } from 'react-router-dom'
import { Refetch } from 'models/Utils'

interface Props {
    organization: Organization
    refetch: Refetch
}

const OrganizationOnboarding: React.FC<Props> = ({ organization, refetch }) => {
    const [invitees, setInvitees] = useState<OrganizationInvitee[]>([emptyInvitee])
    const nonEmptyInvites = invitees.filter((invitee) => invitee.email.trim() !== '').length
    const [apiError, setApiError] = useState<string | undefined>(undefined)
    const [errors, setErrors] = useState<Error[]>([{}])
    const hasErrors = errors.filter((error) => error.email !== undefined).length > 0
    const DEBOUNCE_DELAY = 500
    const debounceTimeout = useRef<number | null>(null)
    const navigate = useNavigate()

    useEffect(() => {
        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current)
            }
        }
    }, [])

    const handleAddInvitee = () => {
        setInvitees([...invitees, emptyInvitee])
        setErrors([...errors, {}])
    }

    const handleRemoveInvitee = (index: number) => {
        if (index === 0 && invitees.length === 1) {
            return
        }
        setInvitees(invitees.filter((_, i) => i !== index))
        setErrors(errors.filter((_, i) => i !== index))
    }

    const handleInputChange = (index: number, field: keyof OrganizationInvitee, value: string) => {
        const updatedInvitees = invitees.map((invitee, i) => {
            if (i === index) {
                return { ...invitee, [field]: value }
            }
            return invitee
        })
        setInvitees(updatedInvitees)

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current)
        }

        debounceTimeout.current = setTimeout(() => {
            debounceTimeout.current = null
            validateInvitees(updatedInvitees)
        }, DEBOUNCE_DELAY) as never
    }

    const validateInvitees = (invitees: OrganizationInvitee[]): void => {
        const emails = invitees.map(({ email }) => email)

        setErrors(
            invitees.map((invitee) => {
                return validateInvitee(invitee, emails)
            }),
        )
    }

    const handleInviteMembers = () => {
        if (errors.some((error) => Object.keys(error).length > 0)) {
            return
        }

        apiClient.organizations
            .organizationsInvitationsCreate({ organization: organization.slug, requestBody: invitees })
            .then(() => {
                refetch().then(() => {
                    navigate(URLS.organizations.invitations.buildPath({ slug: organization.slug }))
                })
            })
            .catch((error: ApiError) => {
                const data: APIErrorResponse = error.body
                setApiError(data.detail)
            })
    }

    return (
        <Card className="card">
            <h1 className="h1">Welcome to {organization.name}!</h1>
            <div className="section-block">
                <Typography variant="body1" gutterBottom>
                    To get started, you can add members to your organization. This will allow them to collaborate on
                    testing APIs.
                </Typography>
                <Table>
                    <InviteeTableHeader />
                    <TableBody>
                        {invitees.map((invitee, index) => (
                            <TableRow key={index}>
                                <InviteeEmailCell
                                    errors={errors}
                                    index={index}
                                    invitee={invitee}
                                    onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                                />
                                <InviteeRoleCell
                                    invitee={invitee}
                                    onChange={(e) => handleInputChange(index, 'role', e.target.value)}
                                    errors={errors}
                                    index={index}
                                />
                                <RemoveInviteeCell
                                    onClick={() => handleRemoveInvitee(index)}
                                    index={index}
                                    errors={errors}
                                />
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <AddInviteeButton onClick={handleAddInvitee} />
            </div>

            <div className="section-actions">
                <Button
                    variant="outlined"
                    onClick={() => {
                        navigate(URLS.organizations.profile.route)
                    }}
                >
                    Skip this step
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={hasErrors}
                    sx={{ ml: 2 }}
                    onClick={() => {
                        if (nonEmptyInvites) {
                            handleInviteMembers()
                        } else {
                            navigate(URLS.organizations.profile.route)
                        }
                    }}
                >
                    Complete Setup
                </Button>
            </div>
        </Card>
    )
}

export default OrganizationOnboarding
