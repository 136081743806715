import React from 'react'
import { Chip } from '@mui/material'
import { SetState } from 'models/Utils'

const Chips: React.FC<{ values: string[]; value: string; setValue: SetState<string> }> = ({
    values,
    value,
    setValue,
}) => {
    return (
        <div className="content-cols">
            {values.map((v) => (
                <Chip
                    key={v}
                    label={v}
                    color="primary"
                    variant={value === v ? 'filled' : 'outlined'}
                    onClick={() => setValue(v)}
                />
            ))}
        </div>
    )
}

export { Chips }
