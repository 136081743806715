import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined'
import { URLS } from '../../../urls'
import { useUsers } from '../../../contexts/UsersContext'
import SidebarLayout from '../../../layout/components/SidebarLayout'
import { useAuth } from 'contexts/AuthContext'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { MenuEntry } from 'layout/models/SidebarLayout'
import Account from './Account'
import Billing from './Billing'
import CheckoutDetails from './CheckoutDetails'
import Usage from './Usage'
import Notifications from './Notifications'
import { Breadcrumb } from 'layout/models/Breadcrumbs'
import { UserAvatar } from 'features/components/users'
import { usePersonalAccessTokens } from 'hooks/usePersonalAccessTokens'
import AccessTokens from 'features/components/accessTokens'

const UserSettingsSidebar: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { user, hasAccess } = useUsers()
    const auth = useAuth()

    const menuEntries: MenuEntry[] = [
        {
            title: 'Account',
            url: URLS.settings.account.route,
            icon: <SettingsOutlinedIcon fontSize="small" />,
        },
        {
            title: 'Billing',
            url: URLS.settings.billing.details.route,
            icon: <CreditCardOutlinedIcon fontSize="small" />,
            children: [
                {
                    title: 'Checkout',
                    url: URLS.settings.billing.details.route,
                    isPrefix: true,
                    icon: <CreditCardOutlinedIcon fontSize="small" />,
                },
            ],
        },
        {
            title: 'Usage',
            url: URLS.settings.usage.route,
            icon: <SpeedOutlinedIcon fontSize="small" />,
        },
        {
            title: 'Sign out',
            sep: true,
            icon: <LogoutOutlinedIcon fontSize="small" />,
            action: () => {
                auth.signOut()
                auth.setIsAuthenticated(false)
            },
        },
    ]

    if (hasAccess('profile:admin')) {
        menuEntries.splice(
            3,
            0,
            {
                title: 'Notifications',
                url: URLS.settings.notifications.route,
                icon: <NotificationsNoneOutlinedIcon fontSize="small" />,
            },
            {
                title: 'Access tokens',
                url: URLS.settings.tokens.route,
                icon: <KeyOutlinedIcon fontSize="small" />,
            },
        )
    }

    const breadcrumbsHead: Breadcrumb[] = [
        {
            title: user?.username || 'Profile',
            url: URLS.settings.account.route,
            icon: <UserAvatar alt={user?.username} avatar_url={user?.avatar_url} size="medium" />,
        },
    ]

    return <SidebarLayout breadcrumbsHead={breadcrumbsHead} menu={menuEntries} children={children} />
}

const UserSettingsRouter: React.FC = () => {
    const { user, hasAccess } = useUsers()
    const accessTokenProvider = usePersonalAccessTokens('PersonalAccessTokens')

    if (user === undefined) {
        return <></>
    }

    return (
        <UserSettingsSidebar>
            <Routes>
                <Route path={`account/`} element={<Account />} />
                <Route path={`billing/`} element={<Billing />} />
                <Route path={`billing/checkout/:sessionId/`} element={<CheckoutDetails />} />
                <Route path={`usage/`} element={<Usage />} />
                {hasAccess('profile:admin') && <Route path={`notifications/`} element={<Notifications />} />}
                {hasAccess('profile:admin') && (
                    <Route
                        path={`tokens/`}
                        element={<AccessTokens provider={accessTokenProvider} queryKey={['PersonalAccessTokens']} />}
                    />
                )}
                <Route element={<Navigate to={URLS.settings.account.route} />} />
            </Routes>
        </UserSettingsSidebar>
    )
}

export default UserSettingsRouter
