import DefaultCheckoutDetails from '../../../components/CheckOutDetails'
import { apiClient } from '../../../api'
import React from 'react'

const CheckoutDetails: React.FC = () => {
    return (
        <DefaultCheckoutDetails
            queryCheckoutSession={(checkoutSessionId) => {
                return apiClient.billing.billingCheckoutDetails({ checkoutSessionId })
            }}
        />
    )
}

export default CheckoutDetails
