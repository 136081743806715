import { LoadingButton } from '@mui/lab'
import { Box, Button, Card, TextField } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import GitHubIcon from '@mui/icons-material/GitHub'
import React, { useState } from 'react'
import { useUsers } from '../../../contexts/UsersContext'
import ExternalLinkIcon from '../../../components/ExternalLinkIcon'
import { useAuth } from '../../../contexts/AuthContext'
import { apiClient, ApiError } from '../../../api'
import Dialog from '../../../components/Dialog'
import { GITHUB_AUTHORIZATIONS_URL } from 'config'

const Account: React.FC = () => {
    const { user } = useUsers()
    return <AccountUpdateForm key={JSON.stringify(user)} />
}

type AccountUpdateFormState = {
    name: string
}

const GitHubApp: React.FC = () => {
    return (
        <Card>
            <div className="section-header">
                <h2 className="h2">
                    <GitHubIcon /> GitHub integration
                </h2>
            </div>
            <div className="section-block">
                <h3>This account is created via the GitHub App.</h3>
                <a href={GITHUB_AUTHORIZATIONS_URL} target="_blank" rel="noopener noreferrer">
                    Continue to GitHub to manage application access <ExternalLinkIcon />
                </a>
            </div>
        </Card>
    )
}

const CloseAccount: React.FC = () => {
    const auth = useAuth()
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleCancel = () => {
        setOpen(false)
    }
    const handleCloseAccount = () => {
        apiClient.users.usersClose().then(() => auth.setIsAuthenticated(false))
    }
    return (
        <Card className="card-error">
            <div className="section-header">
                <h2 className="h2">Danger zone</h2>
            </div>
            <div className="section-block">
                <h3 className="h3">Close account</h3>
                <p>This will permanently remove all data for your user.</p>
                <p>Closing your account cannot be undone!</p>
            </div>
            <div className="section-actions">
                <Button variant="contained" color="error" onClick={handleClickOpen}>
                    Close account
                </Button>
            </div>
            <Dialog
                open={open}
                handleCancel={handleCancel}
                handleAction={handleCloseAccount}
                title="This is permanent and cannot be undone, are you sure you want to do this?"
                actionText="Close account"
            />
        </Card>
    )
}

const AccountUpdateForm: React.FC = () => {
    const users = useUsers()

    const { enqueueSnackbar } = useSnackbar()

    const {
        clearErrors,
        setError,
        control,
        handleSubmit,
        watch,
        formState: { isDirty, errors },
    } = useForm<AccountUpdateFormState>({
        defaultValues: {
            name: users.user?.name,
        },
    })

    const watchName = watch('name')

    const updateProfileMutation = useMutation(
        async (requestBody: AccountUpdateFormState) =>
            apiClient.users.usersUpdate({
                requestBody,
            }),
        {
            onMutate: () => {
                clearErrors()
            },
            onSuccess: () => {
                clearErrors()
                enqueueSnackbar('User profile updated', { variant: 'success' })
                users.userObject.refetch()
            },
            onError: (response: ApiError) => {
                clearErrors()
                if (response.body) {
                    const { errors } = response.body
                    for (const [name, fieldErrors] of Object.entries(errors)) {
                        // @ts-expect-error
                        for (const error of fieldErrors) {
                            if (name === 'name') {
                                setError(name, { type: 'custom', message: error.message })
                            }
                        }
                    }
                }
            },
        },
    )
    const onSubmit = (data: AccountUpdateFormState) => {
        updateProfileMutation.mutate(data)
    }

    return (
        <div className="content-rows">
            <Card>
                <Box component="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="section-header">
                        <h2 className="h2">Details</h2>
                    </div>
                    <div className="section-block">
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    label="Full name"
                                    type="name"
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                    }}
                                    {...field}
                                    error={!!errors.name}
                                    helperText={errors?.name?.message}
                                    disabled={!users.hasAccess('profile:admin')}
                                />
                            )}
                        />
                    </div>
                    {users.hasAccess('profile:admin') && (
                        <div className="section-actions">
                            <LoadingButton
                                loading={updateProfileMutation.isLoading}
                                type="submit"
                                variant="contained"
                                disabled={watchName === '' || !isDirty}
                            >
                                Update profile
                            </LoadingButton>
                        </div>
                    )}
                </Box>
            </Card>

            {users.isGitHubUser && <GitHubApp />}

            {users.hasAccess('profile:admin') && <CloseAccount />}
        </div>
    )
}

export default Account
