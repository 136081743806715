import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment'
import { URLS } from 'urls'
import React from 'react'
import { APP_NAME } from 'config'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { ApiPageHeader } from 'containers/Apps/APIs/ApiPageHeader'
import { useQuery } from 'react-query'
import { apiClient, APIIssues, ShortIssue } from 'api'
import CenteredPageLayout from 'components/CenteredPageLayout'
import { Alert, Box, Card, CircularProgress, List, Tooltip } from '@mui/material'
import { StatusTagIcon } from '../Runs/StatusTag'

const IssuesList: React.FC = () => {
    const { id } = useParams<{ id: string }>()

    const issuesRes = useQuery<APIIssues>(['apiIssues', id], async () => apiClient.apis.apisIssues({ apiId: id! }), {})

    const issues: ShortIssue[] = issuesRes?.data?.items || []

    return (
        <CenteredPageLayout>
            <Helmet>
                <title>Issues | {APP_NAME}</title>
            </Helmet>
            <ApiPageHeader id={id!} page="issues" />
            {issuesRes.isLoading && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            )}
            {!issuesRes.isLoading && issues && (
                <>
                    {issues.length > 0 && (
                        <Card>
                            <List sx={{ bgcolor: 'background.paper' }}>
                                {issues.map((issue) => (
                                    <Link
                                        className="list-item"
                                        to={URLS.issues.detail.buildPath({
                                            id: issue.id,
                                        })}
                                        key={issue.short_id}
                                    >
                                        <StatusTagIcon status={issue.level} />
                                        <div className="text w50">
                                            {issue.title}
                                            <div className="secondary">{issue.operation}</div>
                                        </div>
                                        <div className="text w50 valign secondary">
                                            <CalendarTodayIcon fontSize="small" />{' '}
                                            <Tooltip
                                                placement="right"
                                                title={`Last seen: ${moment(issue.last_seen_at).format('LLL')}`}
                                            >
                                                <span>{moment(issue.last_seen_at).fromNow()}</span>
                                            </Tooltip>
                                        </div>
                                        <ChevronRightIcon />
                                    </Link>
                                ))}
                            </List>
                        </Card>
                    )}
                    {issues.length === 0 && (
                        <Card className="card">
                            <Alert severity="info" variant="outlined">
                                Issues not found
                            </Alert>
                        </Card>
                    )}
                </>
            )}
        </CenteredPageLayout>
    )
}

export default IssuesList
