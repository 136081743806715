import { Tooltip } from '@mui/material'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import ErrorIcon from '@mui/icons-material/Error'
import DangerousIcon from '@mui/icons-material/Dangerous'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import TimelapseIcon from '@mui/icons-material/Timelapse'
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors'
import React from 'react'
import '../../../static/styles/components/status.less'

export const StatusTagIcon: React.FC<{
    status: string
    hasErrors?: boolean
    className?: string
}> = ({ status, hasErrors = false, className }) => {
    const tagColor = statusTagColor(status, hasErrors)

    return (
        <div className={`icon-status colors ${tagColor} ${className}`}>
            {' '}
            {status === 'scheduled' ? (
                <WatchLaterIcon fontSize="small" />
            ) : status === 'in_progress' ? (
                hasErrors ? (
                    <RunningWithErrorsIcon fontSize="small" />
                ) : (
                    <TimelapseIcon fontSize="small" />
                )
            ) : status === 'errored' ? (
                <DangerousIcon fontSize="small" />
            ) : status === 'failed' ? (
                <ErrorIcon fontSize="small" />
            ) : status === 'succeeded' ? (
                <CheckCircleIcon fontSize="small" />
            ) : (
                <ErrorIcon fontSize="small" />
            )}
        </div>
    )
}

const statusTagColor = (status: string, hasErrors: boolean) => {
    return status === 'cancelled' || status === 'cancelling' || status === 'deselected' || status === 'skipped'
        ? 'gray'
        : status === 'in_progress'
          ? hasErrors
              ? 'orange'
              : 'sky'
          : status === 'errored' || status === 'high'
            ? 'red'
            : status === 'critical'
              ? 'darkred'
              : status === 'scheduled' || status === 'low'
                ? 'yellow'
                : status === 'medium'
                  ? 'orange'
                  : status === 'failed'
                    ? 'red'
                    : status === 'succeeded'
                      ? 'green'
                      : 'red'
}

export const StatusTag: React.FC<{
    status: string
    hasErrors?: boolean
}> = ({ status, hasErrors = false }) => {
    const statusLabel =
        status === 'scheduled'
            ? 'Scheduled'
            : status === 'in_progress'
              ? hasErrors
                  ? 'In progress with errors'
                  : 'In progress'
              : status === 'errored' || status === 'internal_error'
                ? 'Errored'
                : status === 'failed'
                  ? 'Found issues'
                  : status === 'succeeded'
                    ? 'Succeeded'
                    : status === 'cancelling'
                      ? 'Cancelling'
                      : status === 'cancelled'
                        ? 'Cancelled'
                        : 'Unknown state'

    const tagColor = statusTagColor(status, hasErrors)

    return (
        <div className={`tag-status colors ${tagColor}`}>
            <Tooltip title={statusLabel}>
                <StatusTagIcon status={status} hasErrors={hasErrors} />
            </Tooltip>

            <div>{statusLabel}</div>
        </div>
    )
}
