import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableRow,
    MenuItem,
    FormHelperText,
    TableHead,
    Select,
    TextField,
    SelectChangeEvent,
} from '@mui/material'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { apiClient, ApiError, Organization, OrganizationInvitee, APIErrorResponse } from '../../../api'
import React, { useEffect, useRef, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { URLS } from '../../../urls'
import Dialog from '../../../components/Dialog'

export interface Error {
    email?: string
}
export const emptyInvitee: OrganizationInvitee = { email: '', role: 'member' }

export const InviteeTableHeader: React.FC = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell style={{ fontWeight: 'bold', width: '55%' }}>Email address</TableCell>
                <TableCell align="right" style={{ fontWeight: 'bold', width: '35%' }}>
                    Role
                </TableCell>
                <TableCell align="right" />
            </TableRow>
        </TableHead>
    )
}

export const RemoveInviteeCell: React.FC<{ onClick: () => void; index: number; errors: Error[] }> = ({
    errors,
    index,
    onClick,
}) => {
    return (
        <TableCell align="right">
            <Button sx={{ color: 'black', minWidth: '24px' }} size="small" onClick={onClick} disabled={index === 0}>
                <CancelOutlinedIcon />
            </Button>
            {errors[index].email && <FormHelperText>&nbsp;</FormHelperText>}
        </TableCell>
    )
}

export const InviteeEmailCell: React.FC<{
    errors: Error[]
    index: number
    invitee: OrganizationInvitee
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}> = ({ errors, index, invitee: { email }, onChange }) => {
    return (
        <TableCell>
            <TextField
                error={errors[index].email !== undefined}
                helperText={errors[index].email}
                fullWidth
                label="Email"
                size="small"
                value={email}
                onChange={onChange}
            />
        </TableCell>
    )
}

export const InviteeRoleCell: React.FC<{
    errors: Error[]
    index: number
    invitee: OrganizationInvitee
    onChange: (e: SelectChangeEvent) => void
}> = ({ errors, index, invitee: { role }, onChange }) => {
    return (
        <TableCell align="right">
            <Select labelId="role-label" id="role" value={role} onChange={onChange} label="Role" size="small">
                <MenuItem value={'member'}>Member</MenuItem>
                <MenuItem value={'owner'}>Owner</MenuItem>
            </Select>
            {errors[index].email && <FormHelperText>&nbsp;</FormHelperText>}
        </TableCell>
    )
}

export function validateInvitee(invitee: OrganizationInvitee, emails: string[]) {
    const error: Error = {}
    if (invitee.email.trim() === '') {
        return error
    }
    if (!/^\S+@\S+\.\S+$/.test(invitee.email)) {
        error.email = 'Invalid email address'
    } else if (emails.filter((email) => email === invitee.email).length > 1) {
        error.email = 'Duplicate email address'
    }

    return error
}

export const AddInviteeButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
        <Button startIcon={<AddCircleOutlineOutlinedIcon />} sx={{ color: 'black', mt: '20px' }} onClick={onClick}>
            <strong>Add another</strong>
        </Button>
    )
}

const InviteMembersDialog: React.FC<{
    organization: Organization
    open: boolean
    handleCancel: () => void
}> = ({ organization, open, handleCancel }) => {
    const { enqueueSnackbar } = useSnackbar()
    const [invitees, setInvitees] = useState<OrganizationInvitee[]>([emptyInvitee])
    const nonEmptyInvites = invitees.filter((invitee) => invitee.email.trim() !== '').length
    const [apiError, setApiError] = useState<string | undefined>(undefined)
    const [errors, setErrors] = useState<Error[]>([{}])
    const hasErrors = errors.filter((error) => error.email !== undefined).length > 0
    const DEBOUNCE_DELAY = 500
    const debounceTimeout = useRef<number | null>(null)
    const navigate = useNavigate()

    useEffect(() => {
        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current)
            }
        }
    }, [])

    const handleAddInvitee = () => {
        setInvitees([...invitees, emptyInvitee])
        setErrors([...errors, {}])
    }

    const handleRemoveInvitee = (index: number) => {
        if (index === 0 && invitees.length === 1) {
            return
        }
        setInvitees(invitees.filter((_, i) => i !== index))
        setErrors(errors.filter((_, i) => i !== index))
    }

    const handleInputChange = (index: number, field: keyof OrganizationInvitee, value: string) => {
        const updatedInvitees = invitees.map((invitee, i) => {
            if (i === index) {
                return { ...invitee, [field]: value }
            }
            return invitee
        })
        setInvitees(updatedInvitees)

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current)
        }

        debounceTimeout.current = setTimeout(() => {
            debounceTimeout.current = null
            validateInvitees(updatedInvitees)
        }, DEBOUNCE_DELAY) as never
    }

    const validateInvitees = (invitees: OrganizationInvitee[]): void => {
        const emails = invitees.map(({ email }) => email)

        setErrors(
            invitees.map((invitee) => {
                return validateInvitee(invitee, emails)
            })
        )
    }

    const handleInviteMembers = () => {
        if (errors.some((error) => Object.keys(error).length > 0)) {
            return
        }

        apiClient.organizations
            .organizationsInvitationsCreate({ organization: organization.slug, requestBody: invitees })
            .then(() => {
                enqueueSnackbar(
                    `You've invited new members to ${organization.name}! They'll be receiving an email shortly.`,
                    { variant: 'success' }
                )
                handleCancel()
                navigate(URLS.organizations.invitations.buildPath({ slug: organization.slug }))
            })
            .catch((error: ApiError) => {
                const data: APIErrorResponse = error.body
                setApiError(data.detail)
            })
    }

    return (
        <Dialog
            open={open}
            handleCancel={handleCancel}
            handleAction={handleInviteMembers}
            title="Invite new members"
            error={apiError}
            maxWidth="md"
            content={
                <>
                    <Table sx={{ minWidth: 500 }} aria-label="customized table">
                        <InviteeTableHeader />
                        <TableBody>
                            {invitees.map((invitee, index) => (
                                <TableRow key={index}>
                                    <InviteeEmailCell
                                        errors={errors}
                                        index={index}
                                        invitee={invitee}
                                        onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                                    />
                                    <InviteeRoleCell
                                        invitee={invitee}
                                        onChange={(e) => handleInputChange(index, 'role', e.target.value)}
                                        errors={errors}
                                        index={index}
                                    />
                                    <RemoveInviteeCell
                                        onClick={() => handleRemoveInvitee(index)}
                                        index={index}
                                        errors={errors}
                                    />
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <AddInviteeButton onClick={handleAddInvitee} />
                </>
            }
            actionText={nonEmptyInvites <= 1 ? 'Send invite' : `Send invites (${nonEmptyInvites})`}
            actionButtonDisabled={nonEmptyInvites === 0 || hasErrors}
        />
    )
}

export default InviteMembersDialog
