import { Alert, Box, Divider, TextField, Typography } from '@mui/material'
import { useUsers } from 'contexts/UsersContext'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { URLS } from 'urls'
import { LoadingButton } from '@mui/lab'
import TOSModal from './TOSModal'
import { apiClient, ApiError, BackUrl, OrganizationInvitation, SignupRequest } from '../../../../api'
import UnauthorizedPageLayout from '../../../../components/UnauthorizedPageLayout'
import { passwordValidationRules } from '../../../../utils/constants'
import Footer from '../../../AppLayouts/PublicLayout/Footer'
import GitHubButton from '../GitHubButton'
import InvitationHeader, { extractInviteFromParameters } from '../InvitationHeader'
import useQueryParams from '../../../../hooks/useQueryParams'

const SignUp: React.FC<{
    gitHubAuthError: string | undefined
    setGitHubAuthError: (value: string | undefined) => void
}> = ({ gitHubAuthError, setGitHubAuthError }) => {
    const users = useUsers()
    const {
        control,
        handleSubmit,
        formState: { errors, dirtyFields },
        getValues,
    } = useForm({
        defaultValues: {
            email: '',
            username: '',
            password: '',
        },
    })
    const [signUpError, setSignUpError] = useState<string | undefined>()
    const [signUpSuccessful, setSignUpSuccessful] = useState<string | undefined>()
    const [title, setTitle] = useState<string>('Sign Up')
    const [openTerms, setOpenTerms] = useState<boolean>(false)

    const signUpMutation = useMutation(async (data: SignupRequest) => users.signUp(data), {
        onSuccess: () => {
            setSignUpError(undefined)
            setTitle('Almost there ...')
            setSignUpSuccessful(`Please check your email\n${getValues().email}\nto confirm your account`)
        },
        onError: (error: ApiError) => {
            if (error.body) {
                setSignUpError(error.body.detail || 'Unable to sign up with provided credentials')
            } else {
                setSignUpError('Could not connect to the server, please try again in a few seconds.')
            }
        },
    })
    const onSubmit = (data: SignupRequest) => signUpMutation.mutate(data)

    const query = useQueryParams()

    const { inviteMatch, organization, invitationId } = extractInviteFromParameters(query.next)

    const { data: invitation, error } = useQuery<OrganizationInvitation, ApiError>({
        queryKey: ['organizationInviteData', organization],
        queryFn: async () => apiClient.invitations.invitationsDetails({ organization, invitationId }),
        enabled: inviteMatch !== false,
        retry: false,
    })

    const [gitHubSignUpLoading, setGitHubSignUpLoading] = useState<boolean>(false)
    const signUpGitHub = (back_to: BackUrl) => {
        apiClient.auth
            .authSocialGithubAuthorize({
                requestBody: { back_to, next: query.next },
                query,
            })
            .then(({ authorize_url }) => {
                window.location.replace(authorize_url)
            })
            .catch((error: ApiError) => {
                setGitHubSignUpLoading(false)
                if (error.body) {
                    setSignUpError(error.body.detail)
                } else {
                    setSignUpError('Could not connect to the server, please try again in a few seconds.')
                }
            })
    }

    return (
        <UnauthorizedPageLayout
            title={title}
            header={<InvitationHeader error={error} invitation={invitation} />}
            content={
                signUpSuccessful ? (
                    <div className="center text-lg" style={{ whiteSpace: 'pre-line' }}>
                        {signUpSuccessful}
                    </div>
                ) : (
                    <>
                        {gitHubAuthError ? (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                {gitHubAuthError}
                            </Alert>
                        ) : null}
                        <Box mt={2} mb={2}>
                            <GitHubButton
                                onClick={() => {
                                    setGitHubAuthError(undefined)
                                    setGitHubSignUpLoading(true)
                                    signUpGitHub('sign_up')
                                }}
                                loading={gitHubSignUpLoading}
                                text="Sign up with GitHub"
                            />
                        </Box>
                        <Divider sx={{ mt: 2 }}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>OR</Typography>
                        </Divider>
                        {signUpError ? (
                            <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                                {signUpError}
                            </Alert>
                        ) : null}
                        <form onSubmit={handleSubmit(onSubmit)} className="content-rows" autoComplete="off">
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Please input email',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        type="email"
                                        label="Email"
                                        variant="standard"
                                        {...field}
                                        error={!!errors.email}
                                        helperText={errors?.email?.message}
                                    />
                                )}
                            />

                            <Controller
                                name="username"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Please create username',
                                    },
                                    minLength: {
                                        value: 4,
                                        message: 'Username must be at least 4 characters',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        label="Username"
                                        variant="standard"
                                        {...field}
                                        error={!!errors.username}
                                        helperText={errors?.username?.message}
                                    />
                                )}
                            />

                            <Controller
                                name="password"
                                control={control}
                                rules={{
                                    ...passwordValidationRules,
                                }}
                                render={({ field }) => (
                                    <TextField
                                        type="password"
                                        label="Password (8+ characters)"
                                        variant="standard"
                                        {...field}
                                        error={!!errors.password}
                                        helperText={errors?.password?.message}
                                    />
                                )}
                            />

                            <TOSModal
                                open={openTerms}
                                handleCancel={() => {
                                    setOpenTerms(false)
                                }}
                            />
                            <LoadingButton
                                loadingPosition="start"
                                loading={signUpMutation.isLoading}
                                type="submit"
                                variant="contained"
                                sx={{ mt: 2 }}
                                onClick={() => setGitHubAuthError(undefined)}
                                disabled={
                                    !('email' in dirtyFields && 'username' in dirtyFields && 'password' in dirtyFields)
                                }
                            >
                                Sign up
                            </LoadingButton>
                            <div className="disclaimer">
                                By creating an account, you agree to Schemathesis{' '}
                                <a
                                    role="button"
                                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                                        setOpenTerms(true)
                                        event.stopPropagation()
                                        event.preventDefault()
                                    }}
                                >
                                    Terms of Use and Privacy Policy
                                </a>
                            </div>
                        </form>
                    </>
                )
            }
            footer={
                signUpSuccessful ? (
                    <Footer>
                        No confirmation email received?
                        {'\n'} Check your spam folder or{' '}
                        <a
                            href="mailto:support@schemathesis.io?subject=Issue with email validation&body=Hi, can you please confirm my account manually?"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                        >
                            contact us
                        </a>
                    </Footer>
                ) : (
                    <Footer>
                        Already have an account?{' '}
                        <Link to={URLS.auth.signIn.buildPath({ queryParams: query })}>Sign in</Link>
                    </Footer>
                )
            }
        />
    )
}

export default SignUp
