/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReportDetails } from '../models/ReportDetails'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class ReportsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Upload Schemathesis report.
     * @returns any Schemathesis report is uploaded successfully
     * @throws ApiError
     */
    public reportsUpload({ requestBody }: { requestBody: any }): CancelablePromise<{
        message: string
        next: string
        correlation_id: string
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/reports/upload/',
            body: requestBody,
            mediaType: 'application/x-gtar',
            errors: {
                400: `Generic error response.`,
                403: `Generic error response.`,
                409: `Generic error response.`,
                413: `Generic error response.`,
            },
        })
    }
    /**
     * Get information about single test report.
     * @returns ReportDetails Test report info.
     * @throws ApiError
     */
    public reportsDetail({
        reportId,
    }: {
        /**
         * Unique test report ID.
         */
        reportId: string
    }): CancelablePromise<ReportDetails> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/reports/{report_id}/',
            path: {
                report_id: reportId,
            },
            errors: {
                400: `Generic error response.`,
                401: `Generic error response.`,
                404: `Generic error response.`,
            },
        })
    }
}
