import React from 'react'
import CenteredPageLayout from 'components/CenteredPageLayout'
import { Error } from 'utils/components/errors'

const NotFound: React.FC = () => {
    return (
        <CenteredPageLayout>
            <Error />
        </CenteredPageLayout>
    )
}

export default NotFound
