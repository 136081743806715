import React from 'react'
import { ProjectSpecificationInfo } from 'api/client'
import { GraphQLIcon } from '../../components/icons/GraphQL'
import { OpenAPI2Icon } from '../../components/icons/OpenAPI2'
import { OpenAPI3Icon } from '../../components/icons/OpenAPI3'

export const getSpecificationIcon = (
    type: ProjectSpecificationInfo['type'],
    version?: ProjectSpecificationInfo['version'],
    size = 80
): React.ReactElement => {
    switch (type) {
        case 'graphql':
            return <GraphQLIcon width={size} height={size} />
        case 'openapi':
            if (version === '2.0') {
                return <OpenAPI2Icon width={size} height={size} />
            } else {
                return <OpenAPI3Icon width={size} height={size} />
            }
    }
}
