import React, { useEffect, useState } from 'react'

import { Link, useParams, useNavigate } from 'react-router-dom'

import { useAuth } from 'contexts/AuthContext'
import { URLS } from 'urls'
import { Alert, Box, CircularProgress } from '@mui/material'
import { useMutation } from 'react-query'
import UnauthorizedPageLayout from '../../../components/UnauthorizedPageLayout'
import { apiClient, ApiError } from '../../../api'
import Footer from '../../AppLayouts/PublicLayout/Footer'
import { VerifyAccountRequest } from '../../../api/client'

function VerifyAccount() {
    const { setIsAuthenticated } = useAuth()
    const navigate = useNavigate()
    const [tokenError, setTokenError] = useState<string | undefined>()

    const { token } = useParams<{ token: string }>()

    const verifyTokenMutation = useMutation(
        async (requestBody: VerifyAccountRequest) => apiClient.users.usersVerify({ requestBody }),
        {
            onSuccess: ({ next }) => {
                setIsAuthenticated(true)
                navigate(next)
            },
            onError: (error: ApiError) => {
                if (error.body) {
                    setTokenError(error.body.detail)
                } else {
                    setTokenError('Could not connect to the server, please try again in a few seconds.')
                }
            },
        }
    )
    useEffect(() => {
        if (token && verifyTokenMutation.isIdle) {
            verifyTokenMutation.mutate({ token })
        }
    }, [verifyTokenMutation, token])

    return (
        <UnauthorizedPageLayout
            title="Account Verification"
            content={
                <>
                    {verifyTokenMutation.isLoading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </Box>
                    )}
                    {tokenError ? (
                        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                            {tokenError}
                        </Alert>
                    ) : null}
                </>
            }
            footer={
                <Footer>
                    <Link to={URLS.auth.signIn.route}>Back to sign in</Link>
                </Footer>
            }
        />
    )
}

export default VerifyAccount
