enum SpecificationSource {
    Remote,
    Local,
}

const detectSpecificationSource = (value: string): SpecificationSource => {
    if (value.trim().startsWith('http')) {
        return SpecificationSource.Remote
    } else {
        return SpecificationSource.Local
    }
}

const buildRemoteUpload = (value: string): { remote: Blob } => {
    return { remote: new Blob([JSON.stringify({ location: value.trim() })]) }
}

const getSpecification = (value: string): { remote: Blob } | { local: Blob } => {
    switch (detectSpecificationSource(value)) {
        case SpecificationSource.Remote:
            return buildRemoteUpload(value)
        case SpecificationSource.Local:
            let blobOptions
            try {
                // If this is a valid JSON, then add content type
                JSON.parse(value)
                blobOptions = { type: 'application/json' }
            } catch (e) {
                blobOptions = {}
            }
            return { local: new Blob([value], blobOptions) }
    }
}

export { SpecificationSource, detectSpecificationSource, buildRemoteUpload, getSpecification }
