import { useEffect, useState } from 'react'
import copy from 'copy-to-clipboard'
import { CopySucceedIcon, ReadyToCopyIcon } from '../../components/CopyToClipboardButton'

export const CopyValue: React.FC<{ value: string }> = ({ value }) => {
    const [isCopied, setIsCopied] = useState(false)

    useEffect(() => {
        // If the code is copied, reset the icon after delay
        if (isCopied) {
            const timeout = setTimeout(() => {
                setIsCopied(false)
            }, 2000)
            return () => clearTimeout(timeout)
        }
    }, [isCopied, setIsCopied])
    return (
        <>
            {isCopied ? (
                <CopySucceedIcon />
            ) : (
                <ReadyToCopyIcon
                    onClick={() => {
                        setIsCopied(true)
                        copy(value)
                    }}
                />
            )}
        </>
    )
}
