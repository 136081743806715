import { UserProfile, Organization } from 'api'

export enum AccountType {
    INDIVIDUAL,
    ORGANIZATION,
}

export type Account = UserProfile | Organization

export type Role = 'billing' | 'member' | 'admin' | 'manager' | 'owner'
