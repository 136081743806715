import ReactEcharts from 'echarts-for-react'
import _ from 'lodash'
import moment from 'moment'

import { Card } from '@mui/material'
import SchemaIcon from '../../SchemaIcon'
import { API } from '../../../../../api'
import React from 'react'
import '../../../../../static/styles/components/api.less'

const APIWidget: React.FC<{ api: API }> = ({ api }) => {
    const statistic = _.takeRight(api.statistic, 7)

    const chartOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
        grid: {
            top: '10px',
            left: '30px',
            right: '20px',
            bottom: '18px',
        },
        xAxis: {
            type: 'category',
            data: statistic.map(([key]) => moment(key).format('DD/MM')),
            axisTick: {
                alignWithLabel: true,
            },
        },
        yAxis: {
            type: 'value',
            minInterval: 1,
        },
        series: [
            {
                data: statistic.map(([, value]) => value),
                type: 'bar',
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)',
                },
            },
        ],
    }

    const totalIssues = api.statistic.reduce((acc, curr) => acc + (curr[1] as number), 0)

    return (
        <Card className="api-widget">
            <div className="header">
                <SchemaIcon
                    spec_type={api.project.specification.type}
                    spec_version={api.project.specification.version}
                    size={30}
                />
                <div>
                    <div className="main">{api.project.name}</div>
                </div>
            </div>

            <div className="chart">
                <ReactEcharts option={chartOption} style={{ height: 120 }} />
            </div>

            <div className="count">
                {totalIssues} {totalIssues === 1 ? 'issue' : 'issues'}
            </div>
        </Card>
    )
}

export default APIWidget
