import { Usage as DefaultUsage } from '../../../components/Usage'
import { apiClient, Organization } from '../../../api'
import { CancelablePromise, FeatureUsageResponse } from '../../../api/client'
import React from 'react'

const Usage: React.FC<{ organization: Organization }> = ({ organization }) => {
    const loadUsage = (): CancelablePromise<FeatureUsageResponse> => {
        return apiClient.organizations.organizationsUsageList({
            organization: organization.slug,
        })
    }
    return <DefaultUsage loadUsage={loadUsage} />
}

export default Usage
