import React, { useState } from 'react'

import { Routes, Route, Navigate } from 'react-router-dom'

import SignIn from 'containers/Apps/Auth/SignIn'
import SignUp from 'containers/Apps/Auth/SignUp'
import GitHubCallback from 'containers/Apps/Auth/GitHubCallback'
import ResetPassword from 'containers/Apps/Auth/ResetPassword'
import ResetPasswordCallback from 'containers/Apps/Auth/ResetPasswordCallback'
import VerifyAccount from 'containers/Apps/Auth/VerifyAccount'
import { URLS } from 'urls'

import { Container } from '@mui/material'
import { buildSignInUrl } from '../../../api'

const PublicLayout: React.FC = () => {
    const [gitHubAuthError, setGitHubAuthError] = useState<string | undefined>(undefined)
    return (
        <Container maxWidth={false} disableGutters sx={{ background: '#f0f2f5', minHeight: '100vh' }}>
            <Routes>
                <Route
                    path={URLS.auth.signIn.route}
                    element={<SignIn gitHubAuthError={gitHubAuthError} setGitHubAuthError={setGitHubAuthError} />}
                />
                <Route
                    path={URLS.auth.signUp.route}
                    element={<SignUp gitHubAuthError={gitHubAuthError} setGitHubAuthError={setGitHubAuthError} />}
                />
                <Route
                    path={URLS.auth.githubCallback.route}
                    element={<GitHubCallback setGitHubAuthError={setGitHubAuthError} />}
                />
                <Route path={URLS.auth.resetPassword.route} element={<ResetPassword />} />
                <Route path={URLS.auth.resetPasswordCallback.route} element={<ResetPasswordCallback />} />
                <Route path={URLS.auth.verify.route} element={<VerifyAccount />} />
                <Route path="/*" element={<Navigate to={buildSignInUrl()} />} />
            </Routes>
        </Container>
    )
}

export default PublicLayout
